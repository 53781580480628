import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useEmotion } from './EmotionContext';
import Playhead from './Playhead';
import EmotionBar from './EmotionBar';

const Chart = () => {
  const { importCollectedDataFromFile } = useEmotion();
  const playheadWidth = 4; // Set playhead width here

  useEffect(() => {
    const handleImport = async (event) => {
      const file = event.target.files[0];
      if (file) {
        try {
          await importCollectedDataFromFile(file);
        } catch (error) {
          console.error('Error importing collected data:', error);
        }
      }
    };

    const fileInput = document.getElementById('import-emotions-file');
    fileInput.addEventListener('change', handleImport);

    // Cleanup event listener on component unmount
    return () => {
      fileInput.removeEventListener('change', handleImport);
    };
  }, [importCollectedDataFromFile]);

  return (
    <Box style={{ position: 'relative', height: '100px', width: '100%' }}>
      <Box sx={{ position: 'relative', height: '100%', width: `calc(100% - ${playheadWidth}px)` }}>
        <EmotionBar width="100%" height="100%" />
        <Playhead playheadWidth={playheadWidth} />
      </Box>
      
      <input
        type="file"
        accept=".emo"
        id="import-emotions-file"
        style={{ display: 'none' }}
      />
    </Box>
  );
};

export default Chart;
