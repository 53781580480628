import React, { createContext, useContext } from 'react';
import { useVehicle, fetchBrands, saveBrandData, putVehicle } from './VehicleUtils';

export const AutodinContext = createContext();

export const useAutodin = () => {
    const context = useContext(AutodinContext);
    if (!context) {
        throw new Error('useAutodin must be used within an AutodinProvider');
    }
    return context;
};

export const AutodinProvider = ({ children, nodeId }) => {
    const [node, setNode] = useVehicle(nodeId);

    return (
        <AutodinContext.Provider
            value={{
                node,
                setNode,
                fetchBrands,
                putVehicle,
            }}
        >
            {children}
        </AutodinContext.Provider>
    );
};
