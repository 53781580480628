import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function Registration({ value, onChange }) {
    const [selectedYear, setSelectedYear] = useState(value);
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 50 }, (_, index) => currentYear - index);

    useEffect(() => {
        setSelectedYear(value);
    }, [value]);

    const handleYearChange = (event) => {
        const newValue = event.target.value;
        setSelectedYear(newValue);
        // Create a synthetic event to match the expected structure
        onChange({ target: { name: 'registration', value: newValue } });
    };

    return (
        <Box my={2}>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Mise en circulation</InputLabel>
                <Select
                    name="registration"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedYear}
                    onChange={handleYearChange}
                    label="Mise en circulation"
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 48 * 4.5, // Where 48 is list item height, 4.5 is the visible items count
                                width: 250,
                            },
                        },
                    }}
                >
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
