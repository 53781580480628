import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Paper, Typography } from "@mui/material";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";

const ResetPassword = ({ onClose }) => {
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null); // Declare state variables for token
  const [tokenId, setTokenId] = useState(null); // and tokenId
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
  
    const fetchedToken = params.get("token");
    const fetchedTokenId = params.get("tokenId");

    setToken(fetchedToken); // Update state variables
    setTokenId(fetchedTokenId); // Update state variables
    
    console.log("Token in useEffect:", fetchedToken);
    console.log("TokeId  in useEffect:", fetchedTokenId);

    if (!fetchedToken || !fetchedTokenId) {
      alert(
        "You can only call resetPassword() if the user followed a confirmation email link"
      );
      navigate("/login");
    }
  }, [navigate]);

  const handleReset = async () => {
    try {
      //console.log("Token:", token);
      //console.log("TokeId:", tokenId);

      await resetPassword(password, token, tokenId);
      alert("Password reset successfully!...");
      navigate("/login");
      if (typeof onClose === 'function') {
        onClose();
      }
    } catch (error) {
      alert("Error resetting password: " + error.message);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Paper elevation={2} style={{ padding: "2rem", maxWidth: '500px' }}>
        <Typography variant="h4">Reset Password</Typography>
        <form>
          <TextField
            label="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <Button
            onClick={handleReset}
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "1rem" }}
          >
            Reset Password
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default ResetPassword;