import React from 'react';
import { TextField } from '@mui/material';

function CustomTextField({ value = '', fontSize = '1.8rem', onChange }) {
  return (
    <TextField
      value={value}
      onChange={onChange}
      variant="outlined" // Make sure to keep the variant as 'outlined'
      sx={{
        '& .MuiOutlinedInput-root': {
          border: 'none', // Removes the border from the input
          '& fieldset': {
            border: 'none', // Removes the border
          },
          '&:hover fieldset': {
            border: 'none', // Removes the border on hover
          },
          '&.Mui-focused fieldset': {
            border: 'none', // Removes the border when the TextField is focused
          },
        },
        '& .MuiInputBase-input': {
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: { fontSize },
          padding: 0,
        },
        width: '100%'
      }}
    />
  );
}

export default CustomTextField;
