import React, { useState } from 'react';

import {
  TextField,
  Box,
  Stack,
  Typography,
  IconButton,
  Button,
  Paper
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const BG_BASE = '#7895BF';
const BG_EXECUTIVE = '#46689C';
const CONTAINED_COLOR = '#487ECF';
const CONTAINED_HOVER_COLOR = '#35507A';
const LIGHT_COLOR = '#EDEDED';
const PAPER_COLOR = '#EDEDED';
const BLACK = '#000';
const BORDER_RADIUS = '3px';

export const mlTheme = createTheme({
  palette: {
    BgBase: BG_BASE,
    BgExecutive: BG_EXECUTIVE,
    LightColor: LIGHT_COLOR,
    PaperColor: PAPER_COLOR,
    ContainedColor: CONTAINED_COLOR,
    Black: BLACK,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: CONTAINED_COLOR,
          '&:hover': {
            backgroundColor: CONTAINED_HOVER_COLOR,
          },
        },
      },
    },
  },
});

export function MlTextField() {
  return (
    <ThemeProvider theme={mlTheme}>
      <TextField
        variant="filled"
        InputProps={{
          disableOutline: true,
          style: { boxShadow: 'none', border: 'none' },
        }}
        placeholder="Type something..."
      />
    </ThemeProvider>
  );
}

export function MlText({ children, fontSize = '1rem', fontWeight = '900', fontFamily = 'Inter', colorKey }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Typography style={{
        fontFamily: fontFamily,
        fontWeight: fontWeight,
        fontSize: fontSize,
        color: mlTheme.palette[colorKey] || mlTheme.palette.LightColor
      }}>
        {children}
      </Typography>
    </ThemeProvider>
  );
}

export function NodeTitle({ children }) {
  return (
    <Box sx={{
      height: 'auto',
      width: '100%',
      //backgroundColor: mlTheme.palette.BgBase,
      display: 'flex',
    }}>
      <MlText
        fontSize='1rem'
        fontWeight='500'
        fontFamily='Inter'
        colorKey='Black'
      >
        {children}
      </MlText>
    </Box>
  );
}

export function MlPageContent({ children, bgColor, maxWidth ='1024px' }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Box sx={{
        flexGrow: 1,
        width: '100%', // Take up 100% of the width
        maxWidth: maxWidth, // Maximum width of 1024px
        backgroundColor: bgColor || mlTheme.palette.BgBase, // Use bgColor prop if provided, otherwise default to BgBase
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        margin: '0 auto', // Center the box horizontally
      }}>
        {children}
      </Box>
    </ThemeProvider>
  );
}

export function MlHorizontalContent({ children, bgColor }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Box sx={{
        width: '100%', // Take up 100% of the width
        backgroundColor: bgColor || mlTheme.palette.BgBase, // Use bgColor prop if provided, otherwise default to BgBase
        display: 'flex',
        justifyContent: 'space-evenly', // Adjust this to fit your needs: 'flex-start', 'center', 'space-between', 'space-around', 'space-evenly'
        margin: '0 auto', // Center the box horizontally
      }}>
        {children}
      </Box>
    </ThemeProvider>
  );
}



export function MlBg({ children }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Box sx={{
        height: '100vh',
        width: '100%',
        backgroundColor: mlTheme.palette.BgBase,
        display: 'flex',
      }}>
        {children}
      </Box>
    </ThemeProvider>
  );
}

export function PageBg({ children, bgColor='rgb(255,255,255)' }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Box sx={{
        height: '100vh',
        width: '100%',
        backgroundColor: bgColor,
        display: 'flex',
      }}>
        {children}
      </Box>
    </ThemeProvider>
  );
}

export function MlBoard({ children, spacing = '0px', padding = '0px', backgroundColor = '' }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Stack
        spacing={spacing}
        direction="column"
        sx={{
          height: '100%',
          width: '100%',
          padding: {padding},
          backgroundColor: {backgroundColor},

          //backgroundColor: mlTheme.palette.BgBase,
          display: 'flex',
        }}>
        {children}
      </Stack>
    </ThemeProvider>
  );
}

export function MlHBox({ children, colorKey, padding = '6px 12px' }) {
  const backgroundColor = colorKey ? mlTheme.palette[colorKey] : null;

  return (
    <ThemeProvider theme={mlTheme}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        width: '100%',
        backgroundColor: backgroundColor,  // Use the variable here
        padding: { padding },
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {children}
      </Box>
    </ThemeProvider>
  );
}

export function MlHToolBox({ children, distribute = false, padding = '6px 12px', maxWidth = '400px', borderRadius = '200px' }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Paper sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 'auto',
        width: '100%',
        maxWidth: { maxWidth },
        backgroundColor: mlTheme.palette.ContainedColor,
        padding: { padding },
        borderRadius: {borderRadius},  // For rounded corners
        elevation: 3,  // Optional: for box shadow
        justifyContent: distribute ? 'space-between' : 'flex-start'
      }}>
        {children}
      </Paper>
    </ThemeProvider>
  );
}

export function MlColumn({ children, distribute = false, padding = '6px', maxWidth = '350px' }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        maxWidth: { maxWidth },
        backgroundColor: mlTheme.palette.PaperColor,
        padding: { padding },
        borderRadius: BORDER_RADIUS,
        elevation: 3,
        justifyContent: 'flex-start' // Changed this line
      }}>
        {children}
      </Paper>
    </ThemeProvider>
  );
}

export function MlEditorBG({ children, distribute = false, padding = '6px' }) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: 'auto',
        backgroundColor: mlTheme.palette.PaperColor,
        padding: { padding },
        
        elevation: 3,
        justifyContent: 'flex-start' // Changed this line
      }}>
        {children}
      </Paper>
    </ThemeProvider>
  );
}


export function MlLayoutStack({ children, padding = '0px', spacing = '0px', mt=0}) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Stack
        spacing={spacing}
        mt={mt}
        sx={{
          width: '100%',
          height: '100%', // Ensures Stack fills its parent's height
          display: 'flex', // Use flex layout
          flexDirection: 'column', // Stack items vertically
          alignItems: 'center', // Center items horizontally
          padding: padding, // Apply padding
        }}>
        {children}
      </Stack>
    </ThemeProvider>
  );
}




export function MlHContainer({ children, pb = 0, pt = 0, pl = 0, pr = 0, spacing = 2, bgColor='black'}) {
  return (
    <ThemeProvider theme={mlTheme}>
      <Stack direction="row"
        spacing={spacing}
        pt={pt}
        pl={pl}
        pr={pr}
        pb={pb}
        sx={{
          alignItems: 'center',  // Directly set alignItems here
          height: 'auto',        // Take full height of parent
          width: '100%',
          justifyContent: 'flex-start',
          backgroundColor: bgColor,
        }}>
        {children}
      </Stack>
    </ThemeProvider>
  );
}

export function MlIconButton({ icon: Icon, colorKey, size = "18" }) {
  const color = mlTheme.palette[colorKey] || mlTheme.palette.LightColor;

  return (
    <ThemeProvider theme={mlTheme}>
      <IconButton>
        <Icon fill={color} size={size} />
      </IconButton>
    </ThemeProvider>
  );
}

export function MlSwitchIconButton({ icon1: Icon1, icon2: Icon2, colorKey, size = "24" }) {
  const [activeIcon, setActiveIcon] = useState(1);
  const color = mlTheme.palette[colorKey] || mlTheme.palette.LightColor;

  const toggleIcon = () => {
    setActiveIcon(prev => (prev === 1 ? 2 : 1));
  };

  return (
    <ThemeProvider theme={mlTheme}>
      <IconButton onClick={toggleIcon}>
        {activeIcon === 1 ? <Icon1 fill={color} size={size} /> : <Icon2 fill={color} size={size} />}
      </IconButton>
    </ThemeProvider>
  );
}

export function MlButton({ icon: Icon, colorKey, size = "24", children }) {
  const color = mlTheme.palette[colorKey] || mlTheme.palette.LightColor;

  return (
    <ThemeProvider theme={mlTheme}>
      <Button variant="contained"
        startIcon={<Icon fill={color}
          size={size} />}
        style={{ borderRadius: '200px' }}
      >
        {children}
      </Button>
    </ThemeProvider>
  );
}

export function MlImageBox({ imgSrc, colorKey, width ='250px', boxShadow = null}) {
  const backgroundColor = colorKey ? mlTheme.palette[colorKey] : null;

  return (
    <ThemeProvider theme={mlTheme}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        width: { xs: '145px', sm: '250px', md: '350px' },
        backgroundColor: backgroundColor, // Use the variable here
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: boxShadow,
      }}>
        <img 
          src={imgSrc} 
          alt="" 
          style={{
            maxWidth: '100%', // Ensure the image is not wider than the container
            height: 'auto',  // Maintain aspect ratio
            borderRadius: '5px',
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
