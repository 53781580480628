import React, { useState, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/ArrowBackRounded';

import { useMlCanvas } from '../../MlModels/MlCanvasProvider';

export default function MlNavigationMenu({ getList, iconComponent,color = 'black' }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { loadCanvas, saveToDatabase } = useMlCanvas();

  const handleLoadCanvas = async (canvasId) => {
    await saveToDatabase(); // Save the current flow first
    loadCanvas(canvasId); // Then load the new canvas
  };
  
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // When the menu is opened, fetch the list
  const canvasList = open ? getList() : [];

  return (
    <>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          color: color,
          '&:focus': {
            outline: 'none'
          },
          margin: '0px',
          padding: '0px',
        }}
      >
        {iconComponent}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="simple-menu"
                  aria-labelledby="simple-menu-button"
                  onKeyDown={(event) => {
                    if (event.key === 'Tab' || event.key === 'Escape') {
                      event.preventDefault();
                      setOpen(false);
                    }
                  }}
                  sx={{ maxHeight: 'calc(100vh - 180px)', overflow: 'auto' }}
                >
                  {canvasList.map((canvas, index) => (
                    <MenuItem key={index} onClick={() => handleLoadCanvas(canvas._id)}>
                      {canvas.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
