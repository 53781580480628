export const authors = [

    {
        //ANJO
        src: "/IMAGES/upload/645b5e00c7b61df466430901/node_65fe2a2c6a8b68f837ceb351/ANJO-MIST.png?v=1711158466",
        link: "661fa334f115f3182e592a54?lock=true",
        name: "Anjo (Jo Angenot)"
    },
    {
        //MARTHA
        src: "/IMAGES/upload/64c9164919bd6f8d1f1feb6c/node_65ea0fcccd992518e449392d/(_1) Alma-Mater-case 2.jpeg?v=1711435864",
        link: "661fa357f115f3182e592cc0?lock=true",
        name: "Martha Verschaffel"
    },
    {
        //Goscinny
        src: "/IMAGES/upload/null/node_6652f089619bbe427a719c64/WhatsApp Image 2024-05-26 à 10.06.46_0388554d.jpg",
        link: "661fa3a3f115f3182e592d49?lock=true",
        name: "René Goscinny"
    },

    {
        //DEVOS
        src: "/IMAGES/upload/null/node_665302cd771468deccfe2fd1/Devos mystère NEW1 copie.jpg",
        link: "661fa37ef115f3182e592d22?lock=true",
        name: "Jacques Devos"
    },

    {
        //MANAERT
        src: "/IMAGES/upload/null/node_6653144049a4eb0366458d76/WhatsApp Image 2024-05-26 à 11.59.57_5f8ac13d.jpg",
        link: "661fa71ef115f3182e592d80?lock=true",
        name: "Wauter Mannaert"
    },

    {
        //IMAGIL
        src: "/IMAGES/upload/null/node_665312ccd27bda0be6118d9f/WhatsApp Image 2024-05-26 à 12.03.33_484dc233.jpg?v=1716720987",
        link: "661d4df37a8d818d328ecc53?lock=true",
        name: "Imagil (Charles Gilbert)"
    },
    
    {
        //DERADIGES
        src: "/IMAGES/upload/null/node_665ebcd43974eccb5b75811d/WhatsApp Image 2024-06-02 à 09.47.41_3913926e.jpg?v=1717484776",
        link: "661d51107a8d818d328ecd5a?lock=true",
        name: "Max de Radiguès"
    },

    {
        //GREG SHAW
        src: "/IMAGES/upload/null/node_6682e3f6ff2ab68d163e0f8c/Greg Shaw Mistère A.jpg?v=1720518373",
        link: "6682e351ff2ab68d163e0ed9?lock=true",
        name: "Greg Shaw"
    },
    {
        //DUVAL
        src: "/IMAGES/upload/null/node_66b0e17770083f3e385e283c/Duval-Thumbnail.jpg?v=1722868108",
        link: "661d50c17a8d818d328ecd34?lock=true",
        name: "Yves Duval"
    },
    {
        //CHAREL CAMBRÉ
        src: "/IMAGES/upload/null/node_66ced596e0fec37d8408944a/A.jpg?v=1724831147",
        link: "661d52947a8d818d328ecd8f?lock=true",
        name: "Charel Cambré"
    }
    // Add more images here
];

/*
/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/KBR-Logo-Black_FR-NL.png?v=1719385677
/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/WhatsApp Image 2024-06-24 à 12.38.23_5a15125b.jpg?v=1719385677
/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/WhatsApp Image 2024-06-24 à 12.38.32_63b2f6bb.jpg?v=1719385677
/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/WhatsApp Image 2024-06-24 à 12.38.42_88b5a3b0.jpg?v=1719385677
/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/WhatsApp Image 2024-06-24 à 12.38.53_8280aaba.jpg?v=1719385677
*/

export const logos = [
    {
        //FEDERATION WALLONIE BRUXELLES
        src: "/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/WhatsApp Image 2024-06-24 à 12.38.53_8280aaba.jpg?v=1719385677",
        link: "https://www.federation-wallonie-bruxelles.be/",
        name: "Fédération Wallonie Bruxelles"
    },
    {
        //KBR
        src: "/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/KBR-Logo-Black_FR-NL.png?v=1719385677",
        link: "https://www.kbr.be/en/",
        name: "KBR"
    },
    {
        //MUSEE BD
        src: "/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/WhatsApp Image 2024-06-24 à 12.38.23_5a15125b.jpg?v=1719385677",
        link: "https://www.cbbd.be/fr/accueil",
        name: "Musée de la BD Belge"
    },
    {
        //STRIPMUSEUM
        src: "/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/WhatsApp Image 2024-06-24 à 12.38.32_63b2f6bb.jpg?v=1719385677",
        link: "https://www.stripmuseum.be/nl/home",
        name: "Strip Museum"
    },

    {
        //CITE BD
        src: "/IMAGES/upload/645b5e00c7b61df466430901/node_667bbdf6536489476c1cd990/WhatsApp Image 2024-06-24 à 12.38.42_88b5a3b0.jpg?v=1719385677",
        link: "https://www.citebd.org/",
        name: "La cité internationale de la bande dessinée"
    }

    
    // Add more images here
];