import { ObjectId } from 'bson';
import ReactFlow, {
  MarkerType
} from 'reactflow';

export function truncateString(str, n) {
  if (str && str.length > n) {
    return `${str.substring(0, n - 3)}...`;
  }
  return str;
}

export function getTruncatedTitle(node, n) {
  return truncateString(node.title, n);
}

export function getTruncatedBrief(node, n) {
  return truncateString(node.brief, n);
}

// position = { x: 0, y: 0 };
/*
export const createNewNode = (position, type = 'selectorNode') => {
  return {
    id: `node_${new ObjectId()}`,
    type,
    dragHandle: '.custom-drag-handle',
    position,
    dragging: false,
  };
};
*/

export const createNewNode = (position, type, title, brief, ptype, state) => {

  const currentDate = new Date();

  const nodeData = {
    id: `node_${new ObjectId()}`,
    type: type || "selectorNode",
    data: {
      _id: `node_${new ObjectId()}`,
      title: title || `Node-${currentDate.getSeconds()}`,
      brief: brief || '',
      createdAt: currentDate,
      updatedAt: currentDate,
      displayName: true,
      displayIcon: true,
      backgroundColor: '250,250,248,1',
      elevation: '2'
    },
    dragHandle: ".custom-drag-handle",
    position,
    dragging: false
  };

  // Add state property if the type is 'task' or if the state is a number
  if (ptype === 'task' || typeof state === 'number') {
    nodeData.data.type = ptype;
    nodeData.data.state = state;
  }

  return nodeData;
};

//to use to create an edge programmatically

export const getNewEdge = (sourceId, targetId) => {
  const edgeId = `${sourceId}-${targetId}`;
  return {
    source: sourceId,
    target: targetId,
    sourceHandle: "a",
    targetHandle: "c",
    markerEnd: {
      type: MarkerType.Arrow,  // Make sure to import MarkerType
      width: 50,
      height: 50,
    },
    id: edgeId,
    selected: false
  };
};

//this is used by ReactFlow
// this is the standard one with an edge
export const createNewEdge = (params) => {
  return {
    ...params,
    style: { strokeWidth: 2 },
    /*
    markerEnd: {
      type: MarkerType.Arrow,  // Make sure to import MarkerType
      width: 50,
      height: 50,
    },
    */
  };
};