import { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    TextField,
    Autocomplete,
    CircularProgress,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { useAutodin } from '../../../components/autodin/AutodinProvider';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Papa from 'papaparse';

function BrandModelSelect({ formData = {}, handleInputChange = () => { }, disableEditing = false }) {
    const { fetchBrands, saveBrandData } = useAutodin();  // Destructure fetchBrands from useAutodin

    const [data, setData] = useState({ brands: [], models: {} });
    const [loading, setLoading] = useState(true);
    const [openBrandDialog, setOpenBrandDialog] = useState(false);
    const [openModelDialog, setOpenModelDialog] = useState(false);
    const [newBrand, setNewBrand] = useState('');
    const [newModel, setNewModel] = useState('');
    const [deleteBrandDialog, setDeleteBrandDialog] = useState(false);
    const [deleteModelDialog, setDeleteModelDialog] = useState(false);

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                complete: updateData,
                header: true
            });
        }
    }

    function updateData(result) {
        const csvData = result.data;
        const newData = { ...data };
        for (let item of csvData) {
            const brand = item.marque;
            const model = item.modele;
            if (brand && model) {
                if (!newData.brands.includes(brand)) {
                    newData.brands.push(brand);
                }
                if (!newData.models[brand]) {
                    newData.models[brand] = [];
                }
                if (!newData.models[brand].includes(model)) {
                    newData.models[brand].push(model);
                }
            }
        }
        setData(newData);
        saveBrandData(newData); // Save the new data to the node after updating the state.
    }

    useEffect(() => {
        const fetchData = async () => {
            const node = await fetchBrands(); // Call fetchBrands
            if (node && node.nodeObjects && node.nodeObjects.belcarbrands) {
                setData(node.nodeObjects.belcarbrands);
            } else {
                setData({ brands: [], models: {} });
            }
            setLoading(false);
        };
        fetchData();
    }, [fetchBrands]);

    const handleAddBrand = async () => {
        if (newBrand && !data.brands.includes(newBrand)) {
            const updatedData = { brands: [...data.brands, newBrand], models: data.models };
            setData(updatedData);
            saveBrandData(updatedData);
        }
    };

    const handleAddModel = async () => {
        if (newModel && formData.brand && !data.models[formData.brand]?.includes(newModel)) {
            const updatedData = {
                ...data,
                models: {
                    ...data.models,
                    [formData.brand]: [...(data.models[formData.brand] || []), newModel]
                }
            };
            setData(updatedData);
            saveBrandData(updatedData);
        }
    };

    const handleDeleteBrand = async () => {
        const updatedData = {
            brands: data.brands.filter(brand => brand !== formData.brand),
            models: {
                ...data.models,
                [formData.brand]: undefined
            }
        };
        setData(updatedData);
        saveBrandData(updatedData);
    };

    const handleDeleteModel = async () => {
        const updatedData = {
            ...data,
            models: {
                ...data.models,
                [formData.brand]: data.models[formData.brand].filter(model => model !== formData.model)
            }
        };
        setData(updatedData);
        saveBrandData(updatedData);
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Box display="flex" alignItems="center">
                <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
                    <Autocomplete
                        value={formData.brand || ''}
                        options={data.brands}
                        onChange={(event, newValue) => handleInputChange({ target: { name: 'brand', value: newValue } })}
                        renderInput={(params) => <TextField {...params} label="Marque" variant="outlined" />}
                    />
                </FormControl>
                {!disableEditing && (
                    <>
                        <IconButton onClick={() => setOpenBrandDialog(true)}>
                            <AddIcon />
                        </IconButton>
                        <IconButton onClick={() => setDeleteBrandDialog(true)}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                )}
            </Box>

            {formData.brand && (
                <Box display="flex" alignItems="center" mt={2}>
                    <FormControl variant="outlined" fullWidth style={{ flex: 1 }} disabled={!formData.brand}>
                        <Autocomplete
                            value={formData.model || ''}
                            options={data.models[formData.brand] || []}
                            onChange={(event, newValue) => handleInputChange({ target: { name: 'model', value: newValue } })}
                            renderInput={(params) => <TextField {...params} label="Modèle" variant="outlined" />}
                            disabled={!formData.brand}
                        />
                    </FormControl>
                    {!disableEditing && (
                        <>
                            <IconButton onClick={() => setOpenModelDialog(true)} disabled={!formData.brand}>
                                <AddIcon />
                            </IconButton>
                            <IconButton onClick={() => setDeleteModelDialog(true)} disabled={!formData.brand || !formData.model}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </Box>
            )}

            {!disableEditing && (
                <Box mt={2}>
                    <input
                        accept=".csv"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="raised-button-file">
                        {/* 
                        <Button component="span">
                            Charger les marques
                        </Button>
                        */}
                    </label>
                </Box>
            )}

            {!disableEditing && (
                <>
                    <Dialog open={openBrandDialog} onClose={() => setOpenBrandDialog(false)}>
                        <DialogTitle>Add Brand</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Marque"
                                value={newBrand}
                                onChange={(e) => setNewBrand(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleAddBrand();
                                    setOpenBrandDialog(false);  // Close the dialog after adding the brand
                                }}
                                color="primary"
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openModelDialog} onClose={() => setOpenModelDialog(false)}>
                        <DialogTitle>Ajouter un modèle</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Modèle"
                                value={newModel}
                                onChange={(e) => setNewModel(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleAddModel();
                                    setOpenModelDialog(false);  // This line will close the model dialog after adding
                                }}
                                color="primary">
                                Ajouter
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={deleteBrandDialog} onClose={() => setDeleteBrandDialog(false)}>
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                            Etes vous sur de vouloir supprimer cette marque "{formData.brand}" et les modèles associés?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setDeleteBrandDialog(false)} color="primary">Cancel</Button>
                            <Button
                                onClick={() => {
                                    handleDeleteBrand();
                                    setDeleteBrandDialog(false);
                                }}
                                color="primary">
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={deleteModelDialog} onClose={() => setDeleteModelDialog(false)}>
                        <DialogTitle>Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            Etes vous sur de vouloir supprimer ce modèle "{formData.model}"?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setDeleteModelDialog(false)} color="primary">Annuler</Button>
                            <Button
                                onClick={() => {
                                    handleDeleteModel();
                                    setDeleteModelDialog(false);
                                }}
                                color="primary">
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Box>
    );
}

export default BrandModelSelect;
