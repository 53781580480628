import React from "react";
import { PageBg, MlText, MlLayoutStack, MlPageContent } from '../mainlistcomponents';
import BelgicomicsHeader from "./BelgicomicsHeader";
import DoubleImage from "../../Belgicomics/DoubleImage";
import { nodeGet } from '../../../MlModels/MlNode';
import BelgicomicsLogo from '../../../components/Belgicomics/Images/belgicomixlogo.js';
import BelgicomicsFooter from '../../../components/Belgicomics/BelgicomicsFooter.js';
import AuthorList from "../../Belgicomics/AuthorList.js";
import LogosDisplay from "../../base/LogoDisplay.js"
import { logos } from '../../Belgicomics/BelgicomicsDB.js';
import {
    Stack,
    Typography,
    //IconButton,
    Box
} from '@mui/material';


const BelgicomicsLandinPage = ({ bgColor = 'rgb(255,255,255)' }) => {
    return (
        <>
            <Stack
                alignItems={'center'}
                sx={{
                    backgroundColor: bgColor,
                    minHeight: '100vh', // At least 100% of the viewport height
                    flexGrow: 1        // Allows the Stack to grow with content
                }}
            >
                <Stack mt={3} alignItems={'center'}>
                    <BelgicomicsLogo height='60px' color='#333333' />

                    <Box sx={{
                        mt: { xs: 2, sm: 2, md: 2.5 } // Responsive margin-top
                    }}>
                        <DoubleImage />
                    </Box>
                    <Box
                        width='100%'
                        sx={{
                            mt: { xs: 2, sm: 3, md: 4 } // Responsive margin-top
                        }}>
                        <AuthorList />
                    </Box>
                </Stack>
            </Stack>
            <LogosDisplay logos />

            <BelgicomicsFooter />
        </>
    );
};

export default BelgicomicsLandinPage;
