// EmotionLib.js
export const emotionColors = {
    CALM: '#fffff',      // Blue
    CONFUSED: '#5d611c',  // Orange
    DISGUSTED: '#000000', // Green
    SAD: '#233bb5',       // Red
    SURPRISED: '#229653', // Purple
    ANGRY: '#b52323',     // Brown
    FEAR: '#ff6600',      // Pink
    HAPPY: '#ff0080',     // Grey
  };
  
  // Function to export emotions data to a .emo file
  export const exportEmotionsToFile = (emotions, filename = 'emotions.emo') => {
    const dataStr = JSON.stringify(emotions);
    const dataBlob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  
  // Function to import emotions data from a .emo file
  export const importEmotionsFromFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = JSON.parse(event.target.result);
          resolve(data);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };
  
  // Function to format time in mmm:ss
  export const formatTime = (milliseconds) => {
    const totalSeconds = Math.round(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(3, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  