import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';

const MlIconTab = ({
    onIconClick,
    iconSize = '24px',
    color = 'black',
    selectedColor = 'rgb(100,100,255)',
    icons  // Accepting icons as a prop
}) => {

    const [selectedId, setSelectedId] = useState(null);

    const handleClick = (id) => {
        setSelectedId(id);
        onIconClick(id);
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {icons.map((item) => (
                <IconButton
                    key={item.id}
                    onClick={() => handleClick(item.id)}
                    style={{
                        color: item.id === selectedId ? selectedColor : color,
                        fontSize: iconSize
                    }}
                >
                    {React.cloneElement(item.icon, {
                        style: {
                            fontSize: iconSize,
                            color: item.id === selectedId ? selectedColor : color
                        }
                    })}
                </IconButton>
            ))}
        </Box>
    );
};

export default MlIconTab;
