export const getKnowledgePrompt = (concept, levels = 4, maxItems = 5, length = 250) => {
    const jsonformat = JSON.stringify([
        {
            "title": "Root",
            "brief": "This is the root",
            "children": [
                {
                    "title": "Child1",
                    "brief": "This is child 1",
                    "children": [
                        {
                            "title": "Child1.1",
                            "brief": "This is child 1.1"
                        }
                    ]
                },
                {
                    "title": "Child2",
                    "brief": "This is child 2"
                }
            ]
        }
    ]);

    return `

    if '${concept}' contains a line started by 'instructions:' or 'instruction:' then use that as your instructions and exctract the instructions out of the concept.
    Develop a knowledge tree for the concept '${concept}', formatted as per the JSON structure in '${jsonformat}'. This tree should have a diverse array of nodes and children, with a maximum of '${maxItems}' children per level.

    Progress through the tree by recursively generating child nodes. Each child derives its context from the parent node's brief, and this process is repeated until reaching a depth of '${levels}'. The brief for each node should succinctly express the key idea of its title, targeted to be around '${length}' words for clarity and brevity.

    The content of each level should expand upon the concepts introduced at preceding levels, with each node's title being a concise two to three-word summary of its brief. The root node must provide an introductory overview of the '${concept}'.

    Please ensure the final output is solely the JSON structure of the knowledge tree, without any additional text. The content language of the JSON should match that of the '${concept}', tailored for direct processing.
`;
};

