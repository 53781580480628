// src/store/selectedNodesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import nodeMapper from '../models/NodeMapper';

// Async action for updating nodes
export const pasteNodes = createAsyncThunk(
  'selectedNodes/paste',
  async (updateValues, { getState, dispatch }) => {
    const selectedNodes = getState().selectedNodes;
    const updatedNodes = [];

    for (let node of selectedNodes) {
      const updatedNode = await nodeMapper.updateNode(node, updateValues);
      updatedNodes.push(updatedNode);
    }

    return updatedNodes;
  }
);

export const selectedNodesSlice = createSlice({
  name: 'selectedNodes',
  initialState: [],
  reducers: {
    selectNode: (state, action) => {
      state.push(action.payload);
    },
    deselectNode: (state, action) => {
      return state.filter(node => node._id !== action.payload._id);
    },
    clearSelectedNodes: (state) => {
      return [];
    },
    updateBoardName: (state, action) => {
      const { oldName, newName } = action.payload;
      const boardToUpdate = state.boards.find(board => board.id === oldName);
      if (boardToUpdate) {
        boardToUpdate.id = newName;
        if (state.selectedBoard === oldName) {
          state.selectedBoard = newName;
        }
        nodeMapper.updateNodeObjects(".defaultMainListBoard", { defaultMainListBoard: state });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pasteNodes.fulfilled, (state, action) => {
      // Clear the selected nodes after pasting
      return [];
    });
  }
});





export const { selectNode, deselectNode, clearSelectedNodes } = selectedNodesSlice.actions;
export default selectedNodesSlice.reducer;
