import React, { useState, useEffect } from 'react';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider'; // Replace with the actual path
import { Stack, Box, MenuItem, TextField, InputAdornment, IconButton } from '@mui/material';
import { getNodesList } from '../../MlModels/MlNode'
import SearchIcon from '@mui/icons-material/Search';
import MlSearchEditor from './MlSearchEditor';



import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MlIconTab from './cannevas/MlIconTab';

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import AnimationIcon from '@mui/icons-material/Animation';
import SettingsIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';



export default function MlCanvasList() {
    const [canvasList, setCanvasList] = useState([]);
    const [filteredCanvasList, setFilteredCanvasList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { getRootCanvasList, loadCanvas, saveToDatabase } = useMlCanvas();

    const icons = [
        { icon: <HubIcon />, id: 0 },
        { icon: <DesktopWindowsIcon />, id: 1 },
        { icon: <AnimationIcon />, id: 4 },
        { icon: <SettingsIcon />, id: 5 },
    ];


    const customMatch = { template: true };
    const customProject = { _id: 1, title: 1, brief: 1, imageLink: 1 };

    

    useEffect(() => {
        const fetchCanvasList = async () => {
            try {
                const userId = '';
                const updatedList = await getNodesList( userId, customMatch, customProject);
                setCanvasList(updatedList);
            } catch (error) {
                console.error('Failed to fetch nodes:', error);
            } 
        };
        fetchCanvasList();
    }, [getRootCanvasList]);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = canvasList.filter(item =>
            item.title.toLowerCase().includes(lowercasedFilter)
        );
        setFilteredCanvasList(filteredData);
    }, [searchTerm, canvasList]);

    const handleLoadCanvas = async (canvasId) => {
        saveToDatabase();
        await loadCanvas(canvasId);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <Stack direction='column' sx={{ height: '100%' }}>
            <Box sx={{ width: '100%' }}>
                {/*<MlIconTab onIconClick={handleIconClick} iconSize={18} color="rgb(80,80,80)" icons={icons} />*/}
            </Box>
            <MlSearchEditor
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                showIdentityFields={false}
                sx={{ m: '5px', mb: '10px' }}
            />
            <Box flexGrow={1} overflow="auto">
                {filteredCanvasList.map((canvas, index) => (
                    <Stack key={index} direction="row" alignItems='center' overflow="hidden" >
                        {/*<IconButton>
                            <ChevronRightIcon />
                        </IconButton>*/}

                        <MenuItem
                            key={index}
                            sx={{ pl: 2 }}
                            onClick={() => handleLoadCanvas(canvas._id)}>
                            {canvas.title}
                        </MenuItem>
                    </Stack>

                ))}
            </Box>
        </Stack>
    );
}
