import React from 'react';
import {
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material';

export default function CarStates({ value, onChange }) {
    return (
        <>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                value={value}
                onChange={onChange}
            >
                <FormControlLabel value="Nouveau" control={<Radio />} label="Nouveau" />
                <FormControlLabel value="Occasion" control={<Radio />} label="Occasion" />
                <FormControlLabel value="Accidenté" control={<Radio />} label="Accidenté" />
                <FormControlLabel value="Pour Pièces" control={<Radio />} label="Pour Pièces" />
            </RadioGroup>
        </>
    );
}
