import React, { useState } from "react";
import { Button, TextField, Grid, Paper, Typography } from "@mui/material";
import { useAuth } from "./AuthContext";
import { useNavigate } from 'react-router-dom';

const SignUp = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signUp, resendConfirmationEmail } = useAuth();  // Assuming you've added a 'resendConfirmationEmail' method to your AuthContext
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signUp(email, password);
      setEmailSent(true);
    } catch (error) {
      alert("Error registering user: " + error.message);
    }
  };

  const handleResend = async () => {
    try {
      await resendConfirmationEmail(email);  // Resend the confirmation email
    } catch (error) {
      alert("Error resending confirmation email: " + error.message);
    }
  };

  return (
    <Grid container justifyContent="center" >
      <Paper elevation={2} style={{ padding: "2rem", maxWidth: '500px' }}>
        <Typography variant="h4">Sign Up</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "1rem" }}
          >
            Register
          </Button>
          <Button
            onClick={() => navigate("/login")}
            variant="contained"
            color="secondary"
            fullWidth
            style={{ marginTop: "1rem" }}
          >
            Log In
          </Button>

          {emailSent && (
            <>
              <Typography variant="body1" style={{ marginTop: "1rem" }}>
                A confirmation email has been sent from "QUANYX.com"
                You have 30 minutes to confirm your registration.
              </Typography>
              <Button
                onClick={handleResend}
                variant="contained"
                color="secondary"
                fullWidth
                style={{ marginTop: "1rem" }}
              >
                Resend Confirmation Email
              </Button>

            </>
          )}
        </form>
      </Paper>
    </Grid>
  );
};

export default SignUp;