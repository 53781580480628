import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Radio, TextField, Stack, RadioGroup, MenuItem, Select, InputLabel, FormControl } from '@mui/material';


export default function DashBoardContact({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>
                <TextField
                    label="Name"
                    name="name"
                    value={node?.name || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="First Name"
                    name="firstName"
                    value={node?.firstName || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Email"
                    name="email"
                    value={node?.email || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Phone"
                    name="phone"
                    value={node?.phone || ''}
                    onChange={handleInputChange}
                />

            </Stack>
        </FormGroup>
    );
}
