import React from 'react';
import { Box, Typography } from '@mui/material';
import Icon from '@mui/icons-material/CheckBoxOutlineBlank';


const MlIcon = ({ icon: CustomIcon, text, iconSize, onClick, disabled  }) => {
    const IconToShow = CustomIcon || Icon;
    const disabledStyle = disabled ? { opacity: 0.5 } : {};
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        onClick={() => !disabled && onClick(text)}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...disabledStyle }}
      >
        <IconToShow style={{ fontSize: iconSize }} />
        <Typography variant="caption">{text}</Typography>
      </Box>
    );
  };

  export default MlIcon;