import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function CustomTabPanel(props) {
  const { children, value, index, label, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {/*
          <Box sx={{ fontWeight: 'bold', mb: 2 }}>
            Current Tab: {label} 
          </Box>
          */}
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ tabLabels, tabContents, onChange, selectedTab }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    onChange(event, newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          sx={{
            '.MuiTab-root': {
              color: 'black',
              backgroundColor: 'white',
              opacity: 1,
            },
            '.Mui-selected': {
              color: 'black',
              backgroundColor: 'white',
              opacity: 1,
            },
          }}
        >
          {tabLabels.map((label, index) => (
            <Tab key={index} label={label} {...a11yProps(index)} size="small" />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        {tabContents && tabContents.map((content, index) => (
          <CustomTabPanel key={index} value={selectedTab} index={index} label={tabLabels[index]}>
            {content}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
}
