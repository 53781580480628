import React, { forwardRef } from 'react';
import { Paper, TextField } from '@mui/material';

const SearchBox = forwardRef(({ placeholder, rows, elevation, padding, sxPaper, sxTextField, mt = 0 }, ref) => {
  return (
      <Paper
        elevation={elevation}
        sx={{
          width: '100%',
          mt: mt,
          backgroundColor: 'white',
          ...sxPaper
        }}
        padding={padding}
      >
        <TextField
          multiline
          rows={rows}
          placeholder={placeholder}
          sx={{
            mt: -0.55,
            width: '100%',
            ...sxTextField
          }}
          inputRef={ref}  // Attach the ref here
        />
      </Paper>
  );
});

SearchBox.defaultProps = {
  placeholder: "Que cherchez vous ?",
  rows: 5,
  elevation: 5,
  padding: 3,
  sxPaper: {},
  sxTextField: {}
};

export default SearchBox;
