import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { MlBg, MlBoard } from '../../Mainlist/mainlistcomponents';
import { useMlCanvas } from '../../../MlModels/MlCanvasProvider';
import MlCanvas from './MlCanvas';

const PublicCanvasViewer = () => {
  const { canvasId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lock = searchParams.get('lock');
  const showAnimationPanel = searchParams.get('showAnimationPanel');

  const { loadPublicCanvas, rfInstance, getBackgroundColor } = useMlCanvas();
  const loadedCanvasId = useRef();

  useEffect(() => {
    if (canvasId && canvasId !== loadedCanvasId.current && rfInstance) {
      loadPublicCanvas(canvasId);
      loadedCanvasId.current = canvasId;
    }
  }, [canvasId, loadPublicCanvas, rfInstance]);

  return (
    <MlBg>
      <MlBoard padding='' backgroundColor={getBackgroundColor()}>
        <MlCanvas
          viewOnly={true}
          lock={lock}
          showAnimationPanel={showAnimationPanel}
          showNavigation={false}
          showPlayStop={true}
        />
      </MlBoard>
    </MlBg>
  );
};

export default PublicCanvasViewer;