import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';

function MlPanelIcon({ onToggle, initialState = false, iconSize="18px",backgroundColor = "#000", panelColor = "#000", panelToggleColor = "#FFF", outlineColor = "#FFF", panel="right"}) {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    // Notify parent component when the toggle state changes
    if (onToggle) {
      onToggle(isOpen);
    }
  }, [isOpen, onToggle]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const FooterIcon = ({ size = iconSize, outline= outlineColor, panel=panelColor, background=backgroundColor }) => (
    <svg width={size} height={size} viewBox="0 0 64 64">
      {/* Outer rectangle with rounded corners */}
      <rect x="2" y="2" width="60" height="60" rx="4" ry="4" fill={background} />
      {/* Upper rectangle */}
      <path fill={outline} d="M58,4c1.1,0,2,.9,2,2v52c0,1.1-.9,2-2,2H6c-1.1,0-2-.9-2-2V6c0-1.1.9-2,2-2h52M58,0H6C2.69,0,0,2.69,0,6v52c0,3.31,2.69,6,6,6h52c3.31,0,6-2.69,6-6V6c0-3.31-2.69-6-6-6h0Z" />
      {/* Lower rectangle */}
      <path fill={panel} d="M6,62c-2.21,0-4-1.79-4-4v-11h60v11c0,2.21-1.79,4-4,4H6Z" />
      {/* Border path */}
      <path fill={outline} d="M60,49v9c0,1.1-.9,2-2,2H6c-1.1,0-2-.9-2-2v-9h56M64,45H0v13c0,3.31,2.69,6,6,6h52c3.31,0,6-2.69,6-6v-13h0Z" />
    </svg>
  );
  
  const SidePanelIcon = ({ size = iconSize, outline= outlineColor, panel=panelColor, background=backgroundColor }) => (
    <svg width={size} height={size} viewBox="0 0 64 64">
      {/* Outer rectangle with rounded corners */}
      <rect fill={background} x="2" y="2" width="60" height="60" rx="4" ry="4" />
      {/* Upper rectangle */}
      <path fill={outline} d="M58,4c1.1,0,2,.9,2,2v52c0,1.1-.9,2-2,2H6c-1.1,0-2-.9-2-2V6c0-1.1.9-2,2-2h52M58,0H6C2.69,0,0,2.69,0,6v52c0,3.31,2.69,6,6,6h52c3.31,0,6-2.69,6-6V6c0-3.31-2.69-6-6-6h0Z" />
      {/* Lower rectangle */}
      <path fill={panel} d="M39,62V2h19c2.21,0,4,1.79,4,4v52c0,2.21-1.79,4-4,4h-19Z" />
      {/* Border path */}
      <path fill={outline} d="M58,4c1.1,0,2,.9,2,2v52c0,1.1-.9,2-2,2h-17V4h17M58,0h-21v64h21c3.31,0,6-2.69,6-6V6c0-3.31-2.69-6-6-6h0Z" />
    </svg>
  );

  return (
    <IconButton onClick={toggle}>
      {panel === "right" ? 
        <SidePanelIcon panel={isOpen ? panelToggleColor : panelColor} /> 
        : 
        <FooterIcon panel={isOpen ? panelToggleColor : panelColor} />
      }
    </IconButton>
  );
}

export default MlPanelIcon;
