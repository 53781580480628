import React from "react";
import { Stack, Box } from '@mui/material';

const SimplePage = ({ bgColor = 'rgb(230,230,230)', header, body, footer, overlay }) => {
    return (
        <>
            <Stack
                sx={{
                    backgroundColor: bgColor,
                    minHeight: '100vh',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                {/* Header Content */}
                <Stack mt={3} alignItems={'center'} position="relative" width="100%">
                    {header}
                </Stack>

                {/* Body Content */}
                <Box
                    sx={{
                        flex: 1,
                        width: '98%',
                        maxWidth: '850px',
                        mt: 7,
                        mx: 'auto',

                        '& p': {
                            marginBottom: '1.2em',  // Adjust the spacing between paragraphs
                        },
                        '& h3': {
                            fontSize: '2em',  // Adjust font size for h3
                            marginBottom: '1.2em',  // Adjust spacing below h3
                        },
                        '& h4': {
                            marginBottom: '1.2em',  // Adjust spacing below h4
                        },
                        '& ul': {
                            marginBottom: '1.2em',  // Adjust spacing below h4
                        },
                        '& li': {
                            marginBottom: '0.5em',  // Adjust spacing below h4
                        },
                    }}
                >
                    {body}
                </Box>

                {/* Footer Content */}
                <Box
                    sx={{
                        width: '100%',
                        mt: 'auto',
                        mb: 3,
                    }}
                >
                    {footer}
                </Box>


            </Stack>
            {/* Overlay Content */}
            {overlay}
        </>
    );
};

export default SimplePage;
