import React, { useState, useEffect } from 'react';
import ImageSlide from './ImageSlide';
import QDraftSlide from './QDraftSlide';
import { nodeGet } from '../../MlModels/MlNode';

const MlCardMedia = ({ size = '1024px', getProperty }) => {
    const [slides, setSlides] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchSlides = async () => {
            let fetchedSlides = [];

            const imageLinks = getProperty('imageLink') ? getProperty('imageLink').split('\n') : [];
            const imageNodeIds = getProperty('imageNodeId') ? getProperty('imageNodeId').split('\n') : [];

            for (const imageLink of imageLinks) {
                const propertyName = imageLink.split('_')[0] + '_QDraftProperty';
                if (imageLink.endsWith('_QDraftProperty') && getProperty(propertyName)) {
                    const qDraftPropValue = getProperty(propertyName);
                    fetchedSlides.push({ type: 'qDraft', props: { readOnly: true, value: qDraftPropValue, size: size } });
                } else {
                    const validLink = await convertToValidLink(imageLink, null);
                    if (validLink) fetchedSlides.push({ type: 'image', props: { src: validLink, alt: 'Image' } });
                }
            }

            for (const imageNodeId of imageNodeIds) {
                const validLink = await convertToValidLink(null, imageNodeId);
                if (validLink) fetchedSlides.push({ type: 'image', props: { src: validLink, alt: 'Image' } });
            }

            setSlides(fetchedSlides);
        };

        fetchSlides();
    }, [getProperty]);

    const goToNextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const goToPrevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    };

    return (
        <div style={{ width: size, height: '100%' }}>
            {slides.length > 0 ? (
                <>
                    {slides.length > 1 && (
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '10px' }}>
                            <button onClick={goToPrevSlide}>Previous</button>
                            <button onClick={goToNextSlide}>Next</button>
                        </div>
                    )}
                    {slides[currentIndex].type === 'image' ? (
                        <ImageSlide {...slides[currentIndex].props} size={size}/>
                    ) : slides[currentIndex].type === 'qDraft' ? (
                        <QDraftSlide {...slides[currentIndex].props} />
                    ) : (
                        // Fallback to an image slide if the type is not recognized
                        <ImageSlide {...slides[currentIndex].props} size={size}/>
                    )}

                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

async function convertToValidLink(imageLink, imageNodeId) {
    if (imageNodeId) {
        const imageData = await nodeGet(imageNodeId);
        if (imageData && imageData.src) {
            return `data:image/png;base64,${imageData.src}`;
        }
    } else if (imageLink) {
        return imageLink.startsWith("/") ? `https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com${imageLink}` : imageLink.replace("&dl=0", "&raw=1");
    }
    return null; // Fallback if neither link nor nodeId yields a valid URL
}

export default MlCardMedia;
