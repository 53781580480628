import React from "react";
import { useAuth } from "../../AuthContext";
import { Navigate } from "react-router-dom";
import { MlBg, MlBoard } from './mainlistcomponents';

import MlCanvas from './cannevas/MlCanvas'
// Import the MlCanvasProvider
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';

//import { Margin } from "@mui/icons-material";
import { setVhProperty } from '../../utils';

const MlEditor = () => {
  const { currentUser, logOut } = useAuth();

  const { getBackgroundColor } = useMlCanvas();
  const handleLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      alert("Error logging out: " + error.message);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  //<MlBoard padding='' backgroundColor='white'>
  return (
    <MlBg>
      <MlBoard
        padding=''
        backgroundColor={getBackgroundColor()}
      >
        <MlCanvas />
      </MlBoard>
    </MlBg>
  );
};

export default MlEditor;
