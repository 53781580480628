import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { createNewNode, createNewEdge } from '../../../MlModels/MlNodeUtils';
import { AppendJSONFlow } from '../../../MlModels/MlAppendJsonFlow';
import MlCanvasDock from './MlCanvasDock';
import MlCanvasControls from './MlCanvasControls';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useReactFlow,
    Panel,
    Controls
} from 'reactflow';
import 'reactflow/dist/style.css';
import NoteNode from './NoteNode';
import './index.css';
import MlPanelIcon from '../MlPanelIcon'
import {
    Stack,
    Box
} from '@mui/material';
//import MlCanvasList from '../MlCanvasList';
import MlCanvasMenu from '../MlCanvasMenu';
import MlCanvasToolBox from './MlCanvasToolBox';
import MlAnimationPanel from '../MlAnimationPanel';
import { useMlCanvas } from '../../../MlModels/MlCanvasProvider';
import { MlBoard } from '../mainlistcomponents';
import MlCanvasRightPanel from './MlCanvasRightPanel';
import HtmlCanvas from './HtmlCanvas'; // Import the new HtmlCanvas component

const flowKey = 'example-flow';
const initialNodes = [];
const initialEdges = [];
const proOptions = { hideAttribution: true };

// Hardcoded constant for display
const htmlDisplay = false;

const MlCanvas = (props) => {
    const {
        viewOnly = false,
        showAnimationPanel = false,
        lock = false,
        showNavigation = true,
        showPlayStop = false
    } = props;

    const [mlNode, setMlNode] = useState(null);

    const {
        nodes,
        setNodes,
        onNodesChange,
        edges,
        setEdges,
        onEdgesChange,
        setRfInstance,
        updateContainerSize,
        rfInstance,
        onMoveEnd
    } = useMlCanvas();

    const flowContainerRef = useRef(null);
    const [isCanvasSetupVisible, setIsCanvasSetupVisible] = useState(false);
    const [isFooterVisible, setIsFooterVisible] = useState(showAnimationPanel);

    const { setViewport } = useReactFlow();
    const { project } = useReactFlow();

    const [customNodeData, setCustomNodeData] = useState({});

    useEffect(() => {
        const updateSize = () => {
            if (flowContainerRef.current) {
                updateContainerSize(flowContainerRef.current.offsetWidth, flowContainerRef.current.offsetHeight);
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, [updateContainerSize]);

    const onConnect = useCallback((params) =>
        setEdges((eds) => addEdge(createNewEdge(params), eds)),
        [setEdges]
    );

    useEffect(() => {
        if (rfInstance && mlNode && mlNode.canvas) {
            rfInstance.fromObject(mlNode.canvas);
            setNodes(mlNode.canvas.nodes || []);
            setEdges(mlNode.canvas.edges || []);
        }
    }, [rfInstance, mlNode, setNodes, setEdges]);

    const updateNodeData = useCallback((id, newData) => {
        setNodes((prevNodes) => {
            return prevNodes.map((node) => {
                if (node.id === id) {
                    return { ...node, data: newData };
                }
                return node;
            });
        });
    }, [setNodes]);

    const nodeTypes = useMemo(() => ({
        selectorNode: (node) => <NoteNode {...node} updateNodeData={updateNodeData} viewOnly={viewOnly} />,
    }), [updateNodeData, viewOnly]);

    const lastMousePosition = useRef({ x: 0, y: 0 });
    useEffect(() => {
        const handleMouseMove = (e) => {
            lastMousePosition.current = { x: e.clientX, y: e.clientY };
        };

        const handleKeyDown = async (e) => {
            if (e.ctrlKey && e.key.toLowerCase() === 'u') {
                e.preventDefault();
                const task = e.shiftKey;
                try {
                    const clipboardText = await navigator.clipboard.readText();
                    const json = JSON.parse(clipboardText);
                    if (!Array.isArray(json) || !json.every(item => 'title' in item && 'brief' in item)) {
                        console.error("Invalid JSON format");
                        return;
                    }
                    const position = project({ x: lastMousePosition.current.x, y: lastMousePosition.current.y });
                    const { nodes: newNodes, edges: newEdges } = AppendJSONFlow(json, nodes, edges, task, null, position.x, position.y);
                    setNodes(newNodes);
                    setEdges(newEdges);
                } catch (err) {
                    console.error("Failed to parse JSON", err);
                }
            }
            if (e.altKey && e.key === 'n') {
                e.preventDefault();
                const position = project({ x: lastMousePosition.current.x, y: lastMousePosition.current.y });
                const newNode = createNewNode(position);
                setNodes((nds) => nds.concat(newNode));
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [nodes, edges, setNodes, setEdges, project]);

    const noOp = () => { };
    const textColor = "rgb(250,250,248)";
    const headerBgColor = "rgb(80,80,85)";

    return (
        <MlBoard>
            <style>
                {`
                    .react-flow__pane {
                        cursor: pointer !important;
                    }
                `}
            </style>
            <Stack
                direction="row"
                sx={{ zIndex: 1000 }}
                alignItems="center"
                backgroundColor={headerBgColor}
            >
                <MlCanvasToolBox viewOnly={viewOnly} lock={lock}/>
                <Stack direction="row" width="100%" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
                    <MlCanvasDock viewOnly={viewOnly} color={textColor} />
                </Stack>
                {!viewOnly && (
                    <MlCanvasMenu aria-label="Menu" color={textColor} />
                )}
                {!viewOnly && (
                    <>
                        <MlPanelIcon
                            initialState={isFooterVisible}
                            onToggle={setIsFooterVisible}
                            color={textColor}
                            panel="bottom"
                        />
                        <MlPanelIcon
                            initialState={isCanvasSetupVisible}
                            onToggle={setIsCanvasSetupVisible}
                            color={textColor}
                            panel="right"
                        />
                    </>
                )}
            </Stack>
            <Stack direction="row" height="calc(100% - 39px)" width="100%" ref={flowContainerRef}>
                <Stack direction="column" width="100%">
                    {htmlDisplay ? (
                        
                            <HtmlCanvas />
                        
                    ) : (
                        <ReactFlow
                            minZoom={0.01}
                            maxZoom={10}
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={viewOnly ? noOp : onNodesChange}
                            onEdgesChange={viewOnly ? noOp : onEdgesChange}
                            onConnect={viewOnly ? noOp : onConnect}
                            nodesDraggable={!viewOnly}
                            panOnScroll={false}
                            nodesConnectable={!viewOnly}
                            nodeTypes={nodeTypes}
                            onInit={(reactFlowInstance) => {
                                setRfInstance(reactFlowInstance);
                            }}
                            zoomOnScroll={!lock}
                            zoomOnPinch={!lock}
                            panOnDrag={!lock}
                            proOptions={proOptions}
                        />
                    )}
                    {isFooterVisible && (
                        <Box width="100%" height="80px" backgroundColor={headerBgColor}>
                            <MlAnimationPanel showNavigation={false} showPlayStop={true} />
                        </Box>
                    )}
                </Stack>
                {isCanvasSetupVisible && <MlCanvasRightPanel />}
            </Stack>
        </MlBoard>
    );
};

export default MlCanvas;
