import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function OptionsList({
    initialLeft = [],
    initialRight = [],
    renderItem = (item) => `${item}`,
    onListsChange = () => { }
}) {
    // Type checking for initialLeft and initialRight
    if (!Array.isArray(initialLeft) || !Array.isArray(initialRight)) {
        throw new Error('OptionsList expects initialLeft and initialRight to be arrays');
    }

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(initialLeft);
    const [right, setRight] = React.useState(initialRight);
    const [filter, setFilter] = React.useState("");

    // Updates when initialRight or initialLeft change
    React.useEffect(() => {
        setLeft(not(initialLeft, initialRight));
    }, [initialLeft, initialRight]);

    React.useEffect(() => {
        setRight(initialRight);
    }, [initialRight]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        const newLeft = not(left, leftChecked);
        const newRight = right.concat(leftChecked);
        setRight(newRight);
        setLeft(newLeft);
        setChecked(not(checked, leftChecked));
        onListsChange(newLeft, newRight);
    };

    const handleCheckedLeft = () => {
        const newRight = not(right, rightChecked);
        const newLeft = left.concat(rightChecked);
        setRight(newRight);
        setLeft(newLeft);
        setChecked(not(checked, rightChecked));
        onListsChange(newLeft, newRight);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredLeft = left.filter(item => item.toLowerCase().includes(filter.toLowerCase()));

    const customList = (title, items) => (
        <Card sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    px: 2,
                    py: 1,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={
                                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{
                                'aria-label': 'all items selected',
                            }}
                        />
                        <Stack direction="column" alignItems="flex-start">
                            <Typography>{title}</Typography>
                            <Typography>{`${numberOfChecked(items)}/${items.length} séléctionnée(s)`} </Typography>
                        </Stack>
                    </Stack>
                    {title === "Options" &&
                        <Box sx={{ flexGrow: 1 }}>
                            <TextField
                                fullWidth
                                label="Filtre"
                                value={filter}
                                onChange={handleFilterChange}
                            />
                        </Box>
                    }
                </Stack>
            </Box>

            <Divider />
            <List
                sx={{
                    width: '100%',
                    height: 230,
                    flex: '1 1 auto',
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={renderItem(value)} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%', flexGrow: 1 }}>
            <Grid item xs={12} sx={{ flexGrow: 1 }}>{customList('Options', filteredLeft)}</Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center">
                    <Button
                        sx={{ mx: 0.5 }}
                        variant="contained"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="Move selected down"
                        color="primary"
                    >
                        ↓
                    </Button>
                    <Button
                        sx={{ mx: 0.5 }}
                        variant="contained"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="Move selected up"
                        color="error"
                    >
                        ↑
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ flexGrow: 1 }}>{customList('Options Choisies', right)}</Grid>
        </Grid>
    );
}
