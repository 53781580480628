import React, { useEffect, useState } from 'react';
import MlFileUploader from '../Mainlist/Dialogs/MlFileUploader';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack
} from '@mui/material';

import { stringId } from '../../MlModels/MlNode';



function LinkDialog({ isOpen, onClose, onAddLink, initialUrl = '' }) {
  const [url, setUrl] = useState(initialUrl);
  const [copyright, setCopyright] = useState('');
  const [linkType, setLinkType] = useState('');
  const [width, setWidth] = useState('');

  useEffect(() => {
    if (!initialUrl) {
      setUrl('');
      setLinkType('');
      setCopyright('');
      setWidth('');
      return;
    }

    const [prefixAndPath, params] = initialUrl.split('?');
    const colonIndex = prefixAndPath.indexOf(':');
    if (colonIndex !== -1) {
      const prefix = prefixAndPath.substring(0, colonIndex);
      const path = prefixAndPath.substring(colonIndex + 1);
      setUrl(path);

      // Map prefix to linkType
      const prefixToLinkType = {
        'image': 'Image Link',
        'img': 'Embedded Image',
        'author': 'Canvas',
        'target': 'Target',
        'qdraft': 'Qdraft Link',
      };
      setLinkType(prefixToLinkType[prefix] || 'HTTPS');
    } else {
      setUrl(prefixAndPath);
      setLinkType('HTTPS');
    }

    // Parse parameters
    const queryParams = new URLSearchParams(params);
    const version = queryParams.get('v');
    const copyright = queryParams.get('copy');
    const width = queryParams.get('width');

    // Set state for each parameter if it exists
    if (version) {
      // Handle version if needed
    }
    if (copyright) {
      setCopyright(copyright);
    }
    if (width) {
      setWidth(width);
    }
  }, [initialUrl, isOpen]);


  useEffect(() => {
    setUrl(initialUrl);
    // Resetting states when the dialog is reopened or the initial URL changes
    setLinkType('');
    setCopyright('');
  }, [initialUrl, isOpen]);

  useEffect(() => {
    if (!initialUrl) {
      setUrl('');
      setLinkType('');
      setCopyright('');
      setWidth('');
      return;
    }

    const [prefixAndPath, params] = initialUrl.split('?');
    const colonIndex = prefixAndPath.indexOf(':');
    if (colonIndex !== -1) {
      const prefix = prefixAndPath.substring(0, colonIndex);
      const path = prefixAndPath.substring(colonIndex + 1);
      setUrl(path);

      // Map prefix to linkType
      const prefixToLinkType = {
        'image': 'Image Link',
        'img': 'Embedded Image',
        'author': 'Canvas',
        'canvas': 'Canvas',
        'target': 'Target',
        'qdraft': 'Qdraft Link',
      };
      setLinkType(prefixToLinkType[prefix] || 'HTTPS');
    } else {
      setUrl(prefixAndPath);
      setLinkType('HTTPS');
    }

    // Parse parameters
    const queryParams = new URLSearchParams(params);
    const version = queryParams.get('v');
    const copyright = queryParams.get('copy');
    const width = queryParams.get('width');

    // Set state for each parameter if it exists
    if (version) {
      // Handle version if needed
    }
    if (copyright) {
      setCopyright(copyright);
    }
    if (width) {
      setWidth(width);
    }
  }, [initialUrl, isOpen]);


  const handleSubmit = (e) => {
    e.preventDefault();

    let prefix = '';
    switch (linkType) {
      case 'Image Link':
        prefix = 'image:';
        break;
      case 'Embedded Image':
        prefix = 'img:';
        break;
      case 'Canvas':
        prefix = 'canvas:';
        break;
      case 'Target':
        prefix = 'target:';
        break;
      case 'Qdraft Link':
        prefix = 'qdraft:';
        break;
      default:
        // Assuming HTTPS is the default case
        prefix = '';
    }

    // Initialize URLSearchParams
    let queryParams = new URLSearchParams();
    if (copyright) {
      queryParams.set('copy', copyright);
    }
    if (width) {
      queryParams.set('width', width);
    }

    // Check if the URL already has a query string
    const hasQueryString = url.includes('?');
    const queryString = queryParams.toString();
    const shouldAppendQuery = queryString.length > 0;

    // Rebuild the URL with parameters, using ? or & appropriately
    // Only append query string if necessary
    const finalUrl = `${prefix}${url}${hasQueryString ? '&' : shouldAppendQuery ? '?' : ''}${queryString}`;

    onAddLink(finalUrl);
    onClose();
  };


  function getVersionString() {
    const seconds = Math.floor(new Date().getTime() / 1000);
    return `v=${seconds}`;
  }

  const handleFileUploadComplete = (fileContent) => {
    const trimmedFileContent = fileContent.trim();
    if (trimmedFileContent) { // Check if the trimmed content is not empty
      const finalUrl = `/${trimmedFileContent}?${getVersionString()}`;
      setUrl(finalUrl);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{initialUrl ? 'Update Link' : 'Add Link'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Please enter the URL for the link.
          </DialogContentText>
          {/* Link Type Dropdown */}
          <FormControl fullWidth margin="dense">
            <InputLabel id="link-type-label">Link Type</InputLabel>
            <Select
              labelId="link-type-label"
              id="link-type"
              value={linkType}
              label="Link Type"
              onChange={(e) => setLinkType(e.target.value)}
            >
              <MenuItem value="HTTPS">HTTPS</MenuItem>
              <MenuItem value="Target">Target</MenuItem>
              <MenuItem value="Canvas">Canvas</MenuItem>
              <MenuItem value="Image Link">Image Link</MenuItem>
              <MenuItem value="Embedded Image">Embedded Image</MenuItem>
              <MenuItem value="Qdraft Link">Qdraft Link</MenuItem>
            </Select>
          </FormControl>

          <Stack direction='row' alignItems="center">


            <TextField
              autoFocus
              margin="dense"
              id="url"
              label="Link URL"
              type="text"
              fullWidth
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <MlFileUploader
              onUpload={handleFileUploadComplete}
              folderPath={`QDRAFT/`}
              userId={stringId()}
              accept="image/*"
              multiple={false}
            />
          </Stack>
          {/* Copyright Field */}
          <TextField
            margin="dense"
            id="copyright"
            label="Copyright"
            type="text"
            fullWidth
            value={copyright}
            onChange={(e) => setCopyright(e.target.value)}
          />
          {/* Copyright Field */}
          <TextField
            margin="dense"
            id="Width"
            label="Width"
            type="text"
            fullWidth
            value={width}
            onChange={(e) => setWidth(e.target.value)} // Corrected from setCopyright
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onClose(); setUrl(''); }} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            {initialUrl ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LinkDialog;
