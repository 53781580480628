import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Play from '@mui/icons-material/PlayArrowRounded';
import Stop from '@mui/icons-material/StopRounded';
import Previous from '@mui/icons-material/SkipPreviousRounded';
import First from '@mui/icons-material/RestartAltRounded';
import Next from '@mui/icons-material/SkipNextRounded';
import Save from '@mui/icons-material/SaveRounded';
import Edit from '@mui/icons-material/EditRounded';
import Delete from '@mui/icons-material/DeleteRounded';
import TreeIcon from '@mui/icons-material/AccountTree';
import SubjectsBoard from './SubjectsBoard';



import { Box } from '@mui/material';
import { useEmotion } from './EmotionContext'; // Import the EmotionContext

const AnimationIcons = () => {
  const { isPlaying, playVideo, stopVideo, resetVideo, skip } = useEmotion(); // Destructure the video control functions
  const [isDashboardOpen, setDashboardOpen] = useState(false);

    const handleDashboardOpen = () => {
        setDashboardOpen(true);
    };

    const handleDashboardClose = () => {
        setDashboardOpen(false);
    };


  const handleClick = (action) => {
    if (action === 'togglePlay') {
      if (isPlaying) {
        stopVideo(); // Stop all videos
      } else {
        playVideo(); // Play all videos
      }
    } else if (action === 'first') {
      resetVideo(); // Reset all videos to time 0
    } else if (action === 'previous') {
      skip('previous'); // Skip to the previous segment
    } else if (action === 'next') {
      skip('next'); // Skip to the next segment
    } else if (action === 'save') {
      // Future save function
    } else if (action === 'edit') {
      // Future edit function
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" position="relative" width="100%">
        {/* Box for Save and Edit icons, aligned to the left */}
        <Box display="flex" alignItems="center" position="absolute" left={0}>
          <IconButton
            onClick={() => handleClick('save')}
            aria-label="Save"
          >
            <Save />
          </IconButton>
          <IconButton
            onClick={() => handleClick('edit')}
            aria-label="Edit"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => handleClick('delete')}
            aria-label="Delete"
          >
            <Delete />
          </IconButton>
          <IconButton
            onClick={handleDashboardOpen}
            aria-label="Datbase"
          >
            <TreeIcon />
          </IconButton>
        </Box>

        {/* Box for the other icons, centered */}
        <Box display="flex" alignItems="center" justifyContent="center" flex="1">
          <IconButton
            onClick={() => handleClick('first')}
            aria-label="First"
          >
            <First />
          </IconButton>
          <IconButton
            onClick={() => handleClick('previous')}
            aria-label="Previous"
          >
            <Previous />
          </IconButton>
          <IconButton
            onClick={() => handleClick('togglePlay')}
            aria-label={isPlaying ? 'Stop' : 'Play'}
            style={{
              marginLeft: '-15px',
              marginRight: '-15px'
            }}
          >
            {isPlaying ? <Stop /> : <Play />}
          </IconButton>
          <IconButton
            onClick={() => handleClick('next')}
            aria-label="Next"
          >
            <Next />
          </IconButton>
        </Box>
      </Box>


      {/* Render the DashBoard as a Dialog */}
      <SubjectsBoard
        open={isDashboardOpen}
        handleClose={handleDashboardClose}
        identity='emotion'
      />
    </>

  );
};

export default AnimationIcons;
