import React, { useState, useRef, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from "../../AuthContext";
import parseExcel from '../../MlModels/Importing/MlImportXlsx';

import { useMlCanvas } from '../../MlModels/MlCanvasProvider';  // Replace with the actual path





export default function MlCanvasMenu({ color = 'black' }) {

  const [canvasList, setCanvasList] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clipboardData, setClipboardData] = useState({ nodes: [], edges: [] }); // New state for clipboard data
  const anchorRef = useRef(null);
  const navigate = useNavigate(); // Initialize the hook
  const {
    //rootCanvasList,
    loadCanvas,
    saveToDatabase,
    createNewCanvas,
    deleteCurrentCanvas,
    pasteElements,
    cutSelectedElements,
    copySelectedElements,
    //fetchRootCanvasList,
    getRootCanvasList,
    addAnimationTarget,
    deleteCurrentAnimationTarget,
    goTarget,
    createTextOutline,
    playCanvas,
  } = useMlCanvas();

  useEffect(() => {
    console.log("Clipboard Data Updated:", clipboardData);
  }, [clipboardData]);

  const handleCalculateBounds = () => {
    addAnimationTarget();
    // Additional logic if needed
  };

  const handleGetCanvasOutline = () => {
    createTextOutline();
    // Additional logic if needed
  };

  const handlePlayCanvas = () => {
    playCanvas();
    // Additional logic if needed
  };

  const handleDeleteCurrentCanvas = () => {
    setDialogOpen(true);
  };

  const { logOut } = useAuth();

  const handleLogout = async () => {
    if (logOut) {
      await logOut();
    }
    navigate("/");
    // Additional logic, if needed, can go here
  };

  const handleGoToTarget = () => {
    goTarget(); // Example: Go to the first target
  };

  const handleNewCanvas = async () => {
    // Check if there is a current canvas and if it needs to be saved
    await createNewCanvas();
    getRootCanvasList();
  };

  const handleCutNodes = () => {
    cutSelectedElements((selectedNodes, selectedEdges) => {
      setClipboardData({ nodes: selectedNodes, edges: selectedEdges });
    });
  };

  const handleCopyNodes = () => {
    copySelectedElements((selectedNodes, selectedEdges) => {
      setClipboardData({ nodes: selectedNodes, edges: selectedEdges });
    });
  };

  const handlePasteNodes = () => {
    pasteElements(clipboardData.nodes, clipboardData.edges);
    // Clear the clipboard data after pasting
    setClipboardData({ nodes: [], edges: [] });
  };

  const confirmDelete = () => {
    deleteCurrentCanvas();
    setDialogOpen(false);
  };

  const handleToggle = async () => {

    setOpen((prevOpen) => !prevOpen);
    if (!open) {
      const updatedList = await getRootCanvasList();
      setCanvasList(updatedList);
      // Use the updated list directly as needed
      // Note: No need to update rootCanvasList state here if it's not required elsewhere
    }
  };


  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const fileInputRef = useRef(null);

  const handleFileSelected = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const data = await parseExcel(file);
        console.log(data); // Your array of objects from Excel file
        // You can now do whatever you need with this data
      } catch (error) {
        console.error('Error parsing file:', error);
      }
    }
  };

  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MenuIcon style={{ color }} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="simple-menu"
                  aria-labelledby="simple-menu-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ maxHeight: 'calc(100vh - 180px)', overflow: 'auto' }}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  <Divider />
                  {/** 
                  <MenuItem onClick={handleGetCanvasOutline}>Get Canvas Outline</MenuItem>
                  <MenuItem onClick={handlePlayCanvas}>Play Canvas</MenuItem>
                  */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>


      <Dialog
        id="deleteCanvasDialog"  // Unique ID for the delete canvas dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle id="deleteCanvasDialogTitle">{"Are you sure you want to delete this canvas?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="deleteCanvasDialogContentText">
            Deleting this canvas will remove it permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="deleteCanvasCancelBtn" onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button id="deleteCanvasConfirmBtn" onClick={confirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelected}
        style={{ display: 'none' }}
        accept=".xlsx, .xls" // Optionally restrict file types
      />
    </div>
  );
}
