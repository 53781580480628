import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null, // or 'anonymous' or some other default
  name: null,
  email: null,
  // add other user properties as needed
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      // assuming the action payload is an object with user properties
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      // set other user properties as needed
    },
    clearUser: (state) => {
      state.id = null;
      state.name = null;
      state.email = null;
      // clear other user properties as needed
    },
    // add other user actions as needed
  },
});

export const selectUser = (state) => state.user;
export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;