import React from 'react';
import { Stack, Menu, MenuItem } from '@mui/material';
import MlC from '../../base/MlComponents';
import { useMlCanvas } from '../../../MlModels/MlCanvasProvider';

const Forms = ({
  components,
  componentValues,
  onAddComponent,
  onComponentValueChange,
  onMenuClose,
  anchorEl,
}) => {

  const {
    mlNode
  } = useMlCanvas();

const test = mlNode

  return (
    <Stack spacing={1}>
      
      <Menu
        id="add-property-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
      >
        {Object.keys(MlC).map((componentType) => (
          <MenuItem key={componentType} onClick={() => onAddComponent(componentType)}>
            {componentType}
          </MenuItem>
        ))}
      </Menu>
      <Stack direction="column">
        {components.map((propertyName, index) => {
          const componentType = propertyName.split('_')[1];
          const Component = MlC[componentType];
          if (!Component) return null;

          const value = componentValues[propertyName] || '';

          return (
            <Component
              key={propertyName}
              propertyName={propertyName.split('_')[0].replace(/-/g, ' ')}
              value={value}
              onChange={(newValue) => onComponentValueChange(propertyName, newValue)}
              editable={false}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default Forms;