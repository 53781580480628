import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Paper, Typography } from "@mui/material";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUser } from './redux/UserSlice';

const LogIn = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { logIn, currentUser, resendConfirmationEmail, sendResetPasswordEmail  } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Use this hook to get the dispatch function

  useEffect(() => {
    if (currentUser) {
      console.log('USERID:',currentUser.id); // Log the user object for debugging

      // Dispatch the setUser action
      dispatch(setUser({
        id: currentUser.id,
        name: currentUser.profile?.data?.email, // Use email as name
        email: currentUser.profile?.data?.email
      }));

      // Navigate to home page (or other route) after login
      navigate("/");
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  }, [currentUser, dispatch, navigate, onClose]);

  const handleResend = async () => {
    try {
      await resendConfirmationEmail(email); // Resend the confirmation email
    } catch (error) {
      alert("Error resending confirmation email: " + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await logIn(email, password);
    } catch (error) {
      alert("Error logging in: " + error.message);
    }
  };

  const handleReset = async () => {
    try {
      await sendResetPasswordEmail(email);  // Using it from AuthContext
      alert("Reset password email sent successfully.");
    } catch (error) {
      alert("Error sending reset password email: " + error.message);
    }
  };

  return (
    <Grid container  justifyContent="center" >
      <Paper elevation={2}  style={{ padding: "2rem", maxWidth: '500px' }}>
        <Typography variant="h4">Log In</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "1rem" }}
          >
            Log In
          </Button>
          <Button
            onClick={() => navigate("/signup")}
            variant="contained"
            color="secondary"
            fullWidth
            style={{ marginTop: "1rem" }}
          >
            Sign Up
          </Button>
          <Button
            onClick={handleResend} // call handleResend function on click
            variant="contained"
            color="secondary"
            fullWidth
            style={{ marginTop: "1rem" }}
          >
            Resend Confirmation
          </Button>
          <Button
            onClick={handleReset} // call handleResend function on click
            variant="contained"
            color="warning"
            fullWidth
            style={{ marginTop: "1rem" }}
          >
            Reset Password
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default LogIn;