import React from 'react';
import Stack from '@mui/material/Stack';
import MlIcon from './MlIcon'; // Assuming this is your custom icon component

const MlIconBox = ({ data, iconSize, onIconClick, disabledTool }) => {
    return (
        <Stack direction="row" spacing={2}>
            {data.map(({ icon, text }, index) => (
                <MlIcon
                    key={index}
                    icon={icon}
                    text={text}
                    iconSize={iconSize}
                    onClick={onIconClick}
                    disabled={text === disabledTool} // Set disabled based on the text
                />
            ))}
        </Stack>
    );
};

export default MlIconBox;