export const getProjectPrompt = (project, levels, maxtasks) => {
  const jsonformat = JSON.stringify([
      {
          "title": "Root",
          "brief": "This is the root",
          "children": [
              {
                  "title": "Child1",
                  "brief": "This is child 1",
                  "children": [
                      {
                          "title": "Child1.1",
                          "brief": "This is child 1.1"
                      }
                  ]
              },
              {
                  "title": "Child2",
                  "brief": "This is child 2"
              }
          ]
      }
  ]);

  return `
"Develop the project ${project} by creating nodes as shown in the example ${jsonformat}. 
${jsonformat} show you the format to use you can vary the number of nodes and the number of childre.
However for each level we should try to not have more than ${maxtasks} children.
You have to recursively generate children using the brief as input until ${levels} is reached
In the brief of each children you have to describe the work to do, the level of difficulty and the estimated duration.
please express the level of difficulty using a fibonacci sequence.
We don't want to have difficulties slightly different.
The uint is points: diffiulty: 1 point, diffculty: 2 points, difficulty: 3 points, difficulty: 5 points ...
The root node should contain an introduction to the project.
The answer should contain the json object and only the json object excluding any other text.
It is really imperative as the object will be directly processed after received.
The resulting json must be an array of objects (even if there is one object only)
The title of each node should represent what is in the brief. But with two or three words.
The language of the answer must be the same as the language of the prompt.
`;
};

