import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#333333', // dark grey
    color: '#ffffff', // white text
    padding: theme.spacing(4, 0),
}));

const Intro = `Belgicomics est une encyclopédie dédiée aux créateurs de littératures en dessins ayant des liens avec la Belgique. Des créations locales, nationales et internationales y seront ainsi recensées de 1830 - année de naissance du pays – à aujourd’hui.
C’est un chantier collectif visant à établir une cartographie objective des créations (sans hiérarchie de tirages ou d'un quelconque indice de popularité). Il comportera deux points d’entrée : par textes (biobibliographie) et par images (éléments graphiques).
Il disperse ses informations sur la toile et traverse d’emblée les limites des frontières belges.
Un gigantesque patrimoine prêt à être (re)découvert.`

const BelgicomicsFooter = () => {
    return (
        <FooterContainer>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            About Us
                        </Typography>
                        <Typography variant="body2">
                            {Intro}
                        </Typography>
                    </Grid>
                    {/* 
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Links
            </Typography>
            <Link href="#" color="inherit" underline="hover">
              Home
            </Link>
            <br />
            <Link href="#" color="inherit" underline="hover">
              Services
            </Link>
            <br />
            <Link href="#" color="inherit" underline="hover">
              Contact
            </Link>
          </Grid>
         
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact
                        </Typography>
                        <Typography variant="body2">
                            Email:
                        </Typography>
                        <Typography variant="body2">
                            Phone:
                        </Typography>
                    </Grid>
                     */}
                </Grid>

                <Box textAlign="center" pt={5} pb={2}>
                    <Typography variant="body2">
                        © {new Date().getFullYear()} Belgicomics. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </FooterContainer>
    );
};

export default BelgicomicsFooter;