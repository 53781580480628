import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Radio, TextField, Stack, RadioGroup, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import CarTypes from './CarTypes';
import Motorisation from './Motorisation';
import BrandModelSelect from '../BelCar/form/BrandModelSelect';
import PowerInput from '../BelCar/base/PowerInput';
import CarStates from './CarStates';
import CarTarifs from './CarTarifs';
import Registration from './Registration';
import Transmission from './Transmission';

export default function DashBoardPro({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>
                <TextField
                    label="Nom de l'entreprise"
                    name="businessName"
                    value={node?.businessName || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Identité Unique"
                    name="businessIdentity"
                    value={node?.businessIdentity || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Numero d'entreprise"
                    name="taxNumber"
                    value={node?.taxNumber || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Email de contact"
                    name="contactMail"
                    value={node?.contactMail || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Email info"
                    name="infoMail"
                    value={node?.infoMail || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Editeurs"
                    name="editors"
                    value={node?.infoMail || ''}
                    onChange={handleInputChange}
                />
            </Stack>
        </FormGroup>
    );
}
