import React from 'react';
import { FormGroup, TextField, Stack, Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from '@mui/material';
import CarKeys from './CarKeys';
import OptionsList from '../BelCar/base/OptionList';
import BodyColor from './BodyColor';
import InteriorColor from './InteriorColor';
import PaintType from './PaintType';
import InteriorTrim from './InteriorTrim';
import { getOptionsData } from './optionsData';

export default function CarDetails({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    const initialLeftData = getOptionsData();
    //console.log('PC optionDatafromFile', actualInitialLeftData)
    //const actualInitialRightData = node.options;

    const handleOptionsChange = (left, right) => {
        onChange('options', right);
    };

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>
                <TextField
                    name="warranties"
                    value={node?.warranties || ''}
                    fullWidth
                    label="Garanties"
                    variant="outlined"
                    onChange={handleInputChange}
                />
                <TextField
                    name="reference"
                    value={node?.reference || ''}
                    fullWidth
                    label="Référence"
                    variant="outlined"
                    onChange={handleInputChange}
                />
                <TextField
                    name="co2"
                    value={node?.co2 || ''}
                    fullWidth
                    label="Emissions Co2"
                    variant="outlined"
                    onChange={handleInputChange}
                />
                <TextField
                    name="classeEmission"
                    value={node?.classeEmission || ''}
                    fullWidth
                    label="Classe d'émission"
                    variant="outlined"
                    onChange={handleInputChange}
                />
                <Divider />
                <BodyColor
                    value={node?.bodyColor || ''}
                    onChange={handleInputChange}
                />
                <InteriorColor
                    value={node?.interiorColor || ''}
                    onChange={handleInputChange}
                />
                <InteriorTrim
                    value={node?.interiorTrim || ''}
                    onChange={handleInputChange}
                />
                <PaintType
                    value={node?.painttype || false}
                    onChange={handleInputChange}
                />
                <Divider />
                <CarKeys
                    values={{
                        sliderKeys: node?.sliderKeys || 0,
                        sliderKeysTele: node?.sliderKeysTele || 0,
                        sliderNoContact: node?.sliderNoContact || 0,
                        sliderTeleSeule: node?.sliderTeleSeule || 0
                    }}
                    onChange={handleInputChange}
                />
                <Accordion>
                    <AccordionSummary>
                        <Typography>Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OptionsList
                            initialLeft={initialLeftData} // Replace with actual initial data
                            initialRight={node?.options || []}
                            onListsChange={handleOptionsChange}
                        />
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </FormGroup>
    );
}
