import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useEmotion } from './EmotionContext';

const EmotionBar = ({ width = '100%', height = 20 }) => {
  const { collectedData, colors } = useEmotion();

  useEffect(() => {
    console.log('CollectedData in EmotionBar:', collectedData);
  }, [collectedData]);

  // Calculate the percentage width of each emotion segment
  const segments = collectedData.map((data, index) => {
    const nextData = collectedData[index + 1];
    const startPercentage = data.selectedTime * 100;
    const endPercentage = nextData ? nextData.selectedTime * 100 : 100;
    const widthPercentage = endPercentage - startPercentage;

    // Debugging output to check color retrieval
    const color = colors[data.emotion.toLowerCase()];
    console.log(`Emotion: ${data.emotion}, Color: ${color}, Start: ${startPercentage}%, Width: ${widthPercentage}%`);

    return {
      emotion: data.emotion,
      color: color || 'transparent', // Fallback to transparent if color is undefined
      startPercentage: Math.max(startPercentage, 0), // Ensure it's at least 0
      widthPercentage: Math.max(widthPercentage, 0), // Ensure it's at least 0
    };
  });

  return (
    <Box sx={{ width, height, display: 'flex', position: 'relative', backgroundColor: '#e0e0e0' }}>
      {segments.map((segment, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: segment.color, // Apply the emotion color
            position: 'absolute',
            left: `${segment.startPercentage}%`,
            width: `${segment.widthPercentage}%`,
            height: '100%',
            transition: 'width 0.2s ease-out', // Smooth transition for visual effect
            //border: '1px solid black', // Keep the border for visibility during testing
          }}
        />
      ))}
    </Box>
  );
};

export default EmotionBar;
