import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import MlTooltip from '../cannevas/MlToolTip';

const MlBriefViewOnlyDialog = ({ open, onClose, title, content }) => {
    const handleCancel = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="ml-tooltip-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="ml-tooltip-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <MlTooltip text={content} maxWidth='100%' />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MlBriefViewOnlyDialog;