import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  cursor: pointer;
  width: 100%; /* Ensure the container takes full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
`;

export const ImageSection = styled.div`
  width: 100px; /* Fixed width */
  min-width: 100px; /* Ensure it does not shrink */
  height: 100px; /* Fixed height */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire area without distortion */
  }
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px; /* Space between the image and content */
  flex-grow: 1; /* Allow the content to grow */
  overflow: hidden; /* Hide overflow content */
`;

export const Title = styled.div`
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  word-wrap: break-word; /* Ensure long words break to the next line */
`;

export const Summary = styled.div`
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  color: #666; /* Optional: lighter color for summary */
  word-wrap: break-word; /* Ensure long words break to the next line */
  margin-top: 8px; /* Space between title and summary */
`;
