import styled from 'styled-components';

export const Display = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f0f0f0; /* You can change this color */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Stack = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: ${(props) => `${props.spacing * 8}px` || '0px'}; /* Convert spacing prop to pixels */
`;

export const Footer = styled.footer`
  width: 100%;
  height: 150px;
  background-color: #333; /* You can change this color */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const BottomNavigationBar = styled.div`
  display: flex;
  justify-content: center; /* Center the group of buttons */
  align-items: center;
  width: 100%;
  
  position: absolute;
  bottom: 0;
   /* background-color: #ccc; You can change this color */
  gap: ${(props) => `${props.spacing * 8}px` || '0px'}; /* Convert spacing prop to pixels */
  padding: 0 ${(props) => `${props.spacing * 8}px` || '0px'}; /* Add padding to handle spacing on the edges */
`;

