// AutodinLandingPage.js

import React, { useRef, useState, useEffect } from "react";
import AutodinFooter from './AutodinFooter';
import AutoDinList from './AutoDinList';
import SearchBox from '../SearchBox';
import AddButton from "../AddButton";
import TabPanelStyled from '../TabPanelStyled';
import AutoDinLogo from './AutoDinLogo';
import CarForm from './CarForm';
import VehicleDetails from './VehicleDetails';
import AutodinMenu from './AutodinMenu';
import {
    Stack,
    Box,
    Button,
    Typography
} from '@mui/material';
import { getUserMail } from '../../MlModels/MlNode';
import { getEmbedding } from '../../MlModels/GPTembedding'

const AutodinLandingPage = ({ bgColor = 'rgb(230,230,230)', loggedIn = false }) => {
    const [isCarFormVisible, setCarFormVisible] = useState(false);
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [refreshList, setRefreshList] = useState(false);
    const [embedding, setEmbedding] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    const searchInputRef = useRef(null);

    const handleAddButtonClick = () => {
        setSelectedNodeId(null);
        setCarFormVisible(true);
    };

    const handleCloseCarForm = () => {
        setCarFormVisible(false);
        setRefreshList(prev => !prev);
    };

    const handleCardClick = (nodeId) => {
        setSelectedNodeId(null);
        setTimeout(() => {
            setSelectedNodeId(nodeId);
        }, 0);
    };

    useEffect(() => {
        if (selectedNodeId !== null) {
            setCarFormVisible(true);
        }
    }, [selectedNodeId]);

    const handleSearchClick = async () => {
        let searchText = searchInputRef.current.value;
        searchText = searchText.replace(/\n/g, " ");
        console.log("Search text:", searchText);

        if (searchText) {
            const embeddingResult = await getEmbedding(searchText);
            console.log("Generated embedding:", embeddingResult);
            setEmbedding(embeddingResult); // Store the embedding in state
        } else {
            setEmbedding(null); // Reset embedding if no search text
        }

        setRefreshList(prev => !prev); // Trigger a refresh in AutoDinList
    };

    const tabLabels = ["Edition", "Archive", "Ma liste", "Tout"];
    return (
        <>
            <Stack
                alignItems={'center'}
                sx={{
                    backgroundColor: bgColor,
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >
                <Stack mt={3} alignItems={'center'} position="relative" width="100%">
                    <AutoDinLogo width='12rem' />
                    <Typography
                        variant="h5"
                        component="h2"
                        mt={2}
                    >
                        {`Editeur: ${getUserMail()|| ''}`}
                    </Typography>
                    <Box position="absolute" top={0} right={0} m={2}>
                        <AutodinMenu />
                    </Box>
                </Stack>
                <Stack
                    sx={{
                        width: '98%',
                        maxWidth: '850px',
                    }}
                >
                    <Stack mt={0} alignItems='flex-end'>
                        {loggedIn && <AddButton tooltip='Add a car' onClick={handleAddButtonClick} />}
                        <Stack width='100%' alignItems='flex-end' mt={loggedIn ? 0 : 2} spacing={1}>
                            <SearchBox elevation={0} ref={searchInputRef} />
                            <Button variant="contained" size='small' onClick={handleSearchClick}>
                                Search
                            </Button>
                        </Stack>
                        <Box
                            mt={1}
                            mb={1}
                            sx={{
                                width: '100%',
                            }}>
                            {loggedIn &&
                                <TabPanelStyled
                                    tabLabels={tabLabels}
                                    selectedTab={selectedTab}
                                    onChange={(newIndex) => {
                                        setSelectedTab(newIndex);
                                        setRefreshList(prev => !prev); // Trigger a refresh in AutoDinList
                                    }}
                                />
                            }
                            <AutoDinList
                                elevation={2}
                                onCardClick={handleCardClick}
                                refresh={refreshList}
                                searchEmbedding={embedding}
                                selectedTab={selectedTab} // Pass selectedTab to AutoDinList
                            />
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <AutodinFooter />
            {loggedIn ? (
                <CarForm open={isCarFormVisible} onClose={handleCloseCarForm} nodeId={selectedNodeId} loggedIn={loggedIn} />
            ) : (
                selectedNodeId &&
                <VehicleDetails
                    open={isCarFormVisible}
                    onClose={handleCloseCarForm}
                    nodeId={selectedNodeId}
                    loggedIn={loggedIn}
                />
            )}
        </>
    );
};

export default AutodinLandingPage;

