export const getNotesPrompt = (concept, levels = 4, maxItems = 5, length = 400) => {
    const jsonformat = JSON.stringify([
        {
            "title": "Root",
            "brief": "This is the root",
            "children": [
                {
                    "title": "Child1",
                    "brief": "This is child 1",
                    "children": [
                        {
                            "title": "Child1.1",
                            "brief": "This is child 1.1"
                        }
                    ]
                },
                {
                    "title": "Child2",
                    "brief": "This is child 2"
                }
            ]
        }
    ]);

    return `

    
    Task:
  Review and process text of the '${concept}'. For each note (identified by the phrases "Note pour" or "Note for")
  Each note has to be stored in a json structure specified in '${jsonformat}'
  
  
  perform the following steps:
    1. For each note Create a title of 2 to 3 words for each "title": and use the original text of each note for the "brief":.
    2. Correct any spelling and grammatical errors to clarify each brief without altering the original meazning or omitting any details or summarizing ideas. Make absolutely certain that details are preserved.
    3. Organize these notes into a JSON structure as specified in '${jsonformat}'. The JSON should be organized by theme, with each level containing no more than '${maxItems}' items.
  Ensure that the final output is strictly the JSON structure of the knowledge tree, with no additional explanatory text. The content within the JSON should be in the language of the original notes and tailored for direct processing.

Please focus on precision and completeness in capturing all relevant information from each note, ensuring that the transformation into JSON maintains the full detail as per the original notes.
`;
};

