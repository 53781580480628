import React from 'react';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const FullScreenButton = ({ color = "inherit", size = "medium" }) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  // Appliquez le style directement aux icônes
  const iconStyle = {
    color, // Utilise la couleur passée en prop
    fontSize: size, // Utilise la taille passée en prop, peut être une valeur spécifique comme '24px'
  };

  return (
    <IconButton onClick={toggleFullScreen}>
      {isFullscreen ? <FullscreenExitIcon style={iconStyle} /> : <FullscreenIcon style={iconStyle} />}
    </IconButton>
  );
};

export default FullScreenButton;