import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageSlide from '../ImageSlide';
import QDraftSlide from '../QDraftSlide';

function MlSlideModal({ open, onClose, slideType, slide }) {
  // Modal container style
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1024px',
    maxWidth: 'calc(100% - 55px)',
    maxHeight: 'calc(100% - 20px)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    overflow: 'hidden', // Hide overflow to manage inside content
    display: 'flex',
    flexDirection: 'column',
  };

  // Content style that allows for scrolling
  const contentStyle = {
    padding: '20px',
    position: 'relative',
    overflowY: 'auto',
    maxHeight: 'calc(100% - 40px)', // Adjust if needed based on header size
    flexGrow: 1,
  };

  // Close button style for absolute positioning within the modal container
  const closeButtonStyle = {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 2, // Ensure the button is above the content
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {/* This IconButton is now absolutely positioned within the modal container */}
        <IconButton sx={closeButtonStyle} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        
        {/* Scrollable content */}
        <Box sx={contentStyle}>
          {slideType === 'image' && <ImageSlide src={slide} size="100%" />}
          {slideType === 'qdraft' && <QDraftSlide value={slide} />}
        </Box>
      </Box>
    </Modal>
  );
}

export default MlSlideModal;
