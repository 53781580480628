import styled from "styled-components";


const ThumbnailIcon = styled.img`
  position: relative;
  width: 250px;
  height: 168.46px;
  object-fit: cover;
`;
const Name1 = styled.div`
  align-self: stretch;
  position: relative;
`;
const Info = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  position: relative;
  font-size: 10px;
  font-weight: 300;
  font-family: Inter;
  color: #000;
  text-align: left;
  display: inline-block;
`;
const Summary = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
`;
const Currency = styled.div`
  position: relative;
`;
const Tvacomprise = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  flex: 1;
  position: relative;
  font-size: 10px;
  font-weight: 300;
  font-family: Inter;
  color: #000;
  text-align: left;
  display: inline-block;
`;
const Pricetva = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;
const plusTva = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  position: relative;
  font-size: 9px;
  font-weight: 300;
  font-family: Inter;
  color: #000;
  text-align: left;
  display: inline-block;
`;
const Price = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
`;
const Type = styled.div`
  position: relative;
  font-size: 9px;
  font-weight: 300;
  font-family: Inter;
  color: #000;
  text-align: left;
`;
const Vendeur = styled.button`
  cursor: pointer;
  border: none;
  padding: 4px 8px;
  background-color: #e9e9e9;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: auto;  /* Added this */
`;
const Details = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
  flex: 1;  /* Updated this */
`;

const CarcardRoot = styled.div`
  border-radius: 5px;
  height: 300px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; /* Updated this */
  text-align: left;
  font-size: 12px;
  color: #000;
  font-family: Inter;
`;
const VehicleCardDetails = ({
  info,
  denomination,
  sellerType,
  comments,
  price = 0,
  priceVAT = 0,
  taxDeductible = false,
  onClick // <-- Accept an onClick prop
}) => {

  return (
      <Details>
        <Summary>
          <Name1>{denomination}</Name1>
          <Info>{info}</Info>
          <Info>{comments}</Info>
        </Summary>
        {(price !== '0' && price !== 0 && price !== undefined) && (
          <Price>
            <Pricetva>
              <Currency>€</Currency>
              <Currency>{price} -</Currency>
              {taxDeductible ? <Tvacomprise>Tva Comprise</Tvacomprise> : <Tvacomprise>TTC</Tvacomprise>}
            </Pricetva>
            {taxDeductible &&
              <Pricetva>
                <Currency>€</Currency>
                <Currency>{priceVAT} -</Currency>
                <Tvacomprise>Hors TVA</Tvacomprise>
              </Pricetva>}
          </Price>
        )}
        <Vendeur>
          <Type>{sellerType}</Type>
        </Vendeur>
      </Details>
  );
};

export default VehicleCardDetails;
