import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useEmotion } from './EmotionContext';

const EpisodeMenu = ({ onEpisodeSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubMenus, setOpenSubMenus] = useState({});  // Track open submenus
  const { menuTree, updateSubjectUrl } = useEmotion();  // Consume the menuTree data from the context

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSubMenus({});  // Close all submenus
  };

  const handleSubMenuClick = (event, season) => {
    setOpenSubMenus(prevState => ({
      ...prevState,
      [season]: event.currentTarget,
    }));
  };

  const handleSubMenuClose = (season) => {
    setOpenSubMenus(prevState => ({
      ...prevState,
      [season]: null,
    }));
  };

  const handleSelect = (season, episode) => {
    const selection = `${season}/${episode}`;  // Create a string with season/episode
    onEpisodeSelect(selection);  // Pass the string to the parent
    updateSubjectUrl(selection);

    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-controls="season-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuRoundedIcon style={{ color: 'white' }} />
      </IconButton>
      <Menu
        id="season-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuTree.map((season, seasonIndex) => (
          <MenuItem
            key={seasonIndex}
            aria-controls={`submenu-${seasonIndex}`}
            aria-haspopup="true"
            onClick={(event) => handleSubMenuClick(event, season.season)}
          >
            {season.season}
            <Menu
              id={`submenu-${seasonIndex}`}
              anchorEl={openSubMenus[season.season]}
              keepMounted
              open={Boolean(openSubMenus[season.season])}
              onClose={() => handleSubMenuClose(season.season)}
            >
              {season.episodes.map((episode, episodeIndex) => (
                <MenuItem
                  key={episodeIndex}
                  onClick={() => handleSelect(season.season, episode)}  // Pass season and episode to handleSelect
                >
                  {episode}
                </MenuItem>
              ))}
            </Menu>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default EpisodeMenu;
