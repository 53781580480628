import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Import Quill's CSS

const MlToolTip = ({ text, maxWidth = '500px' }) => {
  const editorStyle = {
    maxWidth, 
    overflow: 'hidden' // Add scroll if content exceeds maxHeight
  };

  return (
    <ReactQuill 
      value={text}
      readOnly={true}
      theme="bubble" 
      modules={{ toolbar: false }}
      style={editorStyle}
    />
  );
};

export default MlToolTip;