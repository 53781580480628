import React, { useState, useEffect, useRef } from 'react';
import {
  formatPrice,
  formatPriceVat,
  formatTitle,
  printComponentAsJpg,
  getListWithReturn,
  ColorDetails,
  formatWarranty
} from './AutodinUtils';

import { getNode } from '../../MlModels/MlNode';
import { ObjectId } from 'bson';
import ContactSeller from './ContactSeller';
import MlDialog from '../base/MlDialog';
import SectionWithHeader from '../base/SectionWithHeader';
import ImageEditor from '../../ImageEditorComponent/ImageEditor';
import VehicleSummary from './VehicleSummary';
import VehiclePublicDetails from './VehiclePublicDetails';
import Poster from './VehiclePoster';

import {
  Carouselframe,
  ModleMarque,
  Garantie,
  Commentaires,
  SummaryChild,
  Summary,
  Foot,
  CardetailRoot,
  Price,
  Pricetva,
  Title,
  Tvacomprise,
} from './StyledStyles';

import {
  Stack,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material';

import TabControl from '../base/TabControl';
import ShareIcon from '@mui/icons-material/Share';
import PrintIcon from '@mui/icons-material/Print';
import SendIcon from '@mui/icons-material/Send';

import { useReactToPrint } from 'react-to-print';

const VehiclDetails = ({ nodeId, open, onClose, loggedIn }) => {
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [vehicleData, setVehiclData] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parentId, setParentId] = useState('autodin');
  const [selectedButton, setSelectedButton] = useState("Résumé");

  const posterRef = useRef();

  const logo = "https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com/IMAGES/upload/645b5e00c7b61df466430901/node_667d69d4dac9c828db94fdb1/AutoDinLogo.png?v=1719495169";

  useEffect(() => {
    console.log('useEffect triggered with nodeId:', nodeId);
    const fetchNodeData = async () => {
      if (nodeId) {
        try {
          console.log('Fetching data for nodeId:', nodeId);
          const fetchedNode = await getNode(nodeId);
          setVehiclData(fetchedNode);
          console.log('Fetched data:', fetchedNode);
        } catch (error) {
          console.error('Failed to fetch node:', error);
        }
      }
    };

    fetchNodeData();
  }, [nodeId]);

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const toggleModal = () => {
    setContactDialogOpen(!contactDialogOpen);
  };

  const handleClose = () => {
    setContactDialogOpen(false);
  };

  const generateCarUrl = (nodeId) => {
    const currentUrl = window.location.href;
    let newUrl;

    if (currentUrl.endsWith(`/car/${nodeId}`)) {
      newUrl = currentUrl; // Already has the correct format
    } else if (currentUrl.endsWith('/')) {
      newUrl = `${currentUrl}car/${nodeId}`; // Append without additional slash
    } else {
      newUrl = `${currentUrl}/car/${nodeId}`; // Append with additional slash
    }

    return newUrl;
  };

  const handleShareClick = () => {
    const newUrl = generateCarUrl(parentId);

    navigator.clipboard.writeText(newUrl)
      .then(() => {
        setDialogOpen(true);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  let availableButtons = ['Résumé'];

  // Add the 'Images' button to the available buttons
  availableButtons.push('Images');

  if (vehicleData?.options && vehicleData.options.length > 0) {
    availableButtons.push('Options');
  }

  if (vehicleData?.selectedBodyColor || vehicleData?.setSelectedInsideColor || vehicleData?.setSelectedDecorationeColor || vehicleData?.isMetallic) {
    availableButtons.push('Couleur');
  }
  if (vehicleData?.co2 || vehicleData?.classeEmission) {
    availableButtons.push('Détails');
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePrint = useReactToPrint({
    content: () => posterRef.current,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="vehicle-details-dialog-title"
        fullWidth
        maxWidth="md"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            height: fullScreen ? '100%' : '95vh',
          },
        }}
      >
        <DialogTitle
          id="vehicle-details-dialog-title"
          sx={{
            borderBottom: '1px solid #ccc',
            backgroundColor: 'rgb(250,250,250)',
          }}
        >
          {vehicleData && (
            <div>
              <ModleMarque>{formatTitle(vehicleData?.brand, vehicleData?.model)}</ModleMarque>
            </div>
          )}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}>
          <CardetailRoot isExporting={isExporting} id="CardetailRoot">
            <Carouselframe className="page-break">
              {/* Carousel content here */}
            </Carouselframe>
            <Summary>
              {vehicleData?.warranties &&
                <Garantie>{formatWarranty(vehicleData.warranties)}</Garantie>
              }
              <Commentaires>{vehicleData?.comments}</Commentaires>
              <SummaryChild />
            </Summary>
            <TabControl
              buttons={availableButtons}
              onButtonClick={handleButtonClick}
              selectedButton={selectedButton}
            />

            {selectedButton === "Résumé" && <VehicleSummary vehicleData={vehicleData} />}
            {selectedButton === "Détails" && <VehiclePublicDetails vehicleData={vehicleData} />}
            {selectedButton === "Options" && (
              <SectionWithHeader
                header="Options"
                content={getListWithReturn(vehicleData?.options, 300)}
                isHtml={true}
              />
            )}
            {selectedButton === "Couleur" && (
              <SectionWithHeader
                header="Couleur"
                content={<ColorDetails
                  bodyColor={vehicleData?.selectedBodyColor}
                  interiorColor={vehicleData?.setSelectedInsideColor}
                  decoration={vehicleData?.setSelectedDecorationeColor}
                  paintType={vehicleData?.isMetallic}
                />}
              />
            )}
            {selectedButton === "Images" && (
              <ImageEditor
                node={vehicleData}
                onChange={(key, value) => {
                  // handle image editor changes if necessary
                }}
                loggedIn={loggedIn}
              />
            )}
            <Foot>
              {vehicleData?.price && vehicleData.price > 0 && (
                <Price>
                  <Pricetva>
                    <Title>€</Title>
                    <Title>{formatPrice(vehicleData.price)} - </Title>
                    {vehicleData.taxDeductible ? <Tvacomprise>Tva comprise</Tvacomprise> : <Tvacomprise>TTC</Tvacomprise>}
                  </Pricetva>
                  {vehicleData.taxDeductible &&
                    <Pricetva>
                      <Title>€</Title>
                      <Title>{formatPriceVat(vehicleData.price)} - </Title>
                      <Tvacomprise>Hors TVA</Tvacomprise>
                    </Pricetva>
                  }
                </Price>
              )}
              <SummaryChild />
              {isLoading && <LinearProgress sx={{ width: '100%' }} />} {/* Display progress bar if isLoading is true */}
              <Box className="no-print" style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
                <Stack
                  direction="column"
                  style={{ width: '100%' }}
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<SendIcon />}
                    onClick={toggleModal}
                    sx={{ width: '100%' }}
                  >
                    Contact
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<ShareIcon />}
                    onClick={handleShareClick}
                    sx={{ width: '100%' }}
                  >
                    Partager
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<PrintIcon />}
                    sx={{ width: '100%' }}
                    onClick={handlePrint}
                  >
                    Imprimer
                  </Button>
                </Stack>
              </Box>
            </Foot>
          </CardetailRoot>

          <MlDialog
            style={{ zIndex: 3000 }}
            open={contactDialogOpen}
            handleClose={handleClose}
            vehicle={formatTitle(vehicleData?.brand, vehicleData?.model)}
            carUrl={generateCarUrl(parentId)}
            ChildComponent={ContactSeller}
          />

        </DialogContent>
        <DialogActions
          sx={{
            borderTop: '1px solid #ccc',
            backgroundColor: 'rgb(250,250,250)',
          }}
        >
          <Button onClick={onClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{"Copié"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Le lien vers la voiture a été copié. Vous pouvez le coller dans la barre d'adresse de votre navigateur. Ou le partager...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Render VehiclePoster off-screen for printing */}
      <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
        <Poster ref={posterRef} vehicleData={vehicleData} logo={logo} />
      </div>
    </>
  );
};

export default VehiclDetails;
