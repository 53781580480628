import React, { useState, useRef, useEffect } from 'react';

import {
    Box,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CopyIcon from '@mui/icons-material/FileCopy';
import PasteIcon from '@mui/icons-material/ContentPaste';
import CutIcon from '@mui/icons-material/ContentCut';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import MlAnimationButtons from '../MlAnimationButtons';
import FullScreenButton from '../FullScreenButton';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { useMlCanvas } from '../../../MlModels/MlCanvasProvider';
import { useNavigate } from "react-router-dom";

export default function MlCanvasTooleBox({ viewOnly = false, color = 'white', size = '18px', lock = false}) {

    const [canvasList, setCanvasList] = useState([]);
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [clipboardData, setClipboardData] = useState({ nodes: [], edges: [] }); // New state for clipboard data
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const {
        //rootCanvasList,
        saveToDatabase,
        createNewCanvas,
        deleteCurrentCanvas,
        pasteElements,
        cutSelectedElements,
        copySelectedElements,
        //fetchRootCanvasList,
        getRootCanvasList,
        fitView

    } = useMlCanvas();

    useEffect(() => {
        console.log("Clipboard Data Updated:", clipboardData);
    }, [clipboardData]);

    const handleDeleteCurrentCanvas = () => {
        setDialogOpen(true);
    };

    const handleSaveCurrentCanvas = () => {
        saveToDatabase();
    };

    const handleNewCanvas = async () => {
        // Check if there is a current canvas and if it needs to be saved
        await createNewCanvas();
        getRootCanvasList();
    };

    const handleCutNodes = () => {
        cutSelectedElements((selectedNodes, selectedEdges) => {
            setClipboardData({ nodes: selectedNodes, edges: selectedEdges });
        });
    };

    const handleFitView = () => {
        fitView();
    };

    const handleNavigateHome = () => {
        navigate("/");
    }; 

    const handleCopyNodes = () => {
        copySelectedElements((selectedNodes, selectedEdges) => {
            setClipboardData({ nodes: selectedNodes, edges: selectedEdges });
        });
    };

    const handlePasteNodes = () => {
        pasteElements(clipboardData.nodes, clipboardData.edges);
        // Clear the clipboard data after pasting
        setClipboardData({ nodes: [], edges: [] });
    };

    const confirmDelete = () => {
        deleteCurrentCanvas();
        setDialogOpen(false);
    };

    const handleToggle = async () => {

        setOpen((prevOpen) => !prevOpen);
        if (!open) {
            const updatedList = await getRootCanvasList();
            setCanvasList(updatedList);
            // Use the updated list directly as needed
            // Note: No need to update rootCanvasList state here if it's not required elsewhere
        }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const IconStyle = {
        fontSize: size,
        color: color
    };


    const alwaysAvailableTools = [
        !lock && { icon: <HomeRoundedIcon fontSize={size} />, name: 'home', action: handleNavigateHome },
        !lock && { icon: <FitScreenIcon fontSize={size} />, name: 'fit', action: handleFitView },
      ].filter(Boolean); // Filter out any false or null values

    const editableTools = [
        { icon: <AddIcon fontSize={size} />, name: 'add', action: handleNewCanvas },
        { icon: <DeleteIcon fontSize={size} />, name: 'delete', action: handleDeleteCurrentCanvas },
        { icon: <SaveIcon fontSize={size} />, name: 'save', action: handleSaveCurrentCanvas },
        { icon: <CopyIcon fontSize={size} />, name: 'copy', action: handleCopyNodes },
        { icon: <PasteIcon fontSize={size} />, name: 'paste', action: handlePasteNodes },
        { icon: <CutIcon fontSize={size} />, name: 'cut', action: handleCutNodes },
    ];

    const tools = viewOnly
        ? alwaysAvailableTools
        : [...alwaysAvailableTools, ...editableTools];


    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                <FullScreenButton color="rgb(255, 255, 255)" size={18} />

                {tools.map((tool) => (
                    <IconButton
                        key={tool.name}
                        onClick={tool.action}
                        style={IconStyle}
                    >
                        {tool.icon}
                    </IconButton>
                ))}

                <MlAnimationButtons
                
                    fill="rgb(255,255,255)"
                    iconSize={12}

                />



            </Box>

            <Dialog
                id="deleteCanvasDialog"  // Unique ID for the delete canvas dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle id="deleteCanvasDialogTitle">{"Are you sure you want to delete this canvas?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="deleteCanvasDialogContentText">
                        Deleting this canvas will remove it permanently.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="deleteCanvasCancelBtn" onClick={() => setDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button id="deleteCanvasConfirmBtn" onClick={confirmDelete} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


