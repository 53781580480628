import React from 'react';
import { Dialog, Box } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CarDetailsDialog = ({ open, handleClose, backgroundColor='#f5f5f5', ChildComponent, ...restProps }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ backgroundColor: '#f5f5f5', p: 1}}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2,  backgroundColor: '#f5f5f5', }}>
          <HighlightOffIcon onClick={handleClose} />
        </Box>
        <ChildComponent {...restProps} />
      </Box>
    </Dialog>
  );
};

export default CarDetailsDialog;
