import React from 'react';
import ReactPlayer from 'react-player';
import { Box, Paper } from '@mui/material';

function MlVideoPlayer({ mediaLink, size = '100%' }) {
  // Only render the ReactPlayer if mediaLink is provided
  if (!mediaLink) {
    return null;
  }

  return (
    
      <ReactPlayer
        url={mediaLink}
        loop
        //width={512}
        //height={512}
        //controls={false}
        playing={false}
        
      // Add additional props as needed, for example, to play a specific portion of the video
      />
   
  );
}

export default MlVideoPlayer;