import React, { useState } from 'react';
import { CircularProgress, Modal, IconButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { uploadFiles } from '../../../AWS/s3Utils'; // Use the new s3Utils library

function MlFileUploader({ onUpload, folderPath, userId, nodeId, accept, multiple = true, color = 'rgb(0,110,255)' }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleFileUpload = async (event) => {
        const files = event.target.files;
        if (!files.length) return;
    
        setIsLoading(true); // Start loading
    
        const links = []; // Store uploaded file URLs here
    
        try {
            // Generate file names using the folder path, userId, and nodeId
            const filesToUpload = Array.from(files).map(file => {
                const fileName = `${folderPath}${userId}/${nodeId ? nodeId + '/' : ''}${file.name}`;
                return file;
            });
    
            // Upload each file sequentially, one by one
            for (const file of filesToUpload) {
                await uploadFiles([file]); // Upload the current file
                links.push(file.name); // Add file name to links after successful upload
            }
    
            onUpload(links.join('\n')); // Pass the array of links to onUpload callback
    
        } catch (error) {
            console.error('Error during file upload:', error);
        }
    
        setIsLoading(false); // Stop loading once done
    };
    

    return (
        <>
            <Modal open={isLoading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Modal>
            <label>
                <IconButton color="inherit" component="span">
                    <FileUploadIcon sx={{ color: color }} />
                </IconButton>
                {/* Note the addition of `multiple` here */}
                <input
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                    accept={accept}
                    {...(multiple ? { multiple: true } : {})} />
            </label>
        </>
    );
}

export default MlFileUploader;
