import React, { useState, useEffect } from "react";
//import { BCLinkIcon } from './BCIcons'
import { authors } from './BelgicomicsDB';
import {
    Stack,
    IconButton,
    Box
} from '@mui/material';

import {
    MlPageContent,
    MlHorizontalContent,
    MlImageBox
} from '../Mainlist/mainlistcomponents';

import { useMlCanvas } from '../../MlModels/MlCanvasProvider';

const DoubleImage = (bgColor = '#FFFFFF') => {
    const {
        quanyxStorage,
        goTarget
    } = useMlCanvas();
    const [imageA, setImageA] = useState(authors[0]);
    const [imageB, setImageB] = useState(authors[1]);

    useEffect(() => {
        randomize(); // Initial randomization

        const interval = setInterval(randomize, 6000); // Randomize every 3 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []);

    const randomize = () => {
        const indexA = Math.floor(Math.random() * authors.length);
        let indexB = Math.floor(Math.random() * authors.length);

        while (indexB === indexA) {
            indexB = Math.floor(Math.random() * authors.length);
        }
        setImageA(authors[indexA]);
        setImageB(authors[indexB]);
    };

    const imageAUrl = quanyxStorage + imageA.src;
    const imageBUrl = quanyxStorage + imageB.src;

    const iconFill = 'rgba(255,255,255,0.8)';
    const iconSize = 48;
    const boxShadow = '0px 4px 20px rgba(0, 0, 0, 0.1)';

    const goCanvas = (uuid) => {
        const domain = window.location.origin; // Dynamically get the domain
        const publicLink = `${domain}/public-canvas/${uuid}`; // Construct the URL with the UUID only
        window.location.href = publicLink;
    };

    return (
        <MlPageContent bgColor='rgba(255,0,0,0)'>
            <MlHorizontalContent borderRadius='0px' maxWidth='100%' bgColor='rgba(255,0,0,0)'>
                <Stack direction='row' spacing={{ xs: 2, sm: 2, md: 3 }} alignItems="center">
                    <Stack spacing={0.5}>
                        <Box
                            onClick={() => goCanvas(imageA.link)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <MlImageBox imgSrc={imageAUrl} boxShadow={boxShadow} />
                        </Box>
                    </Stack>
                    <Stack spacing={0.5}>
                        <Box
                            onClick={() => goCanvas(imageB.link)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <MlImageBox imgSrc={imageBUrl} boxShadow={boxShadow} />
                        </Box>
                    </Stack>
                </Stack>
            </MlHorizontalContent>
        </MlPageContent>
    );
};

export default DoubleImage;
