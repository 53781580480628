import React from 'react';

export const EditIcon = ({ size = 24, color = 'black' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 186.3 186.3"
    width={size}
    height={size}
    fill={color}
  >
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <path d="M182.14,40.37l-11.37,11.37-36.21-36.21,11.37-11.37c5.55-5.55,14.54-5.55,20.08,0l16.13,16.13c5.55,5.55,5.55,14.54,0,20.08Z"/>
        <path d="M165.4,57.11l-116.8,116.8c-.95.95-2.2,1.52-3.53,1.65l-38.82,10.71c-3.55.32-6.53-2.66-6.21-6.21l10.71-38.82c.13-1.34.7-2.58,1.65-3.53L129.19,20.9l36.21,36.21Z"/>
      </g>
    </g>
  </svg>
);
