import React from 'react';
import { Grid, Box } from '@mui/material';
import ColorControlLabel from './ColorControlLabel';

function ResponsiveColorPicker({ radio = true, colors, selectedColor, onColorChange }) {
    const handleColorChangeInternal = (color) => {
        if (radio) {
            onColorChange(color);
        } else {
            const newSelectedColors = selectedColor.includes(color)
                ? selectedColor.filter(selected => selected !== color)
                : [...selectedColor, color];
            onColorChange(newSelectedColors);
        }
    };

    return (
        <Box m={3}>
            <Grid container spacing={2}>
                {Object.entries(colors).map(([colorName, colorCode]) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={colorName}>
                        <ColorControlLabel 
                            color={colorCode} 
                            selected={
                                radio
                                    ? selectedColor === colorName
                                    : selectedColor.includes(colorName)
                            } 
                            onColorChange={() => handleColorChangeInternal(colorName)} 
                            label={colorName}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ResponsiveColorPicker;
