export const Note = ({ fill = "#000", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21V18.875L17.3 13.575L19.425 15.7L14.125 21H12Z" fill={fill} />
      <path d="M3 16V14H10V16H3Z" fill={fill} />
      <path d="M20.125 15L18 12.875L18.725 12.15C18.9083 11.9667 19.1417 11.875 19.425 11.875C19.7083 11.875 19.9417 11.9667 20.125 12.15L20.85 12.875C21.0333 13.0583 21.125 13.2917 21.125 13.575C21.125 13.8583 21.0333 14.0917 20.85 14.275L20.125 15Z" fill={fill} />
      <path d="M3 12V10H14V12H3Z" fill={fill} />
      <path d="M3 8V6H14V8H3Z" fill={fill} />
    </svg>
  );

  export const Link = ({ fill = "#000", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M11 17H7C5.61667 17 4.4375 16.5125 3.4625 15.5375C2.4875 14.5625 2 13.3833 2 12C2 10.6167 2.4875 9.4375 3.4625 8.4625C4.4375 7.4875 5.61667 7 7 7H11V9H7C6.16667 9 5.45833 9.29167 4.875 9.875C4.29167 10.4583 4 11.1667 4 12C4 12.8333 4.29167 13.5417 4.875 14.125C5.45833 14.7083 6.16667 15 7 15H11V17Z" fill={fill} />
      <path d="M8 13V11H16V13H8Z" fill={fill} />
      <path d="M13 17V15H17C17.8333 15 18.5417 14.7083 19.125 14.125C19.7083 13.5417 20 12.8333 20 12C20 11.1667 19.7083 10.4583 19.125 9.875C18.5417 9.29167 17.8333 9 17 9H13V7H17C18.3833 7 19.5625 7.4875 20.5375 8.4625C21.5125 9.4375 22 10.6167 22 12C22 13.3833 21.5125 14.5625 20.5375 15.5375C19.5625 16.5125 18.3833 17 17 17H13Z" fill={fill} />
    </svg>
  );

  export const Folder = ({ fill = "#000", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4Z" fill={fill} />
    </svg>
  );

  export const Lens = ({ fill = "#000", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_84_431" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill={fill} />
      </mask>
      <g mask="url(#mask0_84_431)">
        <path d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.14583 15.3708 4.8875 14.1125C3.62917 12.8542 3 11.3167 3 9.5C3 7.68333 3.62917 6.14583 4.8875 4.8875C6.14583 3.62917 7.68333 3 9.5 3C11.3167 3 12.8542 3.62917 14.1125 4.8875C15.3708 6.14583 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8125 13.5625 12.6875 12.6875C13.5625 11.8125 14 10.75 14 9.5C14 8.25 13.5625 7.1875 12.6875 6.3125C11.8125 5.4375 10.75 5 9.5 5C8.25 5 7.1875 5.4375 6.3125 6.3125C5.4375 7.1875 5 8.25 5 9.5C5 10.75 5.4375 11.8125 6.3125 12.6875C7.1875 13.5625 8.25 14 9.5 14Z" fill={fill} />
      </g>
    </svg>
  );

  export const Set = ({ fill = "#000", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.03008" cy="8.03008" r="7.03008" transform="matrix(1 0 0 -1 0 16.5602)" stroke={fill} strokeWidth="2" />
      <circle cx="8.03008" cy="8.03008" r="7.03008" transform="matrix(1 0 0 -1 7.93982 16.5602)" stroke={fill} strokeWidth="2" />
    </svg>
  );
  


  

  