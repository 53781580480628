import { ObjectId } from 'bson';

class Node {
  constructor(data = {}) {
    const currentDate = new Date();

    this._id = data._id || new ObjectId();
    this.parentId = data.parentId;
    this.userId = data.userId; // owner of the node
    this.sharedWith = data.sharedWith; // array of user ids with whom this node is shared
    this.title = data.title || `Note-${currentDate.getSeconds()}`;
    this.brief = data.brief || '';
    this.nodeIndex = data.nodeIndex;
    this.dueDate = data.dueDate;
    this.nodeState = data.nodeState || 0;
    this.tags = data.tags;
    this.links = data.links;
    if (data.publicId) {
      this.publicId = data.publicId;
    }

    // Check if data.nodeObjects exists and is an object
    if (typeof data.nodeObjects === 'object' && data.nodeObjects !== null) {
      // If it does, assign it to this.nodeObjects
      this.nodeObjects = data.nodeObjects;
    } else {
      // If it does not, initialize this.nodeObjects as an empty object
      this.nodeObjects = {};
    }

    this.resources = data.resources;
    this.budget = data.budget;
    this.difficulty = data.difficulty || 1;
    this.projectValue = data.projectValue || 1;
    this.dependencies = data.dependencies;

    // Define createdAt as a read-only property
    Object.defineProperty(this, 'createdAt', {
      value: data.createdAt || currentDate,
      writable: false
    });

    this.updatedAt = data.updatedAt || currentDate;
  }
  
  toPlainObject() {
    // Return a new object that contains the properties of the instance
    return { ...this };
  }

  update(data) {
    for (let key in data) {
      // If the key is nodeObjects and it's an object, merge it with this.nodeObjects
      if (key === 'nodeObjects' && typeof data[key] === 'object' && data[key] !== null) {
        this.nodeObjects = { ...this.nodeObjects, ...data[key] };
      } else if (this.hasOwnProperty(key) && key !== 'createdAt') {
        this[key] = data[key];
      }
    }
    this.updatedAt = new Date();
  }

  truncateString(str, n) {
    if (str && str.length > n) {
      return `${str.substring(0, n - 3)}...`;
    } else {
      return str;
    }
  }

  getTruncatedTitle(n) {
    return this.truncateString(this.title, n);
  }

  getTruncatedBrief(n) {
    return this.truncateString(this.brief, n);
  }

  addDataByType(data, type) {
    this.nodeObjects[type] = data;
  }
}

export default Node;
