import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

function MlSearchEditor({
  searchTerm,
  handleSearchChange,
  bg,
  type,
  handleTypeChange,
  identity,
  handleIdentityChange,
  placeholder = "Search",
  size = "small",
  sx = "",
  showIdentityFields = false
}) {
  return (
    <>
      {showIdentityFields && (
        <>
          <TextField
            variant="outlined"
            label="Type"
            value={type}
            onChange={handleTypeChange}
            size={size}
            sx={{ ...sx, mt: 2 }} // Ajout d'un peu de marge en haut pour l'espacement
          />
          <TextField
            variant="outlined"
            label="Identity"
            value={identity}
            onChange={handleIdentityChange}
            size={size}
            sx={{ ...sx, mt: 2 }} // Ajout d'un peu de marge en haut pour l'espacement
          />
        </>
      )}
      <TextField
        variant="outlined"
        placeholder={placeholder}
        size={size}
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          backgroundColor: bg, // Change this to your desired color
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: bg, // Change border color if needed
            },
            '&:hover fieldset': {
              borderColor: bg, // Change border color on hover if needed
            },
            '&.Mui-focused fieldset': {
              borderColor: bg, // Change border color on focus if needed
            },
          },
        }}
      />
    </>
  );
}

export default MlSearchEditor;
