export const setVhProperty = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  
  // Optionally, set it initially here or you can do this in index.js
  setVhProperty();
  
  // Reset on resize
  window.addEventListener('resize', setVhProperty);
  