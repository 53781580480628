import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home'; // Example of another icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MailIcon from '@mui/icons-material/Mail';
import {BCLinkIcon} from '../../Belgicomics/BCIcons';

const MlNodeIcon = ({ size, onClick, icon, color, alpha }) => {
    // Default values for props
    const defaultSize = size || 24;
    const defaultColor = color || 'black';
    const iconStyle = {
        fontSize: `${defaultSize}px`,
        color: defaultColor
    };

    // Function to select the icon based on the 'icon' prop
    const selectIcon = (iconKey = 'nextIcon') => {
        const icons = {
            http: <LinkIcon style={iconStyle} />,
            https: <LinkIcon style={iconStyle} />,
            link: <LinkIcon style={iconStyle} />,
            home: <HomeIcon style={iconStyle} />,
            bcIcon: <BCLinkIcon size={size} fill={defaultColor}/>, // Add more icons to this object
            nextIcon: <ArrowForwardIosIcon style={iconStyle}/>,
            tel: <CallIcon style={iconStyle}/>,
            mailto: <MailIcon style={iconStyle}/>
            // ... add other icon cases here
        };
        return icons[iconKey] || icons['link']; // Default to 'link' icon
    };

    return (
        <IconButton
        onClick={onClick}
        sx={{ padding: 0, margin: 0, '& .MuiSvgIcon-root': { margin: 0 } }}
        
        >
            {selectIcon(icon)}
        </IconButton>
    );
};

MlNodeIcon.propTypes = {
    size: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    color: PropTypes.string,
    alpha: PropTypes.string
};

export default MlNodeIcon;
