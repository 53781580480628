// QwanyxAppStyles.js
import styled, { createGlobalStyle } from 'styled-components';

// Global Style
const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat', Arial, sans-serif;
  }
`;

export default GlobalStyle;