import React from 'react';
import { Stack } from '@mui/material';
import MlAnimationButtons from './MlAnimationButtons';





function MlAnimationPanel(props) {

  const {
    showNavigation = false,
    showPlayStop = true
} = props;




  return (
    // Set the Stack to be a flex container with horizontal alignment
    <Stack 
      direction="row" 
      justifyContent="center" // Centers children horizontally in a row
      alignItems="center" // Centers children vertically
      sx={{ width: '100%', height: '100%' }} // Ensures Stack takes full width and height
    >
      <MlAnimationButtons showNavigation = {showNavigation} showPlayStop = {showPlayStop}/>
    </Stack>
  );
}

export default MlAnimationPanel;