import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useEmotion } from './EmotionContext';

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
`;

const VideoPlayer = ({ source = '' }) => {
  const videoRef = useRef(null);
  const { selectedTime, setSelectedTime, isPlaying, isScrubbing, subjectUrl } = useEmotion();

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      //console.log(`Video duration is: ${duration}`);
    }
  };

  const updatePlayhead = () => {
    if (videoRef.current && !isScrubbing) {
      const normalizedTime = videoRef.current.currentTime / videoRef.current.duration;
      setSelectedTime(normalizedTime);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', updatePlayhead);
    }

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', updatePlayhead);
      }
    };
  }, [setSelectedTime]);

  useEffect(() => {
    if (videoRef.current) {
      if (isScrubbing || !isPlaying) {
        videoRef.current.pause(); // Ensure video is paused when isPlaying is false or during scrubbing
        const newTime = selectedTime * videoRef.current.duration;
        if (!isNaN(newTime)) {
          videoRef.current.currentTime = newTime;
        }
      }
      if (isPlaying && !isScrubbing) {
        videoRef.current.play();
      }
    }
  }, [selectedTime, isPlaying, isScrubbing]);

  // Set video source based on the 'source' prop
  let videoSrc = '';
  if (source === 'subject') {
    videoSrc = subjectUrl;
  } else if (source === 'episode') {
    videoSrc = ''; // We can later update this with episode logic
  }

  return (
    <VideoContainer>
      <VideoElement
        ref={videoRef}
        src={videoSrc}
        onLoadedMetadata={handleLoadedMetadata}
        controls={false} // No default controls
      />
    </VideoContainer>
  );
};

export default VideoPlayer;
