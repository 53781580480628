//audioUtils.js
import { Howl } from 'howler';

class AudioManager {
  constructor() {
    this.currentSound = null;
  }

  playSound(url) {
    if (this.currentSound) {
      this.currentSound.stop();
    }
    this.currentSound = new Howl({
      src: [url],
      html5: true,
      onend: () => {
        console.log('Finished playing');
      }
    });
    this.currentSound.play();
  }

  pauseSound() {
    if (this.currentSound && this.currentSound.playing()) {
      this.currentSound.pause();
    }
  }

  stopSound() {
    if (this.currentSound && this.currentSound.playing()) {
      this.currentSound.stop();
      this.currentSound = null;
    }
  }
}

const audioManager = new AudioManager();
export default audioManager;
