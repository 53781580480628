// VideoEmotionAnalysis.js
import React, { useState, useEffect } from 'react'
import AWS from 'aws-sdk'
import EmotionColors from './EmotionColors'
import D3Chart from './D3Chart'
import { Typography, Button } from '@mui/material'
import { emotionColors, exportEmotionsToFile, importEmotionsFromFile } from './EmotionLib'
import EmotionBoard from './EmotionBoard'



const rekognition = new AWS.Rekognition()

const VideoEmotionAnalysis = () => {
  const [jobId, setJobId] = useState(null);
  const [status, setStatus] = useState('Initializing...')
  const [emotions, setEmotions] = useState([])

  useEffect(() => {
    if (jobId) {
      const interval = setInterval(() => {
        checkJobStatus(jobId);
      }, 5000);
      return () => clearInterval(interval)
    }
  }, [jobId]);

  const startFaceDetection = () => {
    const params = {
      Video: {
        S3Object: {
          Bucket: 'qwanyx-storage-images',
          Name: 'SMURF/S01EP03.mp4',
        },
      },
      NotificationChannel: {
        RoleArn: 'arn:aws:iam::156006224732:role/qwanyx-rekognition',
        SNSTopicArn: 'arn:aws:sns:eu-central-1:156006224732:emotions',
      },
      FaceAttributes: 'ALL',
    };

    rekognition.startFaceDetection(params, function (err, data) {
      if (err) {
        console.error("Error starting face detection:", err);
        setStatus('Error starting face detection');
      } else {
        console.log("Face detection started, Job ID:", data.JobId);
        setJobId(data.JobId);
        setStatus('Face detection started');
      }
    });
  };

  const checkJobStatus = (jobId) => {
    const params = {
      JobId: jobId,
    };

    rekognition.getFaceDetection(params, function (err, data) {
      if (err) {
        console.error("Error checking job status:", err);
        setStatus('Error checking job status');
      } else {
        if (data.JobStatus === 'SUCCEEDED') {
          console.log("Face detection completed");
          setStatus('Face detection completed');
          setEmotions(data.Faces.map(face => ({
            timestamp: face.Timestamp,
            emotions: face.Face.Emotions
          })));
        } else {
          console.log("Job status:", data.JobStatus);
          setStatus(`Job status: ${data.JobStatus}`);
        }
      }
    });
  };

  const handleExport = () => {
    exportEmotionsToFile(emotions);
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    importEmotionsFromFile(file).then(
      (importedEmotions) => {
        setEmotions(importedEmotions);
      },
      (error) => {
        console.error("Error importing emotions:", error);
      }
    );
  };

  return (
    <div>
      
      <Typography variant="h4">Video Emotion Analysis</Typography>
      <Button variant="contained" color="primary" onClick={startFaceDetection}>
        Start Face Detection
      </Button>
      <Typography>Status: {status}</Typography>
      {emotions.length > 0 && (
        <div>
          <Typography variant="h5">Detected Emotions</Typography>
          <D3Chart data={emotions} />
          <Button variant="contained" color="secondary" onClick={handleExport}>
            Export Emotions
          </Button>
        </div>
      )}
      <input
        type="file"
        accept=".emo"
        onChange={handleImport}
        style={{ display: 'none' }}
        id="import-emotions-file"
      />
      <label htmlFor="import-emotions-file">
        <Button variant="contained" component="span">
          Import Emotions
        </Button>
      </label>
      <EmotionColors />
     
    </div>
  );

};

export default VideoEmotionAnalysis;
