// MLTTS.js
import React, { useState } from 'react';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { Alert, IconButton, Menu, MenuItem, Switch, FormControlLabel, Tooltip } from '@mui/material';

export default function MlTTS({ onTTSComplete, onTTSDelete, format = 'tts-1', voice = 'alloy' }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(voice);
  const [isHD, setIsHD] = useState(format === 'tts-1-hd');
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (voice) => {
    setSelectedVoice(voice);
    handleClose();
    onTTSComplete?.(voice, isHD ? 'tts-1-hd' : 'tts-1');
  };

  const handleHDToggle = (event) => {
    setIsHD(event.target.checked);
    // Optionally, call a method if you need to do something when HD is toggled
  };

  const handleDeleteSpeech = () => {
    // Implement logic to delete speech
    onTTSDelete?.();
    handleClose();
  };

  return (
    <>
      <Tooltip title="Text to speech" arrow>
        <IconButton onClick={handleClick}>
          <RecordVoiceOverIcon style={{ color: 'black' }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'].map((voice) => (
          <MenuItem
            key={voice}
            selected={voice === selectedVoice}
            onClick={() => handleMenuItemClick(voice)}
          >
            {voice.charAt(0).toUpperCase() + voice.slice(1)}
          </MenuItem>
        ))}
        <MenuItem>
          <FormControlLabel
            control={<Switch checked={isHD} onChange={handleHDToggle} />}
            label="HD Quality"
          />
        </MenuItem>
        <MenuItem onClick={handleDeleteSpeech}>Delete Speech</MenuItem>
      </Menu>
    </>
  );
}

