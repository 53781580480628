/**
 * Selects properties from an object and returns a new object
 * excluding those properties. Useful for preparing data for embeddings.
 * 
 * @param {Object} sourceObject - The original object from which properties are selected.
 * @param {Array<string>} propsToExclude - An array of property names to exclude from the new object.
 * @returns {Object} A new object containing all properties except the specified properties.
 */

// this export a text without property names
/*
export function objectToTextForEmbedding(
  sourceObject,
  propsToExclude = ["_id", "createdAt", "userId", "sharedWith", "imageLink", "embedding", "createdAt", "updatedAt"]
) {
  // Filter out the excluded properties and collect the values
  const filteredValues = Object.keys(sourceObject)
    .filter((prop) => !propsToExclude.includes(prop)) // Exclude specified properties
    .map((prop) => sourceObject[prop]) // Collect the values
    .join(' '); // Combine the values into a single string separated by spaces

  // Convert to plain text (values already joined) and replace any newlines with spaces
  const plainText = filteredValues.replace(/\n/g, ' ');

  return plainText;
}
*/

export function carEmbeddingText(
  sourceObject,
  separator = ': ' // You can change this to '=' if you prefer
) {
  // Predefined objects for replacing numeric values with strings
  const suppliyers = {
    1: "Aziz",
    2: "Philippe",
    3: "Chris"
  };

  const motorisation = {
    1: "Essence",
    3: "Diesel",
    4: "Hybride essence",
    5: "Hybride diesel",
    6: "Electrique",
    7: "Hydrogène",
    2: "LPG"
  };

  const vehicleTypes = {
    1: "Cabriolet",
    2: "Coupé",
    3: "Break",
    4: "Berline",
    5: "SUV",
    6: "Mobil Home",
    7: "Monospace",
    8: "Moto",
    9: "Commercial",
    10: "Pickup",
    11: "Citadine"
  };

  const descriptions = {
    price: 'prix du véhicule',
    tarif: 'catégorie de vendeur',
    type: 'type de véhicule',
    brand: 'marque',
    model: 'modèle',
    motorisation: 'motorisation ou type de carburant',
    capacity: 'la cylindrée est de',
    power: 'la puissance est de',
    kms: 'le nombre de kilomètres est de',
    bodyColor: 'couleur de la carrosserie',
    classeEmission: 'la classe démission co2 est',
    co2: 'le co2 est',
    comments: 'commentaires à propos de ce véhicule',
    interiorColor: 'couleur intérieur',
    Trim: 'type de décoration',
    options: 'Liste des options',
    painttype: 'type de peinture',
    registration: 'première mise en circulation',
    sliderKeys: 'nombre de clés simples',
    sliderKeysTele: 'clés avec télécommande',
    sliderNoContact: 'clés sans contact',
    sliderTeleSeule: 'Télécommande seule',
    warranties: 'Type de garantie'
  };

  const transformedText = Object.keys(sourceObject)
    .filter((prop) => descriptions.hasOwnProperty(prop)) // Only include props that exist in descriptions
    .map((prop) => {
      let value = sourceObject[prop];

      // Skip if the value is null, undefined, or an empty string
      if (value == null || value === '') {
        return null;
      }

      // Replace numeric values with corresponding strings for certain properties
      if (prop === 'suppliers' && suppliyers[value]) {
        value = suppliyers[value];
      } else if (prop === 'motorisation' && motorisation[value]) {
        value = motorisation[value];
      } else if (prop === 'type' && vehicleTypes[value]) {
        value = vehicleTypes[value];
      }

      // Skip if the value is still numeric and wasn't matched to a string
      if ((prop === 'suppliers' || prop === 'motorisation' || prop === 'type') && typeof value === 'number') {
        return null;
      }

      // Construct the final text with description and value only
      return `${descriptions[prop]} ${value}.`;
    })
    .filter(Boolean) // Remove any null values from the array
    .join(' '); // Combine the description-value pairs into a single string separated by spaces

  // Convert to plain text (description-value pairs already joined) and replace any newlines with spaces
  const plainText = transformedText.replace(/\n/g, ' ');

  return plainText;
}







/* this version exports a json text and it does not work very well it seems
export function objectToTextForEmbedding(
  sourceObject,
  propsToExclude = ["_id", "createdAt", "userId", "sharedWith"]
) {
  const filteredObject = Object.keys(sourceObject).reduce((result, prop) => {
    if (!propsToExclude.includes(prop)) {
      result[prop] = sourceObject[prop];
    }
    return result;
  }, {});

  // Convert the object to a JSON string
  let jsonString = JSON.stringify(filteredObject);

  // Replace "\n" with a space
  jsonString = jsonString.replace(/\n/g, " ");

  return jsonString;
}
*/

/*
// Example Usage:
const node = {
  _id: new ObjectId(),
  parentId: null,
  userId: "someUserId",
  sharedWith: null,
  title: "Node-59",
  brief: "This is a brief description",
  nodeIndex: 5,
  tags: ["tag1", "tag2"],
  links: ["link1", "link2"],
  createdAt: new Date(),
  updatedAt: new Date()
};
 
// Properties you want to include for embeddings
const propsToExport = ["title", "brief", "tags", "links"];
 
// Get the object with only the selected properties
const jsonData = objectToJson(node, propsToExport);
 
console.log(embeddingData);
*/