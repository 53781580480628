import React, { useState } from 'react';
import { List, Stack } from '@mui/material';
import { authors } from './BelgicomicsDB';
import MlSearchEditor from '../Mainlist/MlSearchEditor';
import AuthorCard from './AuthorCard';

const AuthorList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const bgColor = 'rgb(250,250,250)';

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredAuthors = authors.filter(author =>
        author.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const borderStyles = {
        border: '1px solid rgb(200,200,200)', // You can customize the border thickness and color
        //borderRadius: '4px', // Optional: add border-radius for rounded corners
    };

    return (
        <Stack direction="column" width="100%" maxHeight="100%" overflow="auto" padding={0} >
            <Stack width="100%" sx={borderStyles}>
                <MlSearchEditor
                    bg={bgColor}
                    searchTerm={searchTerm}
                    handleSearchChange={handleSearchChange}
                    
                />
            </Stack>
            <Stack
                mt={1}
                direction="column"
                width="100%"
                maxHeight="100%"
                overflow="auto"
                sx={borderStyles}
            >
                <List sx={{ width: '100%', bgcolor: bgColor }}>
                    {filteredAuthors.map((author) => (
                        <AuthorCard key={author.link} author={author} />
                    ))}
                </List>
            </Stack>
        </Stack>
    );
};

export default AuthorList;
