import { createSlice } from '@reduxjs/toolkit';
import nodeMapper from '../models/NodeMapper'; // import your NodeMapper
import Node from '../models/Node';


const initialState = {
  boards: [
    { id: 'Board 1', parentNodes: [] }
  ],
  selectedBoard: 'Board 1',
};

export const mainListBoardSlice = createSlice({
  name: 'mainListBoard',
  initialState,
  reducers: {

    setEditingBoardId: (state, action) => {
      state.editingBoardId = action.payload;
    },
    setEditingBoardName: (state, action) => {
      state.editingBoardName = action.payload;
    },
    initBoard: (state, action) => {
      return action.payload;
    },
    addBoard: (state) => {
      const highestNumberedBoard = state.boards.reduce((highest, board) => {
        const currentNumber = parseInt(board.id.split(' ')[1], 10);
        return currentNumber > highest ? currentNumber : highest;
      }, 0);
      const newBoardId = `Board ${highestNumberedBoard + 1}`;
      state.boards.push({ id: newBoardId, parentNodes: [] });
      state.selectedBoard = newBoardId;
      nodeMapper.updateNodeObjects(".defaultMainListBoard", {defaultMainListBoard: state});
    },
    deleteBoard: (state) => {
      state.boards = state.boards.filter(board => board.id !== state.selectedBoard);
      state.selectedBoard = state.boards[0] ? state.boards[0].id : '';
      nodeMapper.updateNodeObjects(".defaultMainListBoard", {defaultMainListBoard: state});
    },
    selectBoard: (state, action) => {
      state.selectedBoard = action.payload;
      nodeMapper.updateNodeObjects(".defaultMainListBoard", {defaultMainListBoard: state});
    },
    addParentNode: (state, action) => {
      const selectedBoard = state.boards.find(board => board.id === state.selectedBoard);
      
      // check if action.payload is an instance of Node
      if (action.payload instanceof Node) {
        // If it is, convert it to a plain object using toPlainObject()
        action.payload = action.payload.toPlainObject();
      }
    
      // Now, it's safe to add action.payload to the array
      selectedBoard.parentNodes.push(action.payload);
    },

    removeParentNode: (state, action) => {
      const selectedBoard = state.boards.find(board => board.id === state.selectedBoard);
      selectedBoard.parentNodes = selectedBoard.parentNodes.filter(node => node._id !== action.payload._id);
    },

    updateBoardName: (state, action) => {
    const { oldName, newName } = action.payload;
    const boardToUpdate = state.boards.find(board => board.id === oldName);
    if (boardToUpdate) {
      boardToUpdate.id = newName;
      if (state.selectedBoard === oldName) {
        state.selectedBoard = newName;
      }
      nodeMapper.updateNodeObjects(".defaultMainListBoard", { defaultMainListBoard: state });
    }
  },
  }
});

export const { initBoard, addBoard, deleteBoard, selectBoard, addParentNode, removeParentNode, updateBoardName } = mainListBoardSlice.actions;

export const selectEditingBoardId = state => state.mainListBoard.editingBoardId;
export const selectEditingBoardName = state => state.mainListBoard.editingBoardName;

export const selectAllParentNodes = state => {
  const selectedBoard = state.mainListBoard.boards.find(board => board.id === state.mainListBoard.selectedBoard);
  return selectedBoard 
    ? selectedBoard.parentNodes.map(node => new Node(node)) 
    : [];
};
export const selectAllBoards = state => state.mainListBoard.boards.map(board => board.id);
export const selectSelectedBoard = state => state.mainListBoard.selectedBoard;

export default mainListBoardSlice.reducer;