import React from 'react';
import TreeColumn from '../base/TreeColumn';

const data = [
  {
    title: 'Level 1 - Item 1',
    children: [
      {
        title: 'Level 2 - Item 1',
        children: [
          {
            title: 'Level 3 - Item 1',
          },
          {
            title: 'Level 3 - Item 2',
          },
        ],
      },
      {
        title: 'Level 2 - Item 2',
      },
    ],
  },
  {
    title: 'Level 1 - Item 2',
    children: [
      {
        title: 'Level 2 - Item 3',
      },
    ],
  },
];

const NodeTree = () => {
  return (
    <div>
      <TreeColumn data={data} />
    </div>
  );
};

export default NodeTree;
