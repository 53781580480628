// VehicleUtils.js
import { useEffect, useState } from 'react';
import {
    createNode,
    nodeGet,
    fetchNodeByTitle,
    updateNode,
    nodePut
} from '../../MlModels/MlNode';

const userId = 'belcar';
const nodeTitle = 'carbrands';

export const getTime = () => {
    return new Date().toLocaleTimeString();
};

export const getVehicle = async (id) => {
    const vehicle = await nodeGet(id);
    return vehicle;
};

export const putVehicle = async (vehicle) => {
    const result = await nodePut(vehicle);
    return result[0];
};

export const fetchBrands = async (id) => {
    const brandData = await fetchNodeByTitle(nodeTitle, userId);
    return brandData;
};

export const fetchVehicles = async (id) => {
    const identity = 'autodin'
    const brandData = await fetchNodeByTitle(nodeTitle, userId);
    return brandData;
};

export const saveBrandData = async (updatedData) => {
    try {
        const existingNode = await fetchNodeByTitle(nodeTitle, userId);

        const nodeData = {
            _id: existingNode?._id,  // Add _id only if existingNode exists
            userId: userId,
            title: nodeTitle,
            nodeObjects: {
                belcarbrands: updatedData
            }
        };

        if (existingNode) {
            await updateNode(nodeData);
        } else {
            await createNode(nodeData, userId);
        }
    } catch (error) {
        console.error("Error saving data to node:", error);
        // Optionally, you can revert the UI to the previous state here or notify the user of the error.
    }
};

export const newVehicle = async () => {
    const vehicle = createNode();
    vehicle.title = 'Nouveau véhicule';
    vehicle.identity = 'autodin';
    return vehicle;
};

export const useVehicle = (vehicleId) => {
    const [vehicle, setVehicle] = useState(null);

    useEffect(() => {
        if (vehicleId) {
            getVehicle(vehicleId).then(setVehicle);
        } else {
            newVehicle().then(setVehicle);
        }
    }, [vehicleId]);

    return [vehicle, setVehicle];
};

export const handleChange = (field, value, setVehicle) => {
    setVehicle(prevVehicle => ({
        ...prevVehicle,
        [field]: value,
    }));
};
