import React, { useState, useEffect } from 'react';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider'; // Replace with the actual path
import { Stack, Box, MenuItem, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function MlNodesList({type, identity, setMlNode}) {
    const [canvasList, setCanvasList] = useState([]);
    const [filteredCanvasList, setFilteredCanvasList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { getRootCanvasList, loadCanvas, saveToDatabase, getNodeList} = useMlCanvas();

    useEffect(() => {
        const fetchNodesList = async () => {
            const updatedList = await getNodeList(type, identity, setMlNode);
            setCanvasList(updatedList);
        };
        fetchNodesList();
    }, [getRootCanvasList]);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = canvasList.filter(item =>
            item.title.toLowerCase().includes(lowercasedFilter)
        );
        setFilteredCanvasList(filteredData);
    }, [searchTerm, canvasList]);

    const handleNodeSelect = (nodeId) => {
        setMlNode(nodeId);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <Stack direction='column' sx={{ width: '100%', height: '100%' }}>
            <TextField
                variant="outlined"
                placeholder="Search"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={{ m: '5px', mb: '10px'}}
            />
            <Box flexGrow={1} overflow="auto">
                {filteredCanvasList.map((canvas, index) => (
                    <MenuItem key={index} onClick={() => handleNodeSelect(canvas._id)}>
                        {canvas.title}
                    </MenuItem>
                ))}
            </Box>
        </Stack>
    );
}
