import React, { useState, useEffect } from 'react';
import {
    Box,
    Stack,
    TextField,
    CircularProgress,
    Modal
} from '@mui/material';

import {
    uploadFileToServer,
    getUserId
} from '../../../MlModels/MlNode';

import MlRecorder from '../sound/MlRecorder';
import MlFileUploader from './MlFileUploader'; // Adjust the import path as necessary

const USER_ID = getUserId();

function getVersionString() {
    const seconds = Math.floor(new Date().getTime() / 1000);
    return `v=${seconds}`;
}

const MlNodeSoundEditor = ({ onSave, initialSoundValues = {} }) => {
    const [isLoading, setIsLoading] = useState(false);
    const getDefaultValues = (values) => ({
        soundLink: values.soundLink || '',
    });
    const [nodeSoundSettings, setNodeSoundSettings] = useState(getDefaultValues(initialSoundValues));

    const handleChange = (field, value) => {
        setNodeSoundSettings(prevSettings => ({
            ...prevSettings,
            [field]: value
        }));
    };

    useEffect(() => {
        onSave(nodeSoundSettings);
    }, [nodeSoundSettings, onSave]);

    const handleFileUploadComplete = (fileName) => {
        const cacheBustingLink = "/" + fileName + "?" + getVersionString();
        handleChange('soundLink', cacheBustingLink); // Update state with new sound link
        nodeSoundSettings.soundLink = cacheBustingLink;
    };

    const handleAudioSave = async (audioBlob) => {
        setIsLoading(true); // Show loading modal

        const soundFolder = `RECORD/voice/${USER_ID}/${initialSoundValues._id.toString()}/`;
        const fileName = `${soundFolder}${initialSoundValues._id.toString()}.wav`;

        // Convert audioBlob to base64String
        const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });

        try {
            if (audioBlob) {
                const base64String = await convertBlobToBase64(audioBlob);
                const base64Data = base64String.split(',')[1];
                const cacheBustingLink = fileName + "?" + getVersionString();
                nodeSoundSettings.soundLink = "/" + cacheBustingLink;
                const uploadResult = await uploadFileToServer(base64Data, fileName);
                setIsLoading(false);
            } else {
                console.error('No sound blob');
            }
        } catch (error) {
            console.error('Error during handleAudioSave:', error);
        }
        setIsLoading(false);
    };
    // Function to open a modal with a loading indicator
    const LoadingModal = (props) => (
        <Modal open={props.open} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Modal>
    );
    return (
        <Box sx={{ padding: 5, width: '100%' }}>
            <LoadingModal open={isLoading} />
            <Stack spacing={2} alignItems="flex-start">
                <MlFileUploader
                    onUpload={handleFileUploadComplete}
                    folderPath={`RECORD/upload/`}
                    userId={USER_ID}
                    nodeId={initialSoundValues._id.toString()}
                    accept="audio/*"
                />
                <MlRecorder onRecordingComplete={handleAudioSave} />
                <TextField
                    size="small"
                    label="Sound Link"
                    variant="outlined"
                    value={nodeSoundSettings.soundLink}
                    onChange={e => handleChange('soundLink', e.target.value)}
                    sx={{ width: '100%' }}
                />
            </Stack>
        </Box>
    );
};

export default MlNodeSoundEditor;