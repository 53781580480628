import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import App from "./App-Belgicomics";
//import App from "./App-Belcar";
import App from "./App-MainList";
import { Provider } from 'react-redux';
import store from './redux/store';
import { AuthProvider } from "./AuthContext";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

import "./styles/color.css";
import "./styles/font.css";
import "./styles/index.css";
import "./styles/tailwind.css";
import "./print.css";


const theme = createTheme(
  
  {
    typography: {
      fontFamily: 'Montserrat, Arial, sans-serif',
      h1: {
        fontSize: '2.5rem',
        fontWeight: 700,
      },
      h5: {
        fontSize: '1.5rem',
        fontWeight: 500,
      },
      h6: {
        fontSize: '1.1rem',
        fontWeight: 500,
      },
      // You can customize other variants as well
    },
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  frFR,
);

// Your global error event listener
window.addEventListener('error', function(e) {
  if(e.message === "ResizeObserver loop completed with undelivered notifications.") {
    e.stopImmediatePropagation();
  }
});

// Function to set the favicon
const setFavicon = (url) => {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = url;
  document.head.appendChild(link);
};

// Get the favicon URL from the environment variable
const faviconUrl = process.env.REACT_APP_FAVICON_URL;

// Set the favicon if the environment variable is defined
if (faviconUrl) {
  setFavicon(faviconUrl);
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
