const markdownContent = `
### **Politique de Confidentialité**

#### **1\. Introduction**

Nous prenons très au sérieux la protection de vos données personnelles. Cette Politique de Confidentialité vous informe sur la manière dont nous collectons, utilisons et protégeons vos informations sur notre plateforme de vente de voitures en ligne.

#### **2\. Responsable du Traitement des Données**

Le responsable du traitement des données pour le site \[Nom de votre site\] est \[Nom de la société\], situé à \[Adresse complète\].

#### **3\. Données Collectées**

Nous collectons les types de données suivants :

* **Données d'identification** : nom, prénom, adresse email, numéro de téléphone.  
* **Données de transaction** : informations relatives à la vente ou à l'achat de véhicules (modèle, marque, prix, etc.).  
* **Données de navigation** : adresse IP, type de navigateur, pages visitées.

#### **4\. Finalités du Traitement**

Vos données personnelles sont traitées pour les finalités suivantes :

* **Gestion des comptes utilisateurs** : création et gestion de votre compte sur notre plateforme.  
* **Traitement des transactions** : gestion des achats et ventes de véhicules, suivi des commandes.  
* **Marketing et communication** : envoi de newsletters, offres promotionnelles (avec votre consentement).  
* **Amélioration du service** : analyse des données de navigation pour améliorer notre site et l’expérience utilisateur.

#### **5\. Partage des Données**

Vos données peuvent être partagées avec des tiers dans les cas suivants :

* **Prestataires de services** : pour l’hébergement, le paiement sécurisé, et autres services nécessaires au fonctionnement de la plateforme.  
* **Partenaires commerciaux** : avec votre consentement, pour des offres conjointes ou spécifiques.  
* **Autorités légales** : si la loi l'exige ou pour protéger nos droits.

#### **6\. Durée de Conservation**

Nous conservons vos données personnelles uniquement pendant la durée nécessaire aux finalités pour lesquelles elles ont été collectées, conformément à la législation en vigueur.

#### **7\. Vos Droits**

Conformément à la réglementation en matière de protection des données, vous disposez des droits suivants :

* **Droit d'accès** : vous pouvez demander l'accès à vos données personnelles.  
* **Droit de rectification** : vous pouvez demander la correction de données inexactes ou incomplètes.  
* **Droit à l’effacement** : vous pouvez demander la suppression de vos données sous certaines conditions.  
* **Droit à la portabilité** : vous pouvez recevoir vos données dans un format structuré et couramment utilisé.  
* **Droit d'opposition** : vous pouvez vous opposer au traitement de vos données à des fins de marketing.

Pour exercer vos droits, veuillez nous contacter à l'adresse email suivante : \[Adresse email de contact\].

#### **8\. Sécurité des Données**

Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos données contre l'accès non autorisé, la divulgation, l'altération ou la destruction.

#### **9\. Cookies**

Notre site utilise des cookies pour améliorer votre expérience utilisateur et pour analyser le trafic. Pour en savoir plus, consultez notre \[Politique de Cookies\].

#### **10\. Modifications de la Politique de Confidentialité**

Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment. Toute modification sera publiée sur cette page avec la date de mise à jour. Nous vous encourageons à consulter cette page régulièrement.

#### **11\. Contact**

Si vous avez des questions concernant cette Politique de Confidentialité, vous pouvez nous contacter à l'adresse suivante : \[Adresse email de contact\].`;

export default markdownContent;
