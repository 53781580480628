import React, { useState, useEffect } from 'react';
import QDraft from '../base/QDraft';

const QDraftSlide = ({ readOnly = true, value, height = '650px', width = '100%' }) => {
    //const [imageStyle, setImageStyle] = useState({});

    return (
        <div>
            <QDraft
                readOnly={readOnly}
                onChange={null}
                value={value}
                height={height}
                width={width}
            />
        </div>
    );
};

export default QDraftSlide;
