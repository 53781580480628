import React from 'react';
import {
    Stack, 
    Typography
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import {
    Title,
    Infoframe as InfoframeWrapper,
    Price,
    Pricetva,
    Tvacomprise,
    BigBold,
  } from './StyledStyles'; 

export default function InfoSeller({ value }) {
    return (
        <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
            <PhoneIcon />
            <Title variant = 'h7'>{value?.phone || '+32 476 52 31 52'}</Title>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
            <LocationOnIcon />
            <Title>{value?.address || 'Rue de Hollogne, 200 4101 Jemeppe'}</Title>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
            <EmailIcon />
            <Title>{value?.email || 'belcarscrl@skynet.be'}</Title>
        </Stack>
    </Stack>
    );
}
