import * as Realm from "realm-web";
import React, { createContext, useState, useContext } from "react";
import app from "./realm-config";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(app.currentUser);

  const logIn = async (email, password) => {
    const credentials = Realm.Credentials.emailPassword(email, password);
    const newUser = await app.logIn(credentials);
    console.log(newUser);
    setCurrentUser(newUser);
  };

  const logOut = async () => {
    await app.currentUser?.logOut();
    setCurrentUser(null);
  };

  const signUp = async (email, password) => {
    try {
      await app.emailPasswordAuth.registerUser(email, password);
    } catch (error) {
      throw error;
    }
  };

  const resendConfirmationEmail = async (email) => {
    try {
      await app.emailPasswordAuth.resendConfirmationEmail({ email });
      // Successfully resent confirmation email
    } catch (error) {
      // Handle or throw the error
      throw new Error("Could not resend the confirmation email");
    }
  };

  const sendResetPasswordEmail = async (email) => {
    try {
      await app.emailPasswordAuth.sendResetPasswordEmail(email);
      
    } catch (error) {
      throw new Error("Error sending reset password email: " + error.message);
    }
  };

  const resetPassword = async (newPassword, token, tokenId) => {
    try {
      // Here, call the MongoDB Realm SDK's function to reset the password.
      // Use the provided newPassword, token, and tokenId.
      await app.emailPasswordAuth.resetPassword({
        password: newPassword,
        token,
        tokenId,
      });
      return true;
    } catch (error) {
      console.error("Error resetting password:", error);
      return false;
    }
  };

  const value = {
    currentUser,
    logIn,
    logOut,
    signUp,
    resendConfirmationEmail,
    sendResetPasswordEmail,
    resetPassword
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
