import React from 'react';
import co2 from '../BelCar/images/co2-svgrepo-com.svg';
import group from '../BelCar/images/group.svg';
import {
  Infoframe,
  Infos,
  Infowithicon1,
  Road,
  RoadIcon,
  Text1,
  TitleSmall,
  Value,
  Transmission,
  TransmissionIcon,
} from './StyledStyles';

const VehiclePublicDetails = ({ vehicleData }) => {
  return (
    <Infoframe>
      <Infos>
        <Infowithicon1>
          <Road>
            <RoadIcon alt="" src={co2} />
          </Road>
          <Text1>
            <TitleSmall>Emissions de CO2</TitleSmall>
            <Value>{vehicleData?.co2}</Value>
          </Text1>
        </Infowithicon1>
        <Infowithicon1>
          <Transmission>
            <TransmissionIcon alt="" src={group} />
          </Transmission>
          <Text1>
            <TitleSmall>Classe d'émissions</TitleSmall>
            <Value>{vehicleData?.classeEmission}</Value>
          </Text1>
        </Infowithicon1>
      </Infos>
    </Infoframe>
  );
};

export default VehiclePublicDetails;
