import React from 'react';
import {
    FormControl,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography
} from '@mui/material';

export default function CarKeys({ values, onChange }) {
    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        onChange({ target: { name, value } });
    };

    return (
        <Accordion>
            <AccordionSummary>
                <Typography>Clés</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box my={2} display="flex" alignItems="center">
                    <FormControl variant="outlined" style={{ maxWidth: '80px' }}>
                        <Select
                            name='sliderKeys'
                            value={values.sliderKeys || 0}
                            onChange={handleSelectChange}
                        >
                            {[...Array(7)].map((_, i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Typography style={{ marginLeft: '10px' }}>Clés simples</Typography>
                </Box>
                <Box my={2} display="flex" alignItems="center">
                    <FormControl variant="outlined" style={{ maxWidth: '80px' }}>
                        <Select
                            name='sliderKeysTele'
                            value={values.sliderKeysTele || 0}
                            onChange={handleSelectChange}
                        >
                            {[...Array(7)].map((_, i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Typography style={{ marginLeft: '10px' }}>Clés avec télécommande</Typography>
                </Box>
                <Box my={2} display="flex" alignItems="center">
                    <FormControl variant="outlined" style={{ maxWidth: '80px' }}>
                        <Select
                            name='sliderNoContact'
                            value={values.sliderNoContact || 0}
                            onChange={handleSelectChange}
                        >
                            {[...Array(7)].map((_, i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Typography style={{ marginLeft: '10px' }}>Clés sans contact</Typography>
                </Box>
                <Box my={2} display="flex" alignItems="center">
                    <FormControl variant="outlined" style={{ maxWidth: '80px' }}>
                        <Select
                            name='sliderTeleSeule'
                            value={values.sliderTeleSeule || 0}
                            onChange={handleSelectChange}
                        >
                            {[...Array(7)].map((_, i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Typography style={{ marginLeft: '10px' }}>Télécommande seule</Typography>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
