import OpenAI from 'openai';

import {
  objectToJson
} from './objectToTextForEmbedding'

import {
  createNode,
  nodePut,
  nodeGet,
  nodeDelete,
  uploadFileToServer
} from './MlNode'

//create embedding from a text the objectToJson has probably nothing to do here and must be implemented for autodi 


//get the variable from .env
const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

export const getEmbedding = async (input) => {
  try {
    // Attempt to create an embedding using the OpenAI API
    
    const response = await openai.embeddings.create({
      model: "text-embedding-3-small",
      input: input,      // Use the provided input text
    });

    // Extract the embedding data from the response
    const embedding = response.data[0].embedding;
   // console.log('Generated embedding:', embedding);

    // Return the embedding directly
    return embedding;
  } catch (error) {
    // Log the error to the console
    console.error('There was an error generating the embedding', error);

    // Return a simple error message
    return "An error occurred while generating the embedding.";
  }
};
/*
export const getDescription = async (prompt) => {
  try {
    const chatCompletion = await openai.chat.completions.create({
      model: GPT_MODEL,
      messages: [
        {
          "role": "user",
          "content": "Write a 350 words text that discribe precisely the following using the property names as a guide:" + prompt
        }
      ],
    });
    const messageContent = chatCompletion.choices[0].message.content;
    console.log(messageContent);
    return messageContent;  // Return the content string directly
  } catch (error) {
    console.error('There was an error fetching the data', error);
    return "An error occurred.";
  }
};
*/
