import React from 'react';
import styled from 'styled-components';

export const Stack = styled.div`
  
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};

/*
  background-color: rgb(0,0,0,0.1);
  border: 1px solid black;
  */
  /* Apply spacing between child elements */
  & > *:not(:last-child) {
    margin-bottom: ${({ spacing }) => spacing || 0}rem;
  }

  @media (max-width: 650px) {
    & > *:not(:last-child) {
      margin-bottom: ${({ spacing }) => (spacing ? spacing / 2 : 0)}rem;
    }
  }

  @media (max-width: 530px) {
    & > *:not(:last-child) {
      margin-bottom: ${({ spacing }) => (spacing ? spacing / 10 : 0)}rem;
    }
  }
`;

// Define the styled component inside the same file
const Frame = styled.button`
  width: 100px;
  height: 100px;
  
  border: none;
  /*border-radius: 10px;*/
  color: black;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: rgb(0,0,0,0.1); /* Darker shade on hover */
  }

  @media (max-width: 650px) {
    font-size: 14px; /* Adjust this value to the desired font size */
  }
  /* Media query for screens smaller than 400px */
  @media (max-width: 530px) {
    font-size: 12px; /* Adjust to an even smaller font size */
    width: 75px;
  height: 75px;
  
  }

`;

// Define the JSX component that uses the styled component
const IconButton = ({ children, image = '' }) => {
    return (
        <Frame>
            <Stack alignItems="center" justifyContent="center" spacing={1}>
                {image && <img src={image} alt="Icon" style={{ width: '60%', height: '60%', objectFit: 'contain' }} />}
                {children}  {/* Render the children passed into the component */}
            </Stack>
        </Frame>
    );
};

export default IconButton;
