import React, { useState, useEffect  } from 'react';
import { Stack, Typography, IconButton, TextField, Box, Checkbox, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import QDraft from './QDraft';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { v4 as uuidv4 } from 'uuid';

const EditableLabel = ({ text, onLabelChange = () => { }, editable = true }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(text);

  // Start editing when double-clicked
  const handleDoubleClick = () => {
    if (!editable) return; // Prevent entering edit mode if not editable
    setIsEditing(true);
    setEditText(text);
  };

  // Update text while editing, allowing only certain characters
  const handleChange = (e) => {
    const newText = e.target.value;
    // Allow letters (including accented characters) and spaces
    if (/^[\p{Letter} ]+$/u.test(newText)) {
      setEditText(newText);
    }
  };


  // Handle onBlur to finalize editing
  const handleBlur = () => {
    if (editText.trim() !== text) {
      onLabelChange(editText.trim()); // Call onLabelChange with the trimmed text
    }
    setIsEditing(false); // Exit editing mode
  };

  // Handle keyboard actions
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') { // When Enter or Escape is pressed
      e.preventDefault(); // Prevent form submission
      handleBlur(); // Trigger blur manually to apply changes
    }
  };

  return isEditing ? (
    <TextField
      size="small"
      value={editText}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      autoFocus
      fullWidth
    />
  ) : (
    <Typography
      onDoubleClick={handleDoubleClick}
      sx={{ cursor: editable ? 'pointer' : 'default', width: '100%', mr: 2, fontWeight: 'bold' }}
    >
      {text || "Default Label"}:
    </Typography>
  );
};

const handleEventPropagation = (event) => {
    event.stopPropagation();
  };

  const QProperty = ({ propertyName = "Default Label", onDelete, onLabelChange, children, editable = true }) => {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack direction="column" width='100%'>
          <EditableLabel text={propertyName} onLabelChange={onLabelChange} editable={editable} />
          {children}
          {editable && (
            <IconButton onClick={onDelete} aria-label="delete" sx={{ alignSelf: 'flex-end', fontSize: '12px' }}>
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  };

const TextProperty = ({ propertyName, value, onChange, onDelete, onLabelChange, editable = true }) => {
  
  return (
    <QProperty propertyName={propertyName} onDelete={onDelete} onLabelChange={onLabelChange} editable = {editable} >
      <TextField
        fullWidth
        multiline
        size='small'
        value={value}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onClick={handleEventPropagation}
        onMouseDown={handleEventPropagation}
        onFocus={handleEventPropagation}
        onChange={(e) => onChange(e.target.value)}
      />
    </QProperty>
  );
};

const CheckBoxProperty = ({ propertyName, value, onChange, onDelete, onLabelChange, editable = true }) => {
  
  // Adjust the change handler for checkbox to directly pass the checked state
  const handleCheckboxChange = (event) => {
    onChange(event.target.checked); // Pass the checked state as a boolean
  };

  return (
    <QProperty propertyName={propertyName} onDelete={onDelete} onLabelChange={onLabelChange} editable = {editable}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value} // Assume value is a boolean that determines if the checkbox is checked
            onChange={handleCheckboxChange}
          />
        }
        label={propertyName} // Use propertyName as the label for the checkbox
      />
    </QProperty>
  );
};

const DateProperty = ({ propertyName, value, onChange, onDelete, onLabelChange , editable = true}) => {
  const handleValueChange = (newValue) => {
    onChange(newValue ? dayjs(newValue).format('YYYY-MM-DD') : '');
  };

  return (
    <QProperty propertyName={propertyName} onDelete={onDelete} onLabelChange={onLabelChange} editable = {editable}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value ? dayjs(value) : null}
          onChange={handleValueChange}
          renderInput={(params) => <TextField {...params} size="small" fullWidth />}
        />
      </LocalizationProvider>
    </QProperty>
  );
};

const QDraftProperty = ({ propertyName, value, onChange, onDelete, onLabelChange, editable = true }) => {
  

  return (
    <QProperty propertyName={propertyName} onDelete={onDelete} onLabelChange={onLabelChange} editable = {editable}>
      <Box
        sx={{
          marginTop: .5,
          flex: 1,
          border: 1,
          borderColor: 'divider',
          borderRadius: '4px',
          padding: 1
        }}
        onClick={handleEventPropagation}
        onMouseDown={handleEventPropagation}
        onFocus={handleEventPropagation}
      >
        <QDraft readOnly={false} onChange={onChange} value={value} />
      </Box>
    </QProperty>
  );
};

const UUIDProperty = ({ propertyName, value, onChange, onDelete, onLabelChange, editable = true }) => {
  const [isEditing, setIsEditing] = useState(false);

  // Generate a UUID only if there isn't one already provided
  useEffect(() => {
    if (!value) {
      onChange(uuidv4());
    }
  }, [value, onChange]);

  const copyUuidToClipboard = () => {
    navigator.clipboard.writeText(value);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleUuidChange = (e) => {
    onChange(e.target.value);
    setIsEditing(false);
  };

  return (
    <QProperty propertyName={propertyName} onDelete={onDelete} onLabelChange={onLabelChange} editable = {editable}>
      {isEditing ? (
        <TextField
          fullWidth
          size='small'
          value={value}
          variant="outlined"
          onChange={handleUuidChange}
          onBlur={() => setIsEditing(false)}
          autoFocus
        />
      ) : (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography onDoubleClick={handleDoubleClick} sx={{ flexGrow: 1, cursor: 'pointer' }}>
            {value}
          </Typography>
          <IconButton onClick={copyUuidToClipboard} aria-label="copy">
            <ContentCopyIcon />
          </IconButton>
        </Stack>
      )}
    </QProperty>
  );
};


export default { DateProperty, QDraftProperty, TextProperty, CheckBoxProperty, UUIDProperty };
