import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';

export default function CarTypes({ value, onChange }) {
    return (
        <>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="car-type-label">Type</InputLabel>
                <Select
                    name="type"
                    label="Type"
                    value={value}
                    onChange={onChange}
                >
                    <MenuItem value="1">Cabriolet</MenuItem>
                    <MenuItem value="2">Coupé</MenuItem>
                    <MenuItem value="3">Break</MenuItem>
                    <MenuItem value="4">Berline</MenuItem>
                    <MenuItem value="5">SUV</MenuItem>
                    <MenuItem value="6">Mobil Home</MenuItem>
                    <MenuItem value="7">Monospace</MenuItem>
                    <MenuItem value="8">Moto</MenuItem>
                    <MenuItem value="9">Commercial</MenuItem>
                    <MenuItem value="10">Pickup</MenuItem>
                    <MenuItem value="11">Citadine</MenuItem>
                </Select>
            </FormControl>
        </>
    );
}
