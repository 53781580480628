import React, { useState, useEffect } from 'react';
import { List, Stack, Paper } from '@mui/material';
import { getNodesList } from '../../MlModels/MlNode';
import AutoDinCard from './AutoDinCard';
import { useAuth } from "../../AuthContext";

const AutoDinList = ({ mt = 0, elevation = 0, onCardClick, refresh, searchEmbedding, selectedTab }) => {
    const { currentUser } = useAuth();
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);

    // Determine the status filter and user-specific conditions based on the selected tab
    const customMatch = currentUser ? {
        identity: 'autodin',
        ...(selectedTab === 0 && { 
            userId: currentUser?.uid, 
            status: { $nin: ['Public', 'Archive'] } // Include nodes not Public and not Archive
        }),
        ...(selectedTab === 1 && { userId: currentUser?.uid, status: 'Archive' }),
        ...(selectedTab === 2 && { userId: currentUser?.uid, status: 'Public' }),
        ...(selectedTab === 3 && { status: 'Public' }), // Tout tab, shows all Public nodes
        ...(searchEmbedding && {
            vectorSearch: {
                index: 'vector_index',
                path: 'embedding',
                queryVector: searchEmbedding,
                numCandidates: 50,
                limit: 5
            }
        })
    } : {
        identity: 'autodin',
        status: 'Public', // When there is no user, default to public nodes
        ...(searchEmbedding && {
            vectorSearch: {
                index: 'vector_index',
                path: 'embedding',
                queryVector: searchEmbedding,
                numCandidates: 50,
                limit: 5
            }
        })
    };

    useEffect(() => {
        const fetchNodes = async () => {
            setLoading(true);
            try {
                // Fetch nodes based on the constructed customMatch
                const nodesList = await getNodesList(currentUser?.uid, customMatch, null);
                console.log('Fetched nodes:', nodesList);
    
                // Apply sorting if the selectedTab is 1, 2, or 3
                let sortedNodes = nodesList;
                if ([0, 1, 2].includes(selectedTab)) {
                    sortedNodes = [...nodesList].sort((a, b) => {
                        const dateA = new Date(a.updatedAt).getTime();  // Convert to timestamp
                        const dateB = new Date(b.updatedAt).getTime();  // Convert to timestamp
                        
                        // Reverse sorting: larger dates (newer) first
                        return dateB - dateA; // Sort in descending order (newest on top)
                    });
                }
    
                setNodes(sortedNodes); // Set the sorted nodes
            } catch (error) {
                console.error('Failed to fetch nodes:', error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchNodes();
    }, [refresh, searchEmbedding, selectedTab]);
    

    return (
        <Paper
            sx={{ width: '100%', bgcolor: 'rgb(250,250,250)', mt: { mt }, borderRadius: '0px' }}
            elevation={elevation}
        >
            <Stack direction="column" width="100%" maxHeight="100%" overflow="auto" padding={0}>
                <Stack
                    direction="column"
                    width="100%"
                    maxHeight="100%"
                    overflow="auto"
                >
                    <List sx={{ width: '100%', bgcolor: 'rgb(250,250,250)' }}>
                        {nodes.map((node) => (
                            <AutoDinCard key={node._id.toString()} node={node} onCardClick={onCardClick} />
                        ))}
                    </List>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default AutoDinList;
