import React from 'react';
import { Stack, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import MlC from '../../base/MlComponents';

const DataSettings = ({
  components,
  componentValues,
  onAddComponent,
  onComponentValueChange,
  onLabelChange,
  onDeleteComponent,
  onMenuClick,
  onMenuClose,
  anchorEl,
}) => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
        <Typography variant="h6">Properties</Typography>
        <IconButton
          aria-controls="add-property-menu"
          aria-haspopup="true"
          onClick={onMenuClick}
          color="primary"
        >
          <AddCircleRoundedIcon />
        </IconButton>
      </Stack>
      <Menu
        id="add-property-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
      >
        {Object.keys(MlC).map((componentType) => (
          <MenuItem key={componentType} onClick={() => onAddComponent(componentType)}>
            {componentType}
          </MenuItem>
        ))}
      </Menu>
      <Stack direction="column">
        {components.map((propertyName, index) => {
          const componentType = propertyName.split('_')[1];
          const Component = MlC[componentType];
          if (!Component) return null;

          const value = componentValues[propertyName] || '';

          return (
            <Component
              key={propertyName}
              propertyName={propertyName.split('_')[0].replace(/-/g, ' ')}
              value={value}
              onChange={(newValue) => onComponentValueChange(propertyName, newValue)}
              onLabelChange={(newLabel) => onLabelChange(propertyName, newLabel)}
              onDelete={() => onDeleteComponent(propertyName)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default DataSettings;