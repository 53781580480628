import React from 'react';
import { Box } from '@mui/material';

const ImageCanvas = ({ src, height = '450px', backgroundColor = 'rgba(0, 0, 0, 0.5)' }) => {
  const imagePlaceHolder = 'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg';
  const finalSrc = src ? src : imagePlaceHolder;

  return (
    <Box
      sx={{
        width: '100%',
        height: height,
        backgroundColor: backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        component="img"
        src={finalSrc}
        alt="Image Canvas"
        sx={{
          maxHeight: '100%',
          maxWidth: '100%',
          objectFit: 'contain'
        }}
      />
    </Box>
  );
};

export default ImageCanvas;