import React, { useState, useEffect } from 'react';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider'; // Replace with the actual path
import { Stack, Box, MenuItem, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NodeTree from '../qwanyx/NodeTree';

export default function MlCanvasList() {

    const {
        nodes,
    } = useMlCanvas();

    return (
        <NodeTree />
    );
}
