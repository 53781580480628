// ImageEditor.js
import React, { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import ToolBar from './ToolBar';
import ImageListComponent from './ImageList';
import ImageCanvas from './ImageCanvas';
import { deleteFileFromServer, getUserId } from '../MlModels/MlNode'; // Import the delete function
import ImagePlaceHolder from './Vehicle.webp'
const ImageEditor = ({ node, onChange, loggedIn = false }) => {
  //const imagePlaceHolder = VehiclePlaceHolder;

  const getFirstImageLink = (imageLinks) => {
    const firstImageLink = imageLinks ? imageLinks.split('\n')[0] : null;
    return firstImageLink ? `${process.env.REACT_APP_S3_URL_BASE}/${firstImageLink}` : ImagePlaceHolder;
  };

  const [imageUrl, setImageUrl] = useState(getFirstImageLink(node.imageLink));
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // When the component mounts or when `node` prop changes, set the first image
    setImageUrl(getFirstImageLink(node.imageLink));
    setSelectedImage(0);
  }, [node]);

  const handleImageClick = (url, index) => {
    setImageUrl(url);
    setSelectedImage(index);
  };

  const moveImageToFirst = () => {
    if (selectedImage !== null) {
      const imageLinks = node.imageLink.split('\n');
      const selectedLink = imageLinks[selectedImage];
      imageLinks.splice(selectedImage, 1);
      imageLinks.unshift(selectedLink);
      onChange('imageLink', imageLinks.join('\n'));
      setImageUrl(`${process.env.REACT_APP_S3_URL_BASE}/${selectedLink}`);
      setSelectedImage(0); // Update the selected image index
    }
  };

  const deleteImage = async () => {
    if (selectedImage !== null) {
      const imageLinks = node.imageLink.split('\n');
      const selectedLink = imageLinks[selectedImage];
      imageLinks.splice(selectedImage, 1);
      onChange('imageLink', imageLinks.join('\n'));

      // Set the new imageUrl based on the updated list
      if (imageLinks.length > 0) {
        if (selectedImage > 0) {
          setImageUrl(`${process.env.REACT_APP_S3_URL_BASE}/${imageLinks[selectedImage - 1]}`);
          setSelectedImage(selectedImage - 1);
        } else {
          setImageUrl(`${process.env.REACT_APP_S3_URL_BASE}/${imageLinks[0]}`);
          setSelectedImage(0);
        }
      } else {
        setImageUrl(ImagePlaceHolder);
        setSelectedImage(null);
      }

      // Call the server to delete the file from S3
      try {

        const folderPath = `IMAGES/autodin/`;
        const userId = getUserId();
        const fileNameFromUrl = selectedLink.split('/').pop(); // Extract the file name from the URL
        const fileName = folderPath + userId + fileNameFromUrl

        const result = await deleteFileFromServer(fileName);
        if (result.success) {
          console.log('File deleted successfully');
        } else {
          console.error('Error deleting file:', result.error);
        }
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  return (
    <Stack sx={{ backgroundColor: 'rgb(150,150,150)', height: '100%' }}>
      <Stack>
        <ImageCanvas src={imageUrl} />

        {loggedIn &&
          <ToolBar
          node={node}
          onChange={onChange}
          moveImageToFirst={moveImageToFirst}
          selectedImage={selectedImage}
          deleteImage={deleteImage}
          />
        }

      </Stack>
      <Box>
        <ImageListComponent images={node.imageLink} onImageClick={handleImageClick} />
      </Box>
    </Stack>
  );
};

export default ImageEditor;
