import { Typography, Box, Paper } from '@mui/material';

const SectionWithHeader = ({ header, content, minHeight = '150px', maxHeight = '250px', isHtml = false }) => {
  if (!content) return null;

  return (
    <Box style={{ marginBottom: '10px', width: '100%'  }}>
      <Typography variant="h6" style={{ fontWeight: 600, fontSize: '18px' }}>
        {header}
      </Typography>
      <Paper style={{ minHeight: minHeight, maxHeight: maxHeight, overflowY: 'auto', padding: '16px', width: '100%' }}>
        {isHtml ? (
          <Typography
            variant="body1"
            style={{ fontSize: '16px' }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : (
          <Typography variant="body1" style={{ fontSize: '16px' }}>
            {content}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default SectionWithHeader;
