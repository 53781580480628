import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

export default function Transmission({ transmission, gears, onChange }) {
    const [transmissionType, setTransmissionType] = useState(transmission || 2); // Default to Manuelle
    const [gearCount, setGearCount] = useState(gears || 4); // Default to 4 gears

    useEffect(() => {
        setTransmissionType(transmission);
        setGearCount(gears);
    }, [transmission, gears]);

    const handleTransmissionChange = (event) => {
        const newValue = Number(event.target.value);
        setTransmissionType(newValue);
        onChange({ target: { name: 'transmission', value: newValue } });

        if (newValue === 1) { // Automatique
            setGearCount(''); // Clear gear count
            onChange({ target: { name: 'gears', value: '' } });
        } else if (newValue === 2) { // Manuelle
            setGearCount(4); // Reset to 4 gears
            onChange({ target: { name: 'gears', value: 4 } });
        }
    };

    const handleGearsChange = (event) => {
        const newValue = event.target.value;
        setGearCount(newValue);
        onChange({ target: { name: 'gears', value: newValue } });
    };

    return (
        <Box my={2}>
            <FormControl fullWidth variant="outlined">
                <InputLabel>Transmission</InputLabel>
                <Select
                    name="transmission"
                    label="Transmission"
                    value={transmissionType}
                    onChange={handleTransmissionChange}
                >
                    <MenuItem value={1}>Automatique</MenuItem>
                    <MenuItem value={2}>Manuelle</MenuItem>
                </Select>
            </FormControl>
            {transmissionType === 2 && (
                <Box mt={2}>
                    <TextField
                        name="gears"
                        label="Vitesses"
                        type="number"
                        value={gearCount}
                        onChange={handleGearsChange}
                        inputProps={{ min: 1 }}
                        fullWidth
                        variant="outlined"
                    />
                </Box>
            )}
        </Box>
    );
}
