import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { EditIcon } from '../../assets/icons/IconPack';

const AccordionContainer = styled.div`
  position: relative;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ isEditing }) => (isEditing ? 'default' : 'pointer')};
  padding: 8px 12px;
  border: none;
`;

const commonTitleStyles = `
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  line-height: 1.2;
  height: 1.2em;
`;

const AccordionTitle = styled.span`
  ${commonTitleStyles}
  cursor: pointer;
`;

const TitleEditInput = styled.input`
  ${commonTitleStyles}
  border: none;
  box-sizing: border-box;
`;

const BriefDisplay = styled.span`
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 200;
  display: block;
  color: rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0px 15px;
  cursor: pointer; /* Make it clickable */
  flex-grow: 1;
`;

const BriefTextArea = styled.textarea`
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 200;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  overflow-y: auto; /* Enable vertical scrolling */
  line-height: 1.4;
  max-height: 150px; /* Set a maximum height to control the scroll area */
  border: 1px solid rgba(0, 0, 0, 0.1);
   /* Add some space between the textarea and the icon */
`;

const IconWrapper = styled.div`
  padding: 8px 12px;
  display: flex;
  justify-content: flex-end;
`;

const AccordionContent = styled.div`
  background-color: rgba(0,0,0,0.02);
  padding: ${({ level }) => `${6 * (level + 1)}px 0px`};
  box-shadow: ${({ level }) =>
        `inset 0 ${2 * (level + 1)}px ${4 * (level + 1)}px -${2 * (level + 1)}px rgba(0, 0, 0, 0.${level + 1})`};
  border-top: 1px solid rgba(0, 0, 0, 0.1); /* Top border */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Bottom border */
`;

const Dot = styled.span`
  width: 12px;
  height: 12px;
  background-color: ${({ isOpen }) => (isOpen ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.5)')};
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer; /* Make the dot clickable */
`;

const Accordion = ({ title, children, level = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentTitle, setCurrentTitle] = useState(title);
    const [brief, setBrief] = useState("");

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.key === 'o') {
                e.preventDefault(); // Prevent the default Ctrl + O behavior
                alert('Open the node'); // Display the alert
            }
        };

        if (isEditing) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isEditing]);

    const hasChildren = React.Children.count(children) > 0;

    const toggleAccordion = (e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const handleTitleClick = (e) => {
        e.stopPropagation();
        setIsEditing(true);
    };

    const handleBriefClick = (e) => {
        e.stopPropagation();
        setIsEditing(true);
    };

    const handleTitleChange = (e) => {
        setCurrentTitle(e.target.value);
    };

    const handleBriefChange = (e) => {
        setBrief(e.target.value);
    };

    const handleBlur = (e) => {
        if (e.relatedTarget && (e.relatedTarget.name === 'title' || e.relatedTarget.name === 'brief')) {
            return; // If focus is moving between title and brief, don't stop editing.
        }
        setIsEditing(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent adding a new line
            setIsEditing(false); // Exit edit mode and collapse the brief
        }
    };

    return (
        <AccordionContainer>
            <AccordionHeader isEditing={isEditing}>
                {isEditing ? (
                    <TitleEditInput
                        type="text"
                        value={currentTitle}
                        name="title"
                        onChange={handleTitleChange}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        autoFocus
                    />
                ) : (
                    <AccordionTitle onClick={handleTitleClick}>
                        {currentTitle}
                    </AccordionTitle>
                )}
                {hasChildren && <Dot isOpen={isOpen} onClick={toggleAccordion} />}
            </AccordionHeader>
            {isEditing ? (
                <>
                    <BriefTextArea
                        value={brief}
                        name="brief"
                        onChange={handleBriefChange}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        placeholder="Add a comment..."
                        rows={4}
                        autoFocus
                    />
                    <IconWrapper>
                        <EditIcon
                            size={12}
                            color="gray"
                        />
                    </IconWrapper>
                </>
            ) : (
                <BriefDisplay onClick={handleBriefClick}>
                    {brief.length > 30 ? `${brief.slice(0, 30)}...` : brief}
                </BriefDisplay>
            )}
            {isOpen && hasChildren && (
                <AccordionContent level={level}>
                    {children}
                </AccordionContent>
            )}
        </AccordionContainer>
    );
};

export default Accordion;
