// ColorControlLabel.js
import React from 'react';
import { FormControlLabel, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import chroma from 'chroma-js';


const ColorControlLabel = ({ color, selected, onColorChange, label }) => {
    const isDark = chroma(color).luminance() <= 0.5;
  
    return (
      <FormControlLabel
        control={
          <Paper
            onClick={() => onColorChange(color)}
            sx={{
              width: '36px',
              height: '36px',
              backgroundColor: color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '4px',
            }}
            elevation={3}
          >
            {selected && <CheckIcon sx={{ color: isDark ? 'white' : 'black' }} />}
          </Paper>
        }
        label={label}
      />
    );
  };
  

export default ColorControlLabel;
