import React, { useState, useRef } from 'react';
import TranscribeIcon from '@mui/icons-material/Transcribe';
import { Alert, IconButton, Tooltip } from '@mui/material';

export default function MlTranscribeFile({ onRecordingComplete, size = '24px' }) {
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError('No file selected.');
      return;
    }

    if (file.size < 3000) { // Check if the file is less than 3 seconds (approximately)
      setError('File is too short. Please select a file of at least 3 seconds.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const audioBlob = new Blob([reader.result], { type: file.type });
      onRecordingComplete(audioBlob);
      setError('');
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <IconButton
        size="px"
        component="label"
        style={{ color: 'black' }}
      >
        <Tooltip title="Transcript audio" arrow>
          <TranscribeIcon sx={{ fontSize: size }} />
        </Tooltip>
        <input
          type="file"
          hidden
          accept="audio/*"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </IconButton>
    </>
  );
}
