import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

function SmtpSettingsDialog({ open, onClose }) {
  const [smtpSettings, setSmtpSettings] = useState({
    host: '',
    port: '',
    username: '',
    password: '',
    secure: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSmtpSettings({ ...smtpSettings, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setSmtpSettings({ ...smtpSettings, secure: e.target.checked });
  };

  const handleSave = () => {
    const smtpSettingsJson = JSON.stringify(smtpSettings);
    console.log(smtpSettingsJson); // This will be your JSON object for storage
    // Here you would usually call a function to handle the storing process.
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">SMTP Server Credentials</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="host"
          name="host"
          label="SMTP Host"
          type="text"
          fullWidth
          value={smtpSettings.host}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="port"
          name="port"
          label="SMTP Port"
          type="number"
          fullWidth
          value={smtpSettings.port}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="username"
          name="username"
          label="Username"
          type="text"
          fullWidth
          value={smtpSettings.username}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="password"
          name="password"
          label="Password"
          type="password"
          fullWidth
          value={smtpSettings.password}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={smtpSettings.secure}
              onChange={handleCheckboxChange}
              name="secure"
              color="primary"
            />
          }
          label="Secure Connection (SSL/TLS)"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SmtpSettingsDialog;
