//import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import InboxIcon from '@mui/icons-material/Inbox';

export const BusinessOutlet = ({ fill = "#1C1B1F", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_84_623" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_84_623)">
            <path d="M21.025 11.05V19C21.025 19.55 20.8291 20.0208 20.4375 20.4125C20.0458 20.8042 19.575 21 19.025 21H5.02497C4.47497 21 4.00413 20.8042 3.61247 20.4125C3.2208 20.0208 3.02497 19.55 3.02497 19V11.05C2.64163 10.7 2.3458 10.25 2.13747 9.7C1.92913 9.15 1.92497 8.55 2.12497 7.9L3.17497 4.5C3.3083 4.06667 3.5458 3.70833 3.88747 3.425C4.22913 3.14167 4.62497 3 5.07497 3H18.975C19.425 3 19.8166 3.1375 20.15 3.4125C20.4833 3.6875 20.725 4.05 20.875 4.5L21.925 7.9C22.125 8.55 22.1208 9.14167 21.9125 9.675C21.7041 10.2083 21.4083 10.6667 21.025 11.05ZM14.225 10C14.675 10 15.0166 9.84583 15.25 9.5375C15.4833 9.22917 15.575 8.88333 15.525 8.5L14.975 5H13.025V8.7C13.025 9.05 13.1416 9.35417 13.375 9.6125C13.6083 9.87083 13.8916 10 14.225 10ZM9.72497 10C10.1083 10 10.4208 9.87083 10.6625 9.6125C10.9041 9.35417 11.025 9.05 11.025 8.7V5H9.07497L8.52497 8.5C8.4583 8.9 8.5458 9.25 8.78747 9.55C9.02913 9.85 9.34163 10 9.72497 10ZM5.27497 10C5.57497 10 5.83747 9.89167 6.06247 9.675C6.28747 9.45833 6.42497 9.18333 6.47497 8.85L7.02497 5H5.07497L4.07497 8.35C3.97497 8.68333 4.02913 9.04167 4.23747 9.425C4.4458 9.80833 4.79163 10 5.27497 10ZM18.775 10C19.2583 10 19.6083 9.80833 19.825 9.425C20.0416 9.04167 20.0916 8.68333 19.975 8.35L18.925 5H17.025L17.575 8.85C17.625 9.18333 17.7625 9.45833 17.9875 9.675C18.2125 9.89167 18.475 10 18.775 10Z" fill={fill} />
        </g>
    </svg>
);

export const ProductCategory = ({ fill = "#1C1B1F", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_84_626" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_84_626)">
            <path d="M6.5 11L12 2L17.5 11H6.5ZM17.5 22C16.25 22 15.1875 21.5625 14.3125 20.6875C13.4375 19.8125 13 18.75 13 17.5C13 16.25 13.4375 15.1875 14.3125 14.3125C15.1875 13.4375 16.25 13 17.5 13C18.75 13 19.8125 13.4375 20.6875 14.3125C21.5625 15.1875 22 16.25 22 17.5C22 18.75 21.5625 19.8125 20.6875 20.6875C19.8125 21.5625 18.75 22 17.5 22ZM3 21.5V13.5H11V21.5H3Z" fill={fill} />
        </g>
    </svg>
);

export const Product = ({ fill = "#1C1B1F", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_84_629" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_84_629)">
            <path d="M14.25 21.4C13.8667 21.7833 13.3917 21.975 12.825 21.975C12.2583 21.975 11.7833 21.7833 11.4 21.4L2.6 12.6C2.41667 12.4167 2.27083 12.2 2.1625 11.95C2.05417 11.7 2 11.4333 2 11.15V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H11.15C11.4333 2 11.7 2.05417 11.95 2.1625C12.2 2.27083 12.4167 2.41667 12.6 2.6L21.4 11.425C21.7833 11.8083 21.975 12.2792 21.975 12.8375C21.975 13.3958 21.7833 13.8667 21.4 14.25L14.25 21.4ZM6.5 8C6.91667 8 7.27083 7.85417 7.5625 7.5625C7.85417 7.27083 8 6.91667 8 6.5C8 6.08333 7.85417 5.72917 7.5625 5.4375C7.27083 5.14583 6.91667 5 6.5 5C6.08333 5 5.72917 5.14583 5.4375 5.4375C5.14583 5.72917 5 6.08333 5 6.5C5 6.91667 5.14583 7.27083 5.4375 7.5625C5.72917 7.85417 6.08333 8 6.5 8Z" fill={fill} />
        </g>
    </svg>
);

export const Ad = ({ fill = "#1C1B1F", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_140_72" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_140_72)">
            <path d="M3.54545 20V15.3846H2.36364C1.71364 15.3846 1.1572 15.1587 0.694318 14.7067C0.231439 14.2548 0 13.7115 0 13.0769V10.7692C0 10.1346 0.231439 9.59135 0.694318 9.13942C1.1572 8.6875 1.71364 8.46154 2.36364 8.46154H7.09091L13 5V18.8462L7.09091 15.3846H5.90909V20H3.54545ZM2.36364 10.7692V13.0769H7.74091L10.6364 14.75V9.09615L7.74091 10.7692H2.36364Z" fill={fill} />
            <path d="M14.2879 14.1327C14.096 13.9408 14 13.703 14 13.4193C14 13.1356 14.096 12.8978 14.2879 12.7059L18.6934 8.30038C18.7851 8.20859 18.8936 8.13559 19.0188 8.08135C19.1439 8.02712 19.2774 8 19.4193 8L22.9987 8C23.2741 8 23.5098 8.09804 23.7059 8.29412C23.902 8.4902 24 8.72591 24 9.00125L24 12.5807C24 12.7226 23.9729 12.8561 23.9186 12.9812C23.8644 13.1064 23.7914 13.2149 23.6996 13.3066L19.2816 17.7121C19.0897 17.904 18.854 18 18.5745 18C18.295 18 18.0592 17.904 17.8673 17.7121L14.2879 14.1327ZM20.9962 10.2528C20.9962 10.4614 21.0693 10.6387 21.2153 10.7847C21.3613 10.9307 21.5386 11.0038 21.7472 11.0038C21.9558 11.0038 22.1331 10.9307 22.2791 10.7847C22.4251 10.6387 22.4981 10.4614 22.4981 10.2528C22.4981 10.0442 22.4251 9.86692 22.2791 9.7209C22.1331 9.57489 21.9558 9.50188 21.7472 9.50188C21.5386 9.50188 21.3613 9.57489 21.2153 9.7209C21.0693 9.86692 20.9962 10.0442 20.9962 10.2528Z" fill={fill} />
        </g>
    </svg>
);

export const Campaign = ({ fill = "#1C1B1F", size = "24" }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_84_750" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_84_750)">
            <path d="M19.2 13.25V10.75H24V13.25H19.2ZM20.64 22L16.8 19L18.24 17L22.08 20L20.64 22ZM18.24 7L16.8 5L20.64 2L22.08 4L18.24 7ZM3.6 20.75V15.75H2.4C1.74 15.75 1.175 15.5052 0.705 15.0156C0.235 14.526 0 13.9375 0 13.25V10.75C0 10.0625 0.235 9.47396 0.705 8.98438C1.175 8.49479 1.74 8.25 2.4 8.25H7.2L13.2 4.5V19.5L7.2 15.75H6V20.75H3.6ZM14.4 16.1875V7.8125C14.94 8.3125 15.375 8.92188 15.705 9.64063C16.035 10.3594 16.2 11.1458 16.2 12C16.2 12.8542 16.035 13.6406 15.705 14.3594C15.375 15.0781 14.94 15.6875 14.4 16.1875ZM2.4 10.75V13.25H7.86L10.8 15.0625V8.9375L7.86 10.75H2.4Z" fill={fill} />
        </g>
    </svg>
);

export const MailSettings = ({ fill = "#1C1B1F", size = "24" }) => (
    <InboxIcon style={{ color: fill, fontSize: size }} />
  );