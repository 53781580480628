import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Radio, TextField, Stack, RadioGroup, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import CarTypes from './CarTypes';
import Motorisation from './Motorisation';
import BrandModelSelect from '../BelCar/form/BrandModelSelect';
import PowerInput from '../BelCar/base/PowerInput';
import CarStates from './CarStates';
import CarTarifs from './CarTarifs';
import Registration from './Registration';
import Transmission from './Transmission';

export default function DashBoardProfile({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>
                <TextField
                    label="Pénom"
                    name="firstName"
                    value={node?.firstName || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Nom"
                    name="name"
                    value={node?.name || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Pseudo"
                    name="title"
                    value={node?.title || ''}
                    onChange={handleInputChange}
                />
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Langue</InputLabel>
                    <Select
                        name="supplier"
                        value={node?.supplier || ''}
                        label="Fournisseur"
                        onChange={handleInputChange}
                    >
                        <MenuItem value="FR">Français</MenuItem>
                        <MenuItem value="NL">Néérlandais</MenuItem>
                        <MenuItem value="EN">Anglais</MenuItem>
                    </Select>
                </FormControl>
                
                <TextField
                    name='comments'
                    id="outlined-multiline-static"
                    label="Commentaires"
                    value={node?.comments || ''}
                    multiline
                    minRows={10}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Stack>
        </FormGroup>
    );
}
