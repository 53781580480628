import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: auto;
  height: ${({ height }) => height || '36px'};
  fill: ${({ fill }) => fill || '#D8D5D5'};
`;

const BelgicomicLogo = ({ height, color }) => (
  <StyledSVG
    height={height}
    fill={color}
    viewBox="0 0 183 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2_17)">
      <path d="M15.9243 19.4415C16.4951 20.2952 16.7816 21.3256 16.7816 22.5329C16.7816 24.2971 16.1598 25.6581 14.9173 26.6159C13.6747 27.5749 11.8771 28.0532 9.52569 28.0532H0V7.92743H9.0206C11.2712 7.92743 12.9839 8.40088 14.1596 9.35019C15.3353 10.2995 15.9232 11.5794 15.9232 13.1886C15.9232 14.1476 15.7184 15.0049 15.3056 15.7629C14.895 16.5197 14.3103 17.1191 13.5548 17.5598C14.5628 17.9619 15.3523 18.5903 15.9232 19.4427L15.9243 19.4415ZM4.93846 12.0383V15.9203H8.36589C10.0456 15.9203 10.885 15.2689 10.885 13.966C10.885 12.6631 10.0456 12.0395 8.36589 12.0395H4.93846V12.0383ZM11.7424 21.9008C11.7424 20.5398 10.8691 19.8593 9.12141 19.8593H4.93846V23.9423H9.12141C10.868 23.9423 11.7424 23.2618 11.7424 21.9008Z" />
      <path d="M32.3918 23.6541V28.0532H18.2311V7.92743H32.065V12.3265H23.1696V15.7193H31.0049V19.9743H23.1696V23.6553H32.3918V23.6541Z" />
      <path d="M34.4376 7.92743H39.426V23.5403H47.8417V28.0544H34.4366V7.92743H34.4376Z" />
      <path d="M60.1826 17.5598H64.5926V25.8978C63.5845 26.7212 62.4258 27.3545 61.1164 27.7953C59.8059 28.236 58.4954 28.4564 57.186 28.4564C55.3375 28.4564 53.6747 28.0108 52.1976 27.1196C50.7195 26.2284 49.5597 24.9885 48.7203 23.3974C47.881 21.8063 47.4619 20.0046 47.4619 17.9909C47.4619 15.9772 47.881 14.1767 48.7203 12.5856C49.5597 10.9945 50.728 9.75339 52.222 8.8634C53.7172 7.97221 55.4054 7.52661 57.2868 7.52661C58.9336 7.52661 60.4118 7.84265 61.7223 8.47593C63.0328 9.108 64.1236 10.0186 64.9969 11.2064L61.8231 14.4842C60.6293 12.9888 59.2021 12.2417 57.5393 12.2417C56.0272 12.2417 54.8091 12.7648 53.8859 13.8098C52.9616 14.8548 52.5011 16.2485 52.5011 17.9921C52.5011 19.1049 52.7102 20.0966 53.1304 20.9684C53.5506 21.8414 54.1384 22.5207 54.895 23.0099C55.6505 23.4991 56.5153 23.7437 57.4895 23.7437C58.4636 23.7437 59.3454 23.5221 60.1847 23.0814V17.5622L60.1826 17.5598Z" />
      <path d="M79.4409 27.1184C77.9713 26.2272 76.8157 24.9873 75.9753 23.3962C75.1359 21.8052 74.7168 20.0034 74.7168 17.9897C74.7168 15.9761 75.1359 14.1755 75.9753 12.5844C76.8146 10.9934 77.9702 9.75223 79.4409 8.86224C80.9106 7.97105 82.5691 7.52545 84.4165 7.52545C86.0294 7.52545 87.4821 7.85238 88.7756 8.50262C90.068 9.15527 91.144 10.0937 92.0014 11.3215L88.8265 14.5981C87.6848 13.0264 86.2979 12.2406 84.6691 12.2406C83.7119 12.2406 82.8588 12.4803 82.1118 12.9598C81.3637 13.4381 80.7843 14.115 80.3726 14.9856C79.9609 15.8586 79.755 16.86 79.755 17.9897C79.755 19.1195 79.9598 20.1221 80.3726 20.9951C80.7832 21.8669 81.3626 22.5426 82.1118 23.0209C82.8577 23.5004 83.7109 23.7401 84.6691 23.7401C86.2979 23.7401 87.6848 22.9543 88.8265 21.3814L92.0014 24.6592C91.144 25.887 90.0691 26.8254 88.7756 27.4768C87.4821 28.1283 86.0294 28.4552 84.4165 28.4552C82.5691 28.4552 80.9106 28.0096 79.4409 27.1184Z" />
      <path d="M97.3823 27.1051C95.8957 26.2042 94.7285 24.9582 93.8806 23.3672C93.0317 21.7761 92.6083 19.9852 92.6083 17.9897C92.6083 15.9942 93.0317 14.2046 93.8806 12.6135C94.7285 11.0224 95.8968 9.77644 97.3823 8.87555C98.869 7.97467 100.544 7.52423 102.409 7.52423C104.273 7.52423 105.949 7.97467 107.435 8.87555C108.922 9.77644 110.088 11.0224 110.937 12.6135C111.785 14.2046 112.209 15.9966 112.209 17.9897C112.209 19.9828 111.785 21.7761 110.937 23.3672C110.088 24.9582 108.921 26.2042 107.435 27.1051C105.949 28.006 104.272 28.4564 102.409 28.4564C100.546 28.4564 98.869 28.006 97.3823 27.1051ZM104.827 23.0221C105.549 22.5438 106.12 21.8669 106.54 20.9963C106.959 20.1233 107.17 19.1219 107.17 17.9909C107.17 16.86 106.959 15.8598 106.54 14.9868C106.12 14.115 105.549 13.4393 104.827 12.961C104.105 12.4815 103.298 12.2418 102.409 12.2418C101.52 12.2418 100.712 12.4815 99.9895 12.961C99.2679 13.4393 98.6971 14.1162 98.2768 14.9868C97.8566 15.8598 97.6476 16.8612 97.6476 17.9909C97.6476 19.1207 97.8566 20.1233 98.2768 20.9963C98.6971 21.8681 99.2679 22.5438 99.9895 23.0221C100.712 23.5016 101.519 23.7413 102.409 23.7413C103.299 23.7413 104.105 23.5016 104.827 23.0221Z" />
      <path d="M130.029 28.0532L129.979 17.3855L125.445 26.0686H123.227L118.717 17.6737V28.0532H114.106V7.92743H118.213L124.412 19.5432L130.46 7.92743H134.567L134.617 28.0532H130.031H130.029Z" />
      <path d="M151.076 27.1184C149.607 26.2272 148.451 24.9873 147.611 23.3962C146.771 21.8052 146.352 20.0034 146.352 17.9897C146.352 15.9761 146.771 14.1755 147.611 12.5844C148.45 10.9934 149.605 9.75223 151.076 8.86224C152.546 7.97105 154.204 7.52545 156.052 7.52545C157.665 7.52545 159.117 7.85238 160.411 8.50262C161.703 9.15527 162.779 10.0937 163.637 11.3215L160.462 14.5981C159.32 13.0264 157.933 12.2406 156.304 12.2406C155.347 12.2406 154.494 12.4803 153.747 12.9598C152.999 13.4381 152.42 14.115 152.008 14.9856C151.596 15.8586 151.39 16.86 151.39 17.9897C151.39 19.1195 151.595 20.1221 152.008 20.9951C152.418 21.8669 152.998 22.5426 153.747 23.0209C154.493 23.5004 155.346 23.7401 156.304 23.7401C157.933 23.7401 159.32 22.9543 160.462 21.3814L163.637 24.6592C162.779 25.887 161.704 26.8254 160.411 27.4768C159.117 28.1283 157.665 28.4552 156.052 28.4552C154.204 28.4552 152.546 28.0096 151.076 27.1184Z" />
      <path d="M168.553 27.8522C167.208 27.4502 166.117 26.9126 165.277 26.243L166.916 22.0449C167.704 22.6394 168.62 23.1177 169.661 23.481C170.703 23.8454 171.728 24.0271 172.736 24.0271C174.65 24.0271 175.607 23.481 175.607 22.3888C175.607 21.8136 175.334 21.3874 174.789 21.1089C174.243 20.8316 173.365 20.5386 172.155 20.231C170.829 19.9053 169.72 19.5566 168.83 19.1812C167.94 18.8083 167.176 18.2089 166.538 17.3843C165.899 16.5609 165.581 15.4493 165.581 14.0484C165.581 12.8217 165.874 11.715 166.462 10.7282C167.05 9.74131 167.928 8.96031 169.095 8.38515C170.262 7.80999 171.695 7.52301 173.392 7.52301C174.55 7.52301 175.692 7.67195 176.818 7.96861C177.943 8.26527 178.934 8.70118 179.791 9.27634L178.255 13.5035C176.574 12.4682 174.945 11.9499 173.366 11.9499C172.375 11.9499 171.653 12.1182 171.199 12.4536C170.746 12.7878 170.519 13.225 170.519 13.7614C170.519 14.2978 170.788 14.701 171.325 14.9686C171.861 15.2374 172.727 15.5147 173.92 15.8029C175.263 16.1286 176.377 16.4773 177.258 16.8527C178.14 17.2257 178.904 17.8202 179.55 18.6339C180.198 19.4488 180.521 20.5555 180.521 21.9553C180.521 23.1625 180.227 24.2559 179.638 25.2331C179.051 26.2103 178.169 26.9913 176.993 27.5749C175.817 28.1598 174.389 28.4528 172.709 28.4528C171.281 28.4528 169.896 28.2518 168.552 27.8486L168.553 27.8522Z" />
      <path d="M72.1287 1.37666C72.6667 1.96151 72.9351 2.67471 72.9351 3.51868C72.9351 4.36265 72.6667 5.09159 72.1287 5.64617C71.5918 6.20196 70.902 6.48045 70.0627 6.48045C69.2233 6.48045 68.5379 6.20196 68.0094 5.64617C67.4799 5.09038 67.2157 4.38203 67.2157 3.51868C67.2157 2.65534 67.4842 1.96151 68.0211 1.37666C68.5591 0.791818 69.2393 0.5 70.0627 0.5C70.8861 0.5 71.5918 0.791818 72.1287 1.37666ZM72.9097 28.0726H67.2157L68.1728 7.92744H71.977L72.9086 28.0726H72.9097Z" />
      <path d="M139.221 34.6233C138.683 34.0385 138.415 33.3253 138.415 32.4813C138.415 31.6373 138.683 30.9084 139.221 30.3538C139.758 29.798 140.448 29.5195 141.287 29.5195C142.127 29.5195 142.812 29.798 143.341 30.3538C143.87 30.9096 144.134 31.618 144.134 32.4813C144.134 33.3447 143.866 34.0385 143.329 34.6233C142.791 35.2082 142.111 35.5 141.287 35.5C140.464 35.5 139.758 35.2082 139.221 34.6233ZM138.44 7.92743H144.134L143.177 28.0726H139.373L138.441 7.92743H138.44Z" />
    </g>
    <defs>
      <clipPath id="clip0_2_17">
        <rect width="195.086" height="35" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </StyledSVG>
);

export default BelgicomicLogo;
