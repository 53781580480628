import React from 'react';

import {
    Title,
} from './StyledStyles';

// Utility function to format and truncate the string
function formatAndTruncate(options, maxLength) {
    if (!options || options.length === 0) return '';

    let result = options.join(' - ');
    if (result.length > maxLength) {
        result = result.substring(0, maxLength - 3) + '...';
    }

    return result;
}

export default function Option({ value }) {

    const formattedOptions = formatAndTruncate(value?.options, 600);

    return (
        <div style={{ marginTop: '10px' }}>
             <Title style={{ fontWeight: 'bold', marginBottom: '10px'}}>Options</Title>
            <Title style={{ fontSize: '14px' }}>{formattedOptions}</Title>
        </div>
    );
}
