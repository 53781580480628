// QwanyxApp.js
import React from 'react';
import { Display, Footer, BottomNavigationBar, IconButton } from '../components';
import scrubbingImage from './Scrubbing.webp'; // Path to your custom image
import scriptImage from './Script.webp'; // Path to your custom image
import outlineImage from './Outline.webp'; // Path to your custom image
import watchImage from './Watch.webp'; // Path to your custom image

const QwanyxApp = () => {
  return (
    <>
      <Display>
        {/* This is where the main content of Display will go */}
        <BottomNavigationBar>
          <IconButton image={watchImage}>Watch</IconButton>
          <IconButton image={scrubbingImage} >Scrub</IconButton>
          <IconButton image={scriptImage} >Script</IconButton>
          <IconButton image={outlineImage}>Outline</IconButton>
        </BottomNavigationBar>
      </Display>
      
      <Footer>
        {/* Footer content */}
      </Footer>
    </>
  );
};

export default QwanyxApp;
