import React from 'react';
import { Box, Container, Grid, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#333333', // dark grey
    color: '#ffffff', // white text
    padding: theme.spacing(4, 0),
}));

const Intro = `Your Gateway to Infinite Productivity.

Here, we're redefining the digital workspace with our innovative "Infinite Desktop," where your ideas, projects, and collaborations know no bounds.

Unleash your full potential in a seamless and interconnected environment, designed to bring out the best in your work and creative endeavors.

Dive into Qwanyx and experience the freedom to create, manage, and collaborate like never before.`

const Footer = () => {
    return (
        <FooterContainer>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom>
                            Welcome to Qwanyx
                        </Typography>
                        <div>
                            {Intro.split('\n\n').map((paragraph, index) => (
                                <Typography key={index} paragraph>
                                    {paragraph}
                                </Typography>
                            ))}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} textAlign="right" >
                        <Stack spacing={1}>
                            <Link href="/login" color="inherit" underline="hover">
                                Login
                            </Link>

                            <Link href="/signup" color="inherit" underline="hover">
                                Sign Up
                            </Link>
                            {/*
                            <Link href="#" color="inherit" underline="hover">
                                Contact
                            </Link>
                            */}
                        </Stack>
                    </Grid>
                </Grid>

                <Box textAlign="center" pt={5} pb={2}>
                    <Typography variant="body2">
                        © {new Date().getFullYear()} Qwanyx. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </FooterContainer>
    );
};

export default Footer;
