import React from 'react';
import {
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ResponsiveColorPicker from '../BelCar/base/ResponsiveColorPicker';

export default function InteriorTrim({ value, onChange }) {
    return (
        <Accordion>
            <AccordionSummary>
                <Typography>Garniture</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ResponsiveColorPicker
                    radio={false}
                    selectionType="single"
                    colors={{
                        Alcantara: '#FFFFFF',
                        Cuir: '#FFFFFF',
                        Velours: '#FFFFFF',
                        Tissu: '#FFFFFF',
                        Autres: '#FFFFFF',
                    }}
                    selectedColor={value}
                    onColorChange={(newColor) => {
                        onChange({ target: { name: 'interiorTrim', value: newColor } });
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
}
