import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function Motorisation({ value, onChange }) {
    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel id="motorisation-label">Motorisation</InputLabel>
            <Select
                name='motorisation'
                label="Motorisation"
                value={value}
                onChange={onChange}
            >
                <MenuItem value="1">Essence</MenuItem>
                <MenuItem value="3">Diesel</MenuItem>
                <MenuItem value="4">Hybride essence</MenuItem>
                <MenuItem value="5">Hybride diesel</MenuItem>
                <MenuItem value="6">Electrique</MenuItem>
                <MenuItem value="7">Hydrogène</MenuItem>
                <MenuItem value="2">LPG</MenuItem>
            </Select>
        </FormControl>
    );
}
