import React, { useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import MlCanvasList from '../MlCanvasList'; // Adjust the import path as needed
import MlTemplateList from '../MlTemplateList'; // Adjust the import path as needed
import MlAnimationList from '../MlAnimationList';
import MlCanVasSetup from '../MlCanvasSetup';
import MlIconTab from './MlIconTab';
import MlCanvasNodeList from '../MlCanvasNodesList';

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import AnimationIcon from '@mui/icons-material/Animation';
import SettingsIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';
//import StorageIcon from '@mui/icons-material/Storage';
//import FingerprintIcon from '@mui/icons-material/Fingerprint';
import TemplateIcon from '@mui/icons-material/LibraryAdd';





const MlCanvasRightPanel = ({
    iconSize = 18,
    editorBgColor = "rgb(250,250,248)",
    borderColor = "rgba(80,80,85,0.4)"
}) => {
    const [value, setValue] = useState(0);
    const handleIconClick = (id) => {
        setValue(id);
        renderTabContent(id);
    };

    const icons = [
        { icon: <HubIcon />, id: 0 },
        { icon: <DesktopWindowsIcon />, id: 1 },
        { icon: <AnimationIcon />, id: 4 },
        { icon: <SettingsIcon />, id: 5 },
        { icon: <TemplateIcon />, id: 6 },
    ];




    const renderTabContent = (tabIndex) => {
        switch (tabIndex) {
            case 0:
                return <MlCanvasList />;
            case 1:
                return <MlCanvasNodeList />;
            case 4:
                return <MlAnimationList />;
            case 5:
                return <MlCanVasSetup />;
            case 6:
                return <MlTemplateList />;
            default:
                return null;
        }
    };

    return (
        <Stack
            sx={{
                height: '100%',
                width: '350px',
                overflow: 'hidden',
                borderLeft: `1px solid ${borderColor}`
            }}
            backgroundColor={editorBgColor}
        >

            <Box sx={{ width: '100%', backgroundColor: 'rgb(225,230,230)' }}>
                <MlIconTab onIconClick={handleIconClick} iconSize={iconSize} color="rgb(80,80,80)" icons={icons} />
            </Box>



            <Box flexGrow={1} width="100%" overflow="auto">
                {renderTabContent(value)}
            </Box>
        </Stack>
    );
};

export default MlCanvasRightPanel;
