import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const TabControl = ({ buttons, onButtonClick, selectedButton }) => {
  return (
    <ButtonGroup size="small" variant="outlined" color="primary" aria-label="outlined primary button group">
      {buttons.map((buttonLabel, index) => (
        <Button
          key={index}
          variant={selectedButton === buttonLabel ? 'contained' : 'outlined'}
          onClick={() => {
            onButtonClick(buttonLabel);
          }}
        >
          {buttonLabel}
        </Button>
      ))}
    </ButtonGroup>
  );
};

TabControl.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.string).isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default TabControl;
