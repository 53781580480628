import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';

const AuthorCard = ({ author }) => {
    const { quanyxStorage } = useMlCanvas();
    const { name, src, link } = author;
    const authorImageUrl = src.startsWith('http') ? src : `${quanyxStorage}${src}`;
    const goCanvas = (uuid) => {
        const domain = window.location.origin; // Dynamically get the domain
        const publicLink = `${domain}/public-canvas/${uuid}`; // Construct the URL with the UUID only
        window.location.href = publicLink;
    };
    const handleClick = () => {
        goCanvas(link);
    };

    return (
        <>
            <ListItem alignItems="flex-start" onClick={handleClick} button>
                <ListItemAvatar>
                    <Avatar 
                        alt={name} 
                        src={authorImageUrl}
                        sx={{ width: 48, height: 48, borderRadius: 2 }} // Set width, height, and borderRadius to make it square
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={name}
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
};

export default AuthorCard;
