// StyledStyles.js
import styled from "styled-components";

export const Carouselframe = styled.div`
  align-self: stretch;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModleMarque = styled.b`
  align-self: stretch;
  position: relative;
`;
export const Garantie = styled.b`
  font-size: 18px;
  align-self: stretch;
  position: relative;
`;
export const Commentaires = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 18px;
  font-weight: 300;
`;
export const SummaryChild = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 0.5px solid #8d8d8d;
  box-sizing: border-box;
  height: 0.5px;
`;
export const Summary = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;
export const RoadIcon = styled.img`
  position: relative;
  width: 19.13px;
  height: 17.94px;
`;
export const Road = styled.div`
  width: 53px;
  height: 53px;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.div`
  position: relative;
`;
export const TitleSmall = styled.div`
  font-size: 16px;
  position: relative;
`;
export const BigBold = styled.div`
  font-size: 50px;
  font-weight: bold;
  position: relative;
`;
export const Value = styled.div`
  position: relative;
  color: #000;
`;
export const Text1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;
export const Infowithicon1 = styled.div`
  width: 220px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
`;
export const TransmissionIcon = styled.img`
  position: relative;
  width: 20.77px;
  height: 18.29px;
`;
export const Transmission = styled.div`
  width: 53px;
  height: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const DateIcon = styled.img`
  position: relative;
  width: 17.99px;
  height: 18.25px;
`;
export const FuelIcon = styled.img`
  position: relative;
  width: 24.7px;
  height: 14.7px;
`;
export const GaugeIcon = styled.img`
  position: relative;
  width: 26.59px;
  height: 18.41px;
`;
export const UserIcon = styled.img`
  position: relative;
  width: 18.52px;
  height: 18.09px;
`;
export const Infos = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
`;
export const Infoframe = styled.div`
  align-self: stretch;
  border: 0.5px solid #8d8d8d;
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  color: #8d8d8d;
`;
export const Tvacomprise = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: 10px;
  font-weight: 300;
  font-family: Inter;
  color: #000;
  text-align: left;
  display: inline-block;
`;
export const Pricetva = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

export const Price = styled.div`
  width: 184px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const Type = styled.div`
  position: relative;
  font-size: 9px;
  font-weight: 300;
  font-family: Inter;
  color: #000;
  text-align: left;
`;

export const Foot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 16px;
`;
export const CardetailRoot = styled.div`
  border-radius: 8px;
  background-color: #f5f5f5;
  width: ${props => (props.isExporting ? '2480px' : '100%')};
  height: ${props => (props.isExporting ? '3507px' : 'auto')};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: 24px;
  color: #000;
  font-family: Inter;
`;
