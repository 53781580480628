export function getOptionsData() {
    return [
        "360° caméra",
        "4x4",
        "ABS",
        "Accoudoir",
        "Affichage tete haute",
        "Airbag arrière",
        "Airbag avant",
        "Airbag conducteur",
        "Airbag passager",
        "Airbags latéraux",
        "Alarme",
        "Alerte de franchissement involontaire de lignes",
        "Android Auto",
        "Anti-démarrage",
        "Anti-patinage",
        "Apple CarPlay",
        "Assistant au frèinage d'urgence",
        "Assistant de démarrage en côte",
        "Assistant de vision nocturne",
        "Assistant feux de route",
        "Attache remorque",
        "Auvent",
        "Avertisseur d'angle mort",
        "Bluetooth",
        "Camera d'aide au stationnement",
        "Capteurs d'aide au stationnement arriere",
        "Capteurs d'aide au stationnement avant",
        "CD",
        "Chargeur smartphone a induction",
        "Chauffage auxiliaire",
        "Climatisation",
        "Climatisation automatique",
        "Climatisation automatique, 3 zones",
        "Climatisation automatique, 4 zones",
        "Climatisation automatique, bi-zone",
        "Commande vocale",
        "Compatible E -10",
        "Conduite a droite",
        "Coupe vent (pour cabriolet)",
        "Déflecteur",
        "Détecteur de lumiere",
        "Détecteur de pluie",
        "Détection des panneaux routiers",
        "Direction assistee",
        "Dispositif mains libres",
        "Eclairage d'ambiance",
        "Ecran multifonction entièrement numerique",
        "Ecran tactile",
        "Equipement handicape",
        "ESP",
        "Feux anti-brouillard",
        "Feux de route non éblouissants",
        "Fonction TV",
        "Fonctionne au biodiesel",
        "Frein de stationnement électronique",
        "Hayon arriere électrique",
        "Hotspot Wi-Fi",
        "Isofix",
        "Jantes acier",
        "Jantes alliage",
        "Kit de depannage",
        "Kit fumeur",
        "LED phare de jour",
        "Limiteur de vitesse",
        "MP3",
        "Ordinateur de bord",
        "Pack hiver",
        "Pack Sport",
        "Palettes de changement de vitesses",
        "Pare-brise chauffant",
        "Phares au LED",
        "Phares au Xenon",
        "Phares bi-xenon",
        "Phares de jour",
        "Phares directionnels",
        "Phares Full LED",
        "Phares laser",
        "Pneus ete",
        "Pneus neige",
        "Pneus tout temps saisons",
        "Porte coulissante",
        "Porte coulissante droite",
        "Porte coulissante gauche",
        "Porte-bagages",
        "Pot catalytique",
        "Prolongateur d'autonomie",
        "Radar de recul",
        "Radio",
        "Radio numerique",
        "Règlage electrique du siege arriere",
        "Régulateur de distance",
        "Régulateur de vitesse",
        "Réetroviseur interieur anti-éblouissement automatique",
        "Rétroviseur rabatable électrique",
        "Rétroviseurs lateraux électriques",
        "Roue de secours",
        "Roue de urgence",
        "Sellerie cuir",
        "Separateur pour coffre",
        "Siège a reglage lombaire",
        "Siège passager repliable",
        "Sièges arrieres 1/3 - 2/3",
        "Sièges chauffants",
        "Sièges electriques",
        "Sièges massants",
        "Sièges sport",
        "Sièges ventiles",
        "Sound system",
        "Start/Stop automatique",
        "Streaming audio integre",
        "Suspension pneumatique",
        "Suspension sport",
        "Système d'aide au stationnement automatique",
        "Système d'appel d'urgence",
        "Système d'avertissement de distance",
        "Système de controle de la pression pneus",
        "Système de detection de la somnolence",
        "Système de navigation",
        "Système de nettoyage des phares",
        "Taxi ou voiture de location",
        "Toit ouvrant",
        "Toit panoramique",
        "Trappe a ski",
        "Tuning",
        "USB",
        "Verrouillage centralise",
        "Verrouillage centralise avec telecommande",
        "Verrouillage centralise sans cle",
        "Vitres électriques",
        "Vitres teintées",
        "Volant chauffant",
        "Volant en cuir",
        "Volant multifonctions"
    ];
}
