// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import mainListBoardReducer from './mainListBoardSlice';
import selectedNodesReducer from './selectedNodesSlice'; // Import the selectedNodesReducer
import nodeMapper from '../models/NodeMapper'; // Make sure to import the nodeMapper

// Custom middleware
const dbMiddleware = store => next => action => {
  let result = next(action); // first update the state
  let state = store.getState(); // get the updated state
  nodeMapper.updateNodeObjects(".defaultMainListBoard", {defaultMainListBoard: state.mainListBoard}); // save to db
  return result;
};

export default configureStore({
  reducer: {
    mainListBoard: mainListBoardReducer,
    selectedNodes: selectedNodesReducer, // Add the selectedNodesReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dbMiddleware),
});
