import React, { useState, useEffect } from 'react';
import {
    Box,
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    CircularProgress,
    Modal
} from '@mui/material';

import MlFileUploader from './MlFileUploader'; // Adjust the import path as necessary
import { uploadFileToServer, getUserId } from '../../../MlModels/MlNode';

const USER_ID = getUserId();

function MlNodePictureEditor({ onSave, initialPictureValues = {} }) {
    const [isLoading, setIsLoading] = useState(false);
    const getDefaultValues = (values) => ({
        imageLink: values.imageLink || '',
        imageSize: values.imageSize || '',
        showImage: values.showImage || false,
    });

    const [nodePictureSettings, setNodePictureSettings] = useState(getDefaultValues(initialPictureValues));

    const handleChange = (field, value) => {
        setNodePictureSettings(prevSettings => ({
            ...prevSettings,
            [field]: value
        }));
    };

    useEffect(() => {
        onSave(nodePictureSettings);
    }, [nodePictureSettings, onSave]);


    const handleFileUploadComplete = (fileContent) => {
        // Split the input string by newlines to process each link separately
        const fileNames = fileContent.split('\n');
    
        // Process each link with cache busting
        const processedLinks = fileNames.map(fileName => {
            // If fileName is not empty, apply cache busting
            if (fileName.trim() !== '') {
                return "/" + fileName + "?" + getVersionString();
            }
            return ''; // Return an empty string for empty lines
        });
    
        // Join the processed links back into a single string with newlines
        const cacheBustedLinks = processedLinks.join('\n');
    
        // Update state or handle the cache busted links as needed
        handleChange('imageLink', cacheBustedLinks);
    };

    const handleDeleteImage = async () => {
        setIsLoading(true);
        // Implement deletion logic here
        setIsLoading(false);
    };

    const LoadingModal = (props) => (
        <Modal open={props.open} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Modal>
    );

    function getVersionString() {
        const seconds = Math.floor(new Date().getTime() / 1000);
        return `v=${seconds}`;
    }

    return (
        <Box sx={{ padding: 5, width: '100%' }}>
            <LoadingModal open={isLoading} />
            <Stack spacing={2} alignItems="flex-start">
                <MlFileUploader
                    onUpload={handleFileUploadComplete}
                    folderPath={`IMAGES/upload/`}
                    userId={USER_ID}
                    nodeId={initialPictureValues._id.toString()}
                    accept="image/*"
                />

                <TextField
                    size="small"
                    label="Image Size"
                    variant="outlined"
                    value={nodePictureSettings.imageSize}
                    onChange={e => handleChange('imageSize', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={nodePictureSettings.showImage}
                            onChange={e => handleChange('showImage', e.target.checked)}
                        />
                    }
                    label="Show Image"
                />
                <TextField
                    size="small"
                    label="Image URL"
                    variant="outlined"
                    multiline
                    minRows={3}
                    value={nodePictureSettings.imageLink}
                    onChange={e => handleChange('imageLink', e.target.value)}
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-inputMultiline': {
                            whiteSpace: 'nowrap',
                            overflowX: 'auto'
                        }
                    }}
                />

                <Button onClick={handleDeleteImage} color="primary">
                    Delete Image
                </Button>
            </Stack>
        </Box>
    );
}

export default MlNodePictureEditor;
