import React from 'react';
import {
  Stack,
  Box,
  Typography,
} from '@mui/material';
import {
  Title,
  Infoframe as InfoframeWrapper,
  Price,
  Pricetva,
  Tvacomprise,
  BigBold,
} from './StyledStyles'; 
import {
  formatPrice,
  formatPriceVat,
  formatTitle,
  printComponentAsJpg,
  getListWithReturn,
  ColorDetails,
  formatWarranty
} from './AutodinUtils';
import ImageCanvas from '../../ImageEditorComponent/ImageCanvas';
import VehicleSummary from './VehicleSummary';
import InfoSeller from './InfoSeller';
import OptionPrint from './OptionPrint';
import TextWithTitle from './TextWithTitle';
import ImagePlaceHolder from '../../ImageEditorComponent/Vehicle.webp';

const VehiclePoster = React.forwardRef(({ vehicleData, logo = '' }, ref) => {

  const getFirstImageLink = () => {
    const imageLink = vehicleData?.imageLink;
    const firstImageLink = imageLink ? imageLink.split('\n')[0] : null;
    return firstImageLink ? `${process.env.REACT_APP_S3_URL_BASE}/${firstImageLink}` : ImagePlaceHolder;
  };

  if (!vehicleData) {
    return null; // Or return a loading state
  }

  return (
    <Stack
      ref={ref}
      gap={1}
      justifyContent='center'
      alignItems='center'
      sx={{ backgroundColor: '#ffffff' }}
      padding={3}
    >
      <Box mt={3} mb={3}>
        <ImageCanvas src={logo} height='5rem' backgroundColor='#ffffff' />
      </Box>
      <ImageCanvas src={getFirstImageLink()} height='250px' backgroundColor='#ffffff' />
      <BigBold>
        {formatTitle(vehicleData?.brand, vehicleData?.model)}
      </BigBold>
      
      <VehicleSummary vehicleData={vehicleData} />  
      
      <Box mt={2} mb={1}>
        <Stack direction='row' gap={10} alignItems='center'>
          <Box>
            <Price>
              <Pricetva>
                <Title>€</Title>
                <Title>{formatPrice(vehicleData.price)} - </Title>
                {vehicleData.taxDeductible ? <Tvacomprise>Tva comprise</Tvacomprise> : <Tvacomprise>TTC</Tvacomprise>}
              </Pricetva>
              {vehicleData.taxDeductible &&
                <Pricetva>
                  <Title>€</Title>
                  <Title>{formatPriceVat(vehicleData.price)} - </Title>
                  <Tvacomprise>Hors TVA</Tvacomprise>
                </Pricetva>
              }
            </Price>
          </Box>
          <InfoSeller/>
        </Stack>
      </Box>
      {vehicleData?.warranties &&
        <Box width='100%'>
          <Title>{formatWarranty(vehicleData.warranties)}</Title>
          <TextWithTitle title='Commentaires' text={vehicleData?.comments} />
          <OptionPrint value={vehicleData} />
        </Box>
      }
    </Stack>
  );
});

export default VehiclePoster;
