import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { useEmotion } from './EmotionContext';

const Playhead = ({ playheadWidth = 3 }) => {
  const { selectedTime, setSelectedTime, setIsScrubbing } = useEmotion();
  const [position, setPosition] = useState(selectedTime);
  const playheadRef = useRef(null);
  const isDragging = useRef(false);

  useEffect(() => {
    if (!isDragging.current) {
      setPosition(selectedTime); // Sync position with selectedTime
    }
  }, [selectedTime]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!isDragging.current || !playheadRef.current) return;

      const containerRect = playheadRef.current.parentElement.getBoundingClientRect();
      const newPosition = Math.min(Math.max((event.clientX - containerRect.left) / (containerRect.width - playheadWidth), 0), 1);

      setPosition(newPosition);
      setSelectedTime(newPosition); // Update the selectedTime in the context
    };

    const handleMouseUp = () => {
      isDragging.current = false;
      setIsScrubbing(false); // User stopped scrubbing
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [playheadWidth, setSelectedTime, setIsScrubbing]);

  const handleMouseDown = () => {
    isDragging.current = true;
    setIsScrubbing(true); // User started scrubbing
  };

  return (
    <Box
      ref={playheadRef}
      onMouseDown={handleMouseDown}
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: `${position * 100}%`,
        width: `${playheadWidth}px`,
        backgroundColor: 'red',
        cursor: 'ew-resize',
        userSelect: 'none',
      }}
    />
  );
};

export default Playhead;
