import React from 'react';
import styled from 'styled-components';
import Chart from './Chart';
import { EmotionProvider } from './EmotionContext';
import AnimationIcons from './AnimationIcons';
import VideoPlayer from './VideoPlayer';
import EmotionGrid from './EmotionGrid';
import EpisodeMenu from './Menu';
// Styled components
const EmotionAnalysisContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const DisplayArea = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
`;

const Zone = styled.div`
  flex: 1;
  position: relative;  /* Enable absolute positioning within this zone */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
`;

const Timeline = styled.div`
  width: 100%;
  height: 30px;
  background-color: #f0f0f0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GraphZone = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
`;

const GraphArea = styled.div`
  width: 100%;
  height: 100px;
  background-color: #e0e0e0;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
`;

const EmotionBoard = () => {
  return (
    <EmotionProvider>
      <EmotionAnalysisContainer>
        <DisplayArea>
          <Zone>
            <MenuWrapper>
              <EpisodeMenu onEpisodeSelect={(episode) => console.log(`Selected episode: ${episode}`)} />
            </MenuWrapper>
            <VideoPlayer source='eposide' />
          </Zone>
          <Zone>
            <VideoPlayer source='subject' />
          </Zone>
          <Zone>
            <EmotionGrid width='100%' height='75%' />
          </Zone>
        </DisplayArea>
        <Timeline>
          <AnimationIcons />
        </Timeline>
        <GraphArea>
          <Chart />
        </GraphArea>
      </EmotionAnalysisContainer>
    </EmotionProvider>
  );
};

export default EmotionBoard;
