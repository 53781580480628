import React, { useState, useEffect } from 'react';
import { Box, IconButton, Stack, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import UpdateIcon from '@mui/icons-material/SystemUpdateAlt';
import Rename from '@mui/icons-material/DriveFileRenameOutline';


function MlAnimationButtons({ fill = "rgb(200,200,200)", iconSize = 24 }) {
  const muiFontSize = iconSize + 7;

  const {
    deleteCurrentAnimationTarget,
    resetAnimation,
    addAnimationTarget,
    updateCurrentAnimationTarget,
    renameCurrentAnimationTarget,
  } = useMlCanvas();

  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [newName, setNewName] = useState('');

  const handleRenameClick = () => {
    setOpenRenameDialog(true);
  };

  const handleRenameClose = () => {
    setOpenRenameDialog(false);
    setNewName(''); // Reset newName state
  };

  const handleRenameConfirm = () => {
    renameCurrentAnimationTarget(newName);
    handleRenameClose();
  };

  const resetAllTargets = () => {
    resetAnimation();
  };

  const deleteTarget = () => {
    deleteCurrentAnimationTarget();
  };

  const addTarget = () => {
    addAnimationTarget();
  };

  const updateTarget = () => {
    updateCurrentAnimationTarget();
  };


  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Stack direction="row">
        <IconButton onClick={addTarget} >
          <CircleIcon style={{ fontSize: muiFontSize, color: fill }} />
        </IconButton>
        <IconButton onClick={updateTarget} >
          <UpdateIcon style={{ fontSize: muiFontSize, color: fill }} />
        </IconButton>
        <IconButton onClick={handleRenameClick} >
          <Rename style={{ fontSize: muiFontSize, color: fill }} />
        </IconButton>
        <IconButton onClick={deleteTarget} >
          <DeleteIcon style={{ fontSize: muiFontSize, color: fill }} />
        </IconButton>
        <IconButton onClick={resetAllTargets} >
          <DeleteSweepIcon style={{ fontSize: muiFontSize + 5, color: fill }} />
        </IconButton>
        {/* Dialog Component for Renaming */}
        <Dialog open={openRenameDialog} onClose={handleRenameClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Rename Target</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Name"
              type="text"
              fullWidth
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRenameClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleRenameConfirm} color="primary">
              Rename
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
}

export default MlAnimationButtons;