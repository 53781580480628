import React from 'react';
import { IconButton, Stack } from '@mui/material';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';

function MlAnimationButtons(props) {
  const {
    fill = "rgb(200,200,200)",
    iconSize = 24,
    showNavigation = true,
    showPlayStop = false,
  } = props;

  const muiFontSize = iconSize * 2;

  const {
    goTarget,
    deleteCurrentAnimationTarget,
    resetAnimation,
    addAnimationTarget,
    playCanvas,
    stopCanvas,
    isPlaying,
  } = useMlCanvas();

  const NextPrevious = ({ size = iconSize, fillColor = fill }) => (
    <svg width={size} height={size} viewBox="0 0 22.94 40.01">
      <path fill={fillColor} d="M22.94,37.07V2.94c0-2.62-3.16-3.93-5.01-2.08L.86,17.93c-1.15,1.15-1.15,3.01,0,4.15l17.06,17.06c1.85,1.85,5.01.54,5.01-2.08Z" />
    </svg>
  );

  const StartEnd = ({ size = iconSize, fillColor = fill }) => (
    <svg width={size} height={size} viewBox="0 0 31.37 40.01">
      <path fill={fillColor} d="M31.37,37.07V2.94c0-2.62-3.16-3.93-5.01-2.08L9.29,17.93c-1.15,1.15-1.15,3.01,0,4.15l17.06,17.06c1.85,1.85,5.01.54,5.01-2.08Z" />
      <rect fill={fillColor} width="8.43" height="40.01" />
    </svg>
  );

  const goFirstTarget = () => {
    goTarget('first');
  };

  const goLastTarget = () => {
    goTarget('last');
  };

  const goToPreviousTarget = () => {
    goTarget('previous');
  };

  const goToNextTarget = () => {
    goTarget('next');
  };

  const resetAllTargets = () => {
    resetAnimation();
  };

  const deleteTarget = () => {
    deleteCurrentAnimationTarget();
  };

  const addTargetBefore = () => {
    addAnimationTarget("before");
  };

  const addTargetAfter = () => {
    addAnimationTarget("after");
  };

  const handlePlay = () => {
    playCanvas();
  };

  const handleStop = () => {
    stopCanvas();
  };

  return (
    <Stack direction="row">
      {showNavigation && (
        <>
          <IconButton onClick={goFirstTarget}>
            <StartEnd />
          </IconButton>
          <IconButton onClick={goToPreviousTarget}>
            <NextPrevious />
          </IconButton>
        </>
      )}
      {showPlayStop && (
        <>
          {!isPlaying ? (
            <IconButton onClick={handlePlay}>
              <PlayArrowRoundedIcon sx={{ fontSize: muiFontSize, fill }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleStop}>
              <StopRoundedIcon sx={{ fontSize: muiFontSize, fill }} />
            </IconButton>
          )}
        </>
      )}
      {showNavigation && (
        <>
          <IconButton onClick={goToNextTarget} sx={{ transform: 'rotate(180deg)' }}>
            <NextPrevious />
          </IconButton>
          <IconButton onClick={goLastTarget} sx={{ transform: 'rotate(180deg)' }}>
            <StartEnd />
          </IconButton>
        </>
      )}
    </Stack>
  );
}

export default MlAnimationButtons;
