import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonGroup, Stack, Typography, TextField, Select, MenuItem, Menu, IconButton } from '@mui/material';
//import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import MlNodesList from '../MlNodesList';
import DataSettings from './DataSettings';
import Forms from './Forms';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MlC from '../../base/MlComponents';
import {
    nodeGet
} from '../../../MlModels/MlNode'
//TODO: transmit the componentValues to the textEditor to be saved
//but also get the properties to rebuild the editor when we mount it
const MlNodeTypeEditor = ({ onSave, initialComponentValues }) => {

    const [components, setComponents] = useState([]);
    const [componentValues, setComponentValues] = useState({});
    const [activePage, setActivePage] = useState('Forms');
    const [typeName, setTypeName] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    //const componentValuesString = JSON.stringify(componentValues, null, 2);


    useEffect(() => {
        if (initialComponentValues) {
            setComponentValues(initialComponentValues);
            setComponents(Object.keys(initialComponentValues));
        }
    }, []);

    const handleDeleteComponent = (componentType) => {
        const updatedComponents = components.filter((comp, index, self) => self.indexOf(comp) === index);
        setComponents(updatedComponents.filter(comp => comp !== componentType));

        const updatedComponentValues = { ...componentValues };
        delete updatedComponentValues[componentType];
        setComponentValues(updatedComponentValues);

        onSave(updatedComponentValues);
    };


    const handleComponentValueChange = (propertyName, newValue) => {
        // propertyName is assumed to be in the format "Label_ComponentType"
        // We update the componentValues state by setting the value directly for the propertyName
        const newValues = { ...componentValues, [propertyName]: newValue };
        setComponentValues(newValues);
        onSave(newValues); // Assuming onSave should also save the data in the same structure
    };

    const handlePageSwitch = (page) => {
        setActivePage(page);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setMlNode = async (nodeId) => {
        const newValues = await nodeGet(nodeId);
        onSave(newValues); // Assuming this updates some global state or context
        setComponentValues(newValues); // Updates local state to reflect changes
    };

    const handleAddComponent = (componentType) => {
        if (!components.includes(componentType)) {
            const defaultLabel = componentType + ' Label';
            const formattedLabel = defaultLabel.replace(/\s+/g, '-');
            const propertyName = `${formattedLabel}_${componentType}`;

            setComponents(oldComponents => [...oldComponents, propertyName]);
            setComponentValues(oldValues => {
                const newValues = { ...oldValues, [propertyName]: '' };
                onSave(newValues);  // Call onSave here with newValues
                return newValues;
            });
        }
        handleClose();
    };

    const handleLabelChange = (oldPropertyName, newLabel) => {
        const [oldLabel, componentType] = oldPropertyName.split('_');
        const newPropertyName = `${newLabel.replace(/\s+/g, '-')}_${componentType}`;

        // Update components array
        const newComponents = components.map(propertyName =>
            propertyName === oldPropertyName ? newPropertyName : propertyName
        );

        // Update componentValues
        const newValue = componentValues[oldPropertyName];
        const newComponentValues = { ...componentValues, [newPropertyName]: newValue };
        delete newComponentValues[oldPropertyName];

        setComponents(newComponents);
        setComponentValues(newComponentValues);
        onSave(newComponentValues); // Assuming onSave updates the state and/or sends data to a backend
    };

    const type = componentValues.type_TextProperty;
    const identity = componentValues.identity_UUIDProperty;

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 1 }}>
                <ButtonGroup size="small" aria-label="small button group">
                    {['Forms', 'Detail', 'List', 'Card', 'Settings'].map((page) => (
                        <Button
                            key={page}
                            onClick={() => handlePageSwitch(page)}
                            sx={{
                                color: activePage === page ? 'white' : 'black',
                                borderColor: 'black',
                                backgroundColor: activePage === page ? 'black' : 'white',
                                '&:hover': {
                                    backgroundColor: activePage === page ? 'black' : 'rgba(0, 0, 0, 0.04)',
                                    borderColor: activePage === page ? 'black' : 'rgba(0, 0, 0, 0.23)',
                                    color: activePage === page ? 'white' : 'black',
                                },
                            }}
                        >
                            {page}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>

            {activePage === 'Settings' && (
                <DataSettings
                    components={components}
                    componentValues={componentValues}
                    MlC={MlC}
                    onAddComponent={handleAddComponent} // Note the prop name change to match DataSettings
                    onComponentValueChange={handleComponentValueChange} // Name change for clarity
                    onLabelChange={handleLabelChange} // Name change for clarity
                    onDeleteComponent={handleDeleteComponent} // Name change for clarity
                    onMenuClick={handleClick} // Adjusting prop name for clarity
                    onMenuClose={handleClose} // Adjusting prop name for clarity
                    anchorEl={anchorEl}
                />
            )}

            {activePage === 'Forms' && (
                <Forms
                    components={components}
                    componentValues={componentValues}
                    MlC={MlC}
                    onAddComponent={handleAddComponent} // Note the prop name change to match DataSettings
                    onComponentValueChange={handleComponentValueChange} // Name change for clarity
                    onLabelChange={handleLabelChange} // Name change for clarity
                    onDeleteComponent={handleDeleteComponent} // Name change for clarity
                    onMenuClick={handleClick} // Adjusting prop name for clarity
                    onMenuClose={handleClose} // Adjusting prop name for clarity
                    anchorEl={anchorEl}
                />
            )}
            {activePage === 'List' && (
                <MlNodesList
                    type={type}
                    identity={identity}
                    setMlNode={setMlNode}
                />
            )}
        </div>
    );
};

export default MlNodeTypeEditor;
