import React from 'react';
import Box from '@mui/material/Box';

const MlImage = ({ src, alt, width = '1rem', height = 'auto', mt = 0 }) => {
  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        width: width, // Adjust the width as needed
        height: height, // Maintain aspect ratio
        mt: mt // Add margin on top
      }}
    />
  );
};

export default MlImage;
