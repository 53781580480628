import React, { useState, useEffect } from 'react';
import {
    Box,
    Stack,
    FormControlLabel,
    Checkbox,
    TextField,
    Switch,
    Button
} from '@mui/material';

const MlNodeDisplayEditor = ({ onSave, initialDisplayValues = {} }) => {
    const getDefaultValues = (values) => ({
        displayName: values.displayName ?? true,
        displayIcon: values.displayIcon ?? true,
        linkIcon: values.linkIcon || '',
        linkIconSize: values.linkIconSize || '24',
        soundIconSize: values.soundIconSize || '24',
        linkIconColor: values.linkIconColor || '0,0,0',
        backgroundColor: values.backgroundColor || '250,250,248,1',
        linkIconAlpha: values.linkIconAlpha || '1',
        elevation: values.elevation || '2',
        justification: values.justification,
        nameSize: values.nameSize
    });



    const [nodeDisplaySettings, setNodeDisplaySettings] = useState(getDefaultValues(initialDisplayValues));

    const handleChange = (field, value) => {
        setNodeDisplaySettings(prevSettings => ({
            ...prevSettings,
            [field]: value
        }));
    };

    useEffect(() => {
        onSave(nodeDisplaySettings);
    }, [nodeDisplaySettings, onSave]);

    const applySimpleLayout = () => {
        setNodeDisplaySettings({
            ...nodeDisplaySettings,
            backgroundColor: '255,255,255,0', // Example value
            elevation: '0', // Example value
            displayIcon: false,
        });
    };


    return (
        <Box sx={{ padding: 5, width: '100%' }}>
            <Stack spacing={2} alignItems="flex-start">
                <Button variant="outlined" onClick={applySimpleLayout} sx={{ mb: 2 }}>
                    Apply Default Layout
                </Button>
                <FormControlLabel
                    control={<Switch size="small" checked={nodeDisplaySettings.displayName} onChange={e => handleChange('displayName', e.target.checked)} />}
                    label="Display Name"
                />
                <TextField
                    size="small"
                    label="Name Size"
                    variant="outlined"
                    value={nodeDisplaySettings.nameSize}
                    onChange={e => handleChange('nameSize', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <TextField
                    size="small"
                    label="Background Color"
                    variant="outlined"
                    value={nodeDisplaySettings.backgroundColor}
                    onChange={e => handleChange('backgroundColor', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <TextField
                    size="small"
                    label="Elevation"
                    variant="outlined"
                    value={nodeDisplaySettings.elevation}
                    onChange={e => handleChange('elevation', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <FormControlLabel
                    control={<Switch checked={nodeDisplaySettings.displayIcon} onChange={e => handleChange('displayIcon', e.target.checked)} size="small" />}
                    label="Display Icon"
                />
                <TextField
                    size="small"
                    label="Link Icon"
                    variant="outlined"
                    value={nodeDisplaySettings.linkIcon}
                    onChange={e => handleChange('linkIcon', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <TextField
                    size="small"
                    label="Link Icon Size"
                    variant="outlined"
                    value={nodeDisplaySettings.linkIconSize}
                    onChange={e => handleChange('linkIconSize', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <TextField
                    size="small"
                    label="Link Icon Color"
                    variant="outlined"
                    value={nodeDisplaySettings.linkIconColor}
                    onChange={e => handleChange('linkIconColor', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <TextField
                    size="small"
                    label="Link Icon Alpha"
                    variant="outlined"
                    value={nodeDisplaySettings.linkIconAlpha}
                    onChange={e => handleChange('linkIconAlpha', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <TextField
                    size="small"
                    label="Sound Icon Size"
                    variant="outlined"
                    value={nodeDisplaySettings.soundIconSize}
                    onChange={e => handleChange('soundIconSize', e.target.value)}
                    sx={{ width: '100%' }}
                />
                <TextField
                    size="small"
                    label="Justification"
                    variant="outlined"
                    value={nodeDisplaySettings.justification}
                    onChange={e => handleChange('justification', e.target.value)}
                    sx={{ width: '100%' }}
                />
            </Stack>
        </Box>
    );
};

export default MlNodeDisplayEditor;
