import { ObjectId } from 'bson';
import app from '../realm-config'; 
import { REALM_APP_ID } from '../realm-config';
import Node from './Node';

class NodeMapper {

  getUserId() {
    return app.currentUser?.id || null;
  }

  instantiateNode(nodeData) {
    return nodeData instanceof Node ? nodeData : new Node(nodeData);
  }

  instantiateNodes(nodesData) {
    return nodesData.map(nodeData => this.instantiateNode(nodeData));
  }

  instantiateNodesFromAPI(nodesData) {
    if (Array.isArray(nodesData)) {
      return nodesData.map(nodeData => this.instantiateNode(nodeData.node));
    } else {
      return this.instantiateNode(nodesData.node);
    }
  }

  async getNodeByObjectId(objectId) {
    try {
      const nodeData = await this.nodesCollection().findOne({ _id: new ObjectId(objectId) });
      return this.instantiateNode(nodeData);
    } catch (error) {
      console.error('Error getting node by ObjectId:', error);
      return null;
    }
  }

  nodesCollection() {
    return app.currentUser.mongoClient("mongodb-atlas").db("mainlistdb").collection("nodes");
  }

  sharedQuery(userId) {
    return {
      $or: [
        { userId: userId },
        { sharedWith: { $in: [userId] } }
      ]
    };
  }

  async fetchNodeByTitle(title, userId = this.getUserId()) {
    try {
      const query = {
        title: title,
        ...this.sharedQuery(userId)
      };

      const nodeData = await this.nodesCollection().findOne(query);

      if (!nodeData) return null;  // Return null if no node found

      return new Node(nodeData);  // Convert the data into a Node instance
    } catch (error) {
      console.error("Error fetching node by title:", error);
      return null;
    }
  }

  async fetchNodes(parentId, userId = this.getUserId(), objectType = null) {
    try {
      let query;

      if (userId === 'all') {
        query = {
          parentId: parentId === null ? null : new ObjectId(parentId),
        };
      } else {
        query = {
          ...this.sharedQuery(userId),
          parentId: parentId === null ? null : new ObjectId(parentId),
        };
      }

      // Check if objectType is provided and adjust query accordingly
      if (objectType) {
        query['nodeObjects.' + objectType] = { $exists: true };
      }

      const nodesData = await this.nodesCollection().find(query);
      const theData = this.instantiateNodes(nodesData);
      return theData;
    } catch (error) {
      console.error("Error fetching nodes:", error);
      return [];
    }
  }

  async fetchAllDescendants(nodeId) {
    let descendants = [];
    const immediateChildren = await this.fetchNodes(nodeId);
    for (let child of immediateChildren) {
      descendants.push(child);
      const childDescendants = await this.fetchAllDescendants(child._id.toString());
      descendants = descendants.concat(childDescendants);
    }
    return descendants;
  }

  async getNodesByName(name, userId = this.getUserId()) {
    try {
      const query = {
        title: name,
        ...this.sharedQuery(userId),
      };
      const nodesData = await this.nodesCollection().find(query);
      return this.instantiateNodes(nodesData);
    } catch (error) {
      console.error("Error fetching nodes by name:", error);
      return [];
    }
  }

  async create(node, userId = this.getUserId()) {
    try {
      node = this.instantiateNode(node);  // Make sure 'node' is a Node instance

      node._id = node._id || new ObjectId();
      node.parentId = node.parentId ? new ObjectId(node.parentId) : null;
      node.userId = userId; // set user ID to provided ID or current user

      const result = await this.nodesCollection().insertOne(node);
      console.log('CreateNode result:', result);
      if (result.insertedId) {
        return node;  // 'node' is already a Node instance, so just return it
      } else {
        throw new Error('Insert failed');
      }
    } catch (error) {
      console.error('Error creating node:', error);
      return null;
    }
  }


  async createNodes(titles, parentId = null) {
    const createdNodes = [];

    for (let title of titles) {
      const newNode = this.instantiateNode({ title, parentId, userId: this.getUserId() });
      const result = await this.create(newNode);
      if (result) {
        createdNodes.push(result);
      }
    }
    return createdNodes;
  }

  async delete(node, recursive = false) {
    try {
      if (recursive) {
        // Find and delete all child nodes
        const childNodes = await this.nodesCollection().find({ parentId: node._id });
        for (const childNode of childNodes) {
          await this.delete(childNode, true);
        }
      }

      // Delete the node itself
      const result = await this.nodesCollection().deleteOne({ _id: new ObjectId(node._id) });
      if (result.deletedCount) {
        return true;
      } else {
        throw new Error('Deletion failed');
      }
    } catch (error) {
      console.error('Error deleting node:', error);
      return false;
    }
  }


  async update(node) {
    try {
      node = this.instantiateNode(node);  // Make sure 'node' is a Node instance
      const { _id, ...updateValues } = node;

      // If parentId is being updated, perform the loop checks:
      if (updateValues.parentId) {
        if (_id.toString() === updateValues.parentId.toString()) {
          throw new Error('A node cannot be its own parent.');
        }

        // Fetch descendants to check if the new parent is a descendant of the current node.
        const descendants = await this.fetchAllDescendants(_id.toString());
        const isDescendant = descendants.some(descendant => descendant._id.toString() === updateValues.parentId.toString());
        if (isDescendant) {
          throw new Error('Cannot set a descendant as parent.');
        }
      }

      const result = await this.nodesCollection().updateOne({ _id: new ObjectId(_id) }, { $set: updateValues }, { upsert: true });
      if (result.matchedCount > 0 || result.upsertedId != null) {
        return node;
      } else {
        throw new Error('Update failed');
      }
    } catch (error) {
      console.error('Error updating node:', error.message);
      return null;
    }
  }


  async updateNodes(nodes) {
    try {
      const updatedNodes = [];
      for (let node of nodes) {
        const updatedNode = await this.update(node);
        if (updatedNode) {
          updatedNodes.push(updatedNode);
        }
      }
      return updatedNodes;
    } catch (error) {
      console.error('Error updating nodes:', error);
      throw error;
    }
  }

  async updateNode(node, updateValues) {
    try {
      return await this.update({ ...node, ...updateValues });
    } catch (error) {
      console.error('Error updating node:', error);
      throw error;
    }
  }

  async updateNodeState(node, newState) {
    return this.updateNode(node, { nodeState: newState });
  }

  async updateNodeBrief(node, newBrief) {
    return this.updateNode(node, { brief: newBrief });
  }

  async updateNodeDifficulty(node, newDifficulty) {
    return this.updateNode(node, { difficulty: newDifficulty });
  }

  async updateNodeProjectValue(node, newProjectValue) {
    return this.updateNode(node, { projectValue: newProjectValue });
  }

  async updateNodeObjects(nodeName, nodeObjects) {
    const nodes = await this.getNodesByName(nodeName);
    let node;
    if (nodes.length > 0) {
      // Choose the first node from the array or handle the situation when there are more nodes with the same name
      node = nodes[0];
    } else {
      // Create a new node if no nodes exist with the provided name
      node = await this.create(new Node({ title: nodeName, userId: this.getUserId() }));
    }
    node.nodeObjects = nodeObjects;
    await this.update(node);
  }

  async fetchNodesFromAPI(endpoint, params) {

    const test = JSON.stringify(params);
    console.log(test);
    try {
      const response = await fetch(`https://data.mongodb-api.com/app/mainlistapp-kqoki/endpoint/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      if (response.ok) {
        //const clone = response.clone();
        const data = await response.json();
        const theData = this.instantiateNodesFromAPI(data);
        return theData;
      } else {
        console.error("Response not OK:", await response.text());
        return [];
      }
    } catch (error) {
      console.error("Error calling API:", error);
      return [];
    }
  }
  
  async fetchJSONFromAPI(endpoint, params) {
    try {
      const response = await fetch(`https://data.mongodb-api.com/app/mainlistapp-kqoki/endpoint/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
  
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error("Response not OK:", await response.text());
        return null;
      }
    } catch (error) {
      console.error("Error calling API:", error);
      return null;
    }
  }
  
}


const nodeMapper = new NodeMapper();
export default nodeMapper;
