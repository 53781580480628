import React from 'react';
import {
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ResponsiveColorPicker from '../BelCar/base/ResponsiveColorPicker';

export default function InteriorColor({ value, onChange }) {
    return (
        <Accordion>
            <AccordionSummary>
                <Typography>Couleur intérieure</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ResponsiveColorPicker
                    radio={false}
                    selectionType="single"
                    colors={{
                        Beige: '#F5F5DC',
                        Bleu: '#0000FF',
                        Brun: '#964B00',
                        Jaune: '#FFFF00',
                        Gris: '#808080',
                        Vert: '#008000',
                        Rouge: '#FF0000',
                        Noir: '#000000',
                        Orange: '#FFA500',
                        Blanc: '#FFFFFF',
                        Autre: '#FFFFFF',
                    }}
                    selectedColor={value}
                    onColorChange={(newColor) => {
                        onChange({ target: { name: 'interiorColor', value: newColor } });
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
}
