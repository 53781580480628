import React from 'react';
import {
    Title,
} from './StyledStyles';

// Utility function to truncate the text
function truncateText(text, maxLength) {
    if (!text) return '';
    if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + '...';
    }
    return text;
}

export default function TitleText({ title, text }) {
    const truncatedText = truncateText(text, 600);

    return (
        <div style={{ marginTop: '10px' }}>
            <Title style={{ fontWeight: 'bold', marginBottom: '10px' }}>{title}</Title>
            <Title style={{ fontSize: '14px' }}>{truncatedText}</Title>
        </div>
    );
}
