import React, { useState, useEffect } from 'react';
import {
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Switch, // Added for public switch
  IconButton, // Added for link copy button
  Tooltip // Added for tooltip on link copy button
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link'; // Icon for link copy button
import HistoryMenuIcon from '@mui/icons-material/ArrowBackRounded';
import StatsMenuIcon from '@mui/icons-material/StarRounded';
import MlNavigationMenu from '../MlNavigationMenu';
import { useMlCanvas } from '../../../MlModels/MlCanvasProvider';

const MlCanvasDock = ({ viewOnly = false, color = 'black' }) => {
  const {
    mlNode,
    setMlNode,
    updateCanvasNameInList,
    getHistoryCanvasList,
    getSortedStatsCanvasList,
    saveToDatabase,
  } = useMlCanvas();
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [isPublic, setIsPublic] = useState(false); // New state for public switch
  const [copySuccess, setCopySuccess] = useState(''); // New state for clipboard notification

  const handleClickOpen = () => {
    if (!viewOnly) {
      setNewName(mlNode ? mlNode.title : '');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRename = async () => {
    if (mlNode) {
      const updatedMlNode = { ...mlNode, title: newName };
      await setMlNode(updatedMlNode);
      // Pass the updated node directly
      await saveToDatabase(updatedMlNode);
      //loadCanvas(mlNode._id);
      handleClose();
    }
  };

  const handlePublicSwitch = (event) => {
    const newPublicValue = event.target.checked;
    setIsPublic(newPublicValue);

    if (mlNode) {
      // Update mlNode with the new public property value
      const updatedMlNode = { ...mlNode, public: newPublicValue };
      setMlNode(updatedMlNode);
      // If there is any other update method or API call to persist this change, add it here
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('Link copied to the clipboard');
      setTimeout(() => setCopySuccess(''), 2000); // Hide after 2 seconds
    });
  };

  const handleLinkCopy = () => {
    const link = `https://qwanyx.netlify.app/public-canvas/${mlNode?._id}`;
    copyToClipboard(link);
  };

  const mlNodeTitle = mlNode ? mlNode.title : 'Untitled';

  useEffect(() => {
    // Set the initial state of isPublic based on the mlNode's public property when the component mounts
    if (mlNode && mlNode.hasOwnProperty('public')) {
      setIsPublic(mlNode.public);
    }
  }, [mlNode]);



  return (
    <>
      <Stack direction='row' spacing={2} alignItems='center'>
        {/*
        {!viewOnly && (
          <MlNavigationMenu getList={getHistoryCanvasList} iconComponent={<HistoryMenuIcon/>}/>
        )}
        */}
        <Button
          size="small"
          variant="text"
          onClick={handleClickOpen}
          sx={{ color: color, fontSize: '1rem', fontWeight: 'bold' }}>
          {mlNodeTitle}
        </Button>
        {/* Public Switch */}
        {!viewOnly && (
          <Switch
            checked={isPublic}
            onChange={handlePublicSwitch}
          />
        )}
        {/* Link Copy Button */}
        {isPublic && (
          <Tooltip title="Copy Link">
            <IconButton onClick={handleLinkCopy} sx={{ color: color }}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        )}
        {!viewOnly && (
          <MlNavigationMenu color={color} getList={getSortedStatsCanvasList} iconComponent={<StatsMenuIcon />} />
        )}
        {/* Clipboard Copy Notification */}
        {copySuccess && <Box>{copySuccess}</Box>}
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Rename Node</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a new name for the node.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="New Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRename}>Rename</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MlCanvasDock;
