import React, { useState, useEffect } from 'react';
import { Box, FormControl, Select, MenuItem, TextField, InputLabel } from '@mui/material';

// Conversion functions
const convertKWtoCH = (kw) => Number((kw * 1.35962).toFixed(0));
const convertCHtoKW = (ch) => Number((ch / 1.35962).toFixed(0));

const PowerInput = ({ value, unit, onChange, onUnitChange, ...otherProps }) => {
    console.log("Props received in PowerInput:", unit, value, onChange, onUnitChange, otherProps);

    const [currentUnit, setCurrentUnit] = useState(unit || 'KW');
    const [powerValue, setPowerValue] = useState(value || '');

    useEffect(() => {
        setPowerValue(value);
        setCurrentUnit(unit);
    }, [value, unit]);

    const handleUnitChange = (event) => {
        const newUnit = event.target.value;
        setCurrentUnit(newUnit);

        // Conversion
        const convertedValue = newUnit === 'KW' ? convertCHtoKW(powerValue) : convertKWtoCH(powerValue);
        setPowerValue(convertedValue);
        onChange({ target: { name: 'power', value: convertedValue } });
        onUnitChange({ target: { name: 'powerUnit', value: newUnit } });
    };

    const handlePowerChange = (event) => {
        const newPowerValue = event.target.value;
        setPowerValue(newPowerValue);
        onChange({ target: { name: 'power', value: newPowerValue } });
    };

    return (
        <Box display="flex" alignItems="center" {...otherProps}>
            <FormControl variant="outlined" sx={{ minWidth: '100px' }}>
                <InputLabel>Unité</InputLabel>
                <Select
                    label="Unité"
                    value={currentUnit}
                    onChange={handleUnitChange}
                >
                    <MenuItem value="KW">KW</MenuItem>
                    <MenuItem value="CH">CH</MenuItem>
                </Select>
            </FormControl>
            <TextField
                fullWidth
                label="Puissance"
                variant="outlined"
                value={isNaN(powerValue) ? "" : powerValue}
                onChange={handlePowerChange}
            />
        </Box>
    );
};

export default PowerInput;
