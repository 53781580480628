import React from 'react';
import {
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ResponsiveColorPicker from '../BelCar/base/ResponsiveColorPicker';

export default function BodyColor({ value, onChange }) {
    return (
        <Accordion>
            <AccordionSummary>
                <Typography>Couleur carrosserie</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ResponsiveColorPicker
                    selectionType="single"
                    colors={{
                        Beige: '#F5F5DC',
                        Bleu: '#0000FF',
                        Brun: '#964B00',
                        Bronze: '#CD7F32',
                        Jaune: '#FFFF00',
                        Gris: '#808080',
                        Vert: '#008000',
                        Rouge: '#FF0000',
                        Noir: '#000000',
                        Argent: '#C0C0C0',
                        Mauve: '#800080',
                        Blanc: '#FFFFFF',
                        Orange: '#FFA500',
                        Or: '#FFD700'
                    }}
                    selectedColor={value}
                    onColorChange={(newColor) => {
                        onChange({ target: { name: 'bodyColor', value: newColor } });
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
}
