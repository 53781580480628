import React from 'react';
import Accordion from './Accordion';

const TreeColumn = ({ data, level = 0 }) => {
  return (
    <>
      {data.map((item, index) => (
        <Accordion key={index} title={item.title} level={level}>
          {item.children && item.children.length > 0 && (
            <TreeColumn data={item.children} level={level + 1} />
          )}
        </Accordion>
      ))}
    </>
  );
};

export default TreeColumn;
