// ImageList.js
import React from 'react';
import { Box, ImageList, ImageListItem } from '@mui/material';

const REACT_APP_S3_URL_BASE = process.env.REACT_APP_S3_URL_BASE;

const ImageListComponent = ({ images, onImageClick }) => {
  const imageLinks = images ? images.split('\n') : [];

  return (
    <Box sx={{ overflowY: 'scroll' }} padding={1}>
      <ImageList variant="masonry" cols={8} gap={8}>
        {imageLinks.map((img, index) => (
          <ImageListItem key={index} onClick={() => onImageClick(`${REACT_APP_S3_URL_BASE}/${img}`, index)}>
            <img
              src={`${REACT_APP_S3_URL_BASE}/${img}`}
              srcSet={`${REACT_APP_S3_URL_BASE}/${img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={`Image ${index + 1}`}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default ImageListComponent;
