import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Realm from 'realm-web';
import { REALM_APP_ID } from './realm-config';

const UserConfirmation = () => {

    const navigate = useNavigate();
    const app = new Realm.App({ id: REALM_APP_ID });

    useEffect(() => {
        const confirmUser = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get("token");
            const tokenId = urlParams.get("tokenId");

            if (token && tokenId) {
                try {
                    await app.emailPasswordAuth.confirmUser(token, tokenId);
                    console.log(token, tokenId)
                    navigate('/login');  // navigate to login page
                } catch (err) {
                    console.error("Failed to confirm user:", err);
                }
            }
        };
        
        confirmUser();
    }, []);

    return null;
}

export default UserConfirmation;
