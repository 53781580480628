import React, { useState, useEffect } from 'react';
import LaunchIcon from '@mui/icons-material/Launch'; // Assuming you're using Material-UI

const ImageSlide = ({ src, alt, size = '1024' }) => {
    const [imageStyle, setImageStyle] = useState({});
    // Split the URL to extract the query string part
    const urlParts = src.split("?");
    const queryParams = new URLSearchParams(urlParts[1]);
    // Decode URI component if needed (assuming the URL was correctly encoded when passed)
    const link = queryParams.get('link') ? decodeURIComponent(queryParams.get('link')) : null;
    const copyright = queryParams.get('copy');
    let width = queryParams.get('width') || size;

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            const aspectRatio = img.height / img.width;
            const height = size * aspectRatio;
            setImageStyle({ width: `${size}px`, height: `${height}px` });
        };
        img.src = src;
    }, [src, size]);

    const handleAction = () => {
        if (link) {
            window.open(link, '_blank');
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ textAlign: 'center' }}>
                <img
                    loading="lazy"
                    src={src}
                    alt={alt || 'Image slide'}
                    onClick={handleAction}
                    style={{
                        borderRadius: '5px',
                        maxWidth: '100%',
                        width: imageStyle.width,
                        height: 'auto',
                        cursor: link ? 'pointer' : 'default',
                        //boxShadow: '0px 8px 7px rgba(0, 0, 0, 0.1)'
                    }}
                />
                {copyright && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '0.1rem'
                    }}>
                        <p style={{ textAlign: 'left', fontSize: '14px', margin: 0, color: 'black' }}>
                            {copyright}
                        </p>
                        {link && (
                            <div>
                                <LaunchIcon onClick={handleAction} style={{ fontSize: '24px', cursor: 'pointer' }} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageSlide;
