import React, { useEffect, useState, useRef } from 'react';
import { nodePut, createNode, nodesGet, nodeDelete, getUserId } from '../../MlModels/MlNode';
import { Dialog, DialogActions, DialogTitle, Button, Box, useMediaQuery, useTheme, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TabPanel from '../TabPanel';
import DashBoardProfile from './DashBoardProfile';
import DashBoardContact from './DashBoardContact';
import DashBoardPro from './DashBoardPro';

const DashBoard = ({ open, handleClose, backgroundColor = '#f5f5f5', identity, ...restProps }) => {

    //const identity = 'autodin';
    const form = 'dashboard'
    const [node, setNode] = useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const tabLabels = ["Profile", "Contact", "Pro"];
    const [selectedTab, setSelectedTab] = useState(0);
    const nodeId = null;
    //console.log('Mouting Dashboard...'); 
    // Fetch data when component mounts
    useEffect(() => {
        const fetchNodeData = async () => {
            //const test = 'toto'
            //console.log('Fetching node data...');  // Debugging to see if it's called
            try {
                const query = { userId: getUserId(),  identity: identity, form: form };  // MongoDB query without userId
                const fetchedNodes = await nodesGet(query);  // Use the query to fetch nodes

                if (fetchedNodes && fetchedNodes.length > 0) {
                    setNode(fetchedNodes[0]);  // Set the first node
                    console.log('Node fetched:', fetchedNodes[0]);  // Debugging
                } else {
                    setNode(createNode({ title: '', identity: identity, form: form }));  // Create a new node if none found
                    console.log('No nodes found, creating new node');  // Debugging
                }
            } catch (error) {
                console.error('Failed to fetch node:', error);
            }
        };

        fetchNodeData();  // Call fetchNodeData on component mount
    }, []);  // Empty dependency array to ensure useEffect runs once on mount

    const handleChange = (field, value) => {
        setNode(prevNode => ({
            ...prevNode,
            [field]: value,
        }));
    };

    const tabContents = [
        <DashBoardProfile node={node} onChange={handleChange} />,
        <DashBoardContact node={node} onChange={handleChange} />,
        <DashBoardPro node={node} onChange={handleChange}/>
    ];

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleCancel = () => {
        //onClose();
    };

    const handleSave = async () => {
        try {
            // Perform your save logic here, such as an API call
            await nodePut(node);

            // After saving, close the dialog
            handleClose();
        } catch (error) {
            console.error('Error saving data:', error);
            // Handle error if needed
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="car-form-dialog-title"
            fullWidth
            maxWidth="md"
            fullScreen={fullScreen}
            PaperProps={{
                style: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: fullScreen ? '100%' : '95vh',
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'white',
                    position: 'relative',
                    borderBottom: '1px solid #ccc',

                }}>
                <Typography
                    variant="h5"
                    component="h2"
                >
                    {`Tableau de bord: ${node?.firstName || ''} ${node?.name || ''}`}
                </Typography>
                <HighlightOffIcon
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '16px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer'
                    }}
                />
            </DialogTitle>

            <Box sx={{
                backgroundColor: backgroundColor,

                height: '100%'
            }}>
                <TabPanel
                    tabLabels={tabLabels}
                    tabContents={tabContents}
                    selectedTab={selectedTab}
                    onChange={handleTabChange}
                />

                {/* Add other content here */}
            </Box>
            <DialogActions
                sx={{
                    borderTop: '1px solid #ccc',
                    backgroundColor: 'rgb(250,250,250)',
                }}
            >
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSave} color="primary">
                    Sauver
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DashBoard;
