import dagre from 'dagre';
import { createNewNode, getNewEdge } from './MlNodeUtils'; // Ensure path is correct

export const AppendJSONFlow = (json, existingNodes, existingEdges, task = null, parentId = null, x = 0, y = 0) => {

  const newOriginX = x;
  const newOriginY = y;

  const g = new dagre.graphlib.Graph();
  g.setGraph({
    rankdir: 'LR',
    ranksep: 500,
    nodesep: 70
  });
  g.setDefaultEdgeLabel(() => ({}));

  const recursiveAppend = (items, parentId = null, depth = 0) => {
    let newNodes = [];
    let newEdges = [];

    items.forEach(item => {
      // Create a node using the provided function signature

      const position = { x: 0, y: 0 }; // Temporary position, to be updated by Dagre
      //TODO Find a way to create the node of task type.
      //because for now it's a problem
      const type = 'selectorNode';
      const newNode = createNewNode(position, type, item.title, item.brief, item.type, item.state);

      const currentId = newNode.id;
      newNodes.push(newNode);

      if (parentId) {
        const newEdge = getNewEdge(parentId, currentId);
        newEdges.push(newEdge);
      }

      if (item.children && item.children.length) {
        const childResults = recursiveAppend(item.children, currentId, depth + 1);
        newNodes = newNodes.concat(childResults.nodes);
        newEdges = newEdges.concat(childResults.edges);
      }
    });

    return { nodes: newNodes, edges: newEdges };
  };

  const { nodes, edges } = recursiveAppend(json);

  nodes.forEach(node => {
    g.setNode(node.id, { width: 100, height: 50 }); // These should match the actual size of your nodes
  });

  edges.forEach(edge => {
    g.setEdge(edge.source, edge.target);
  });

  dagre.layout(g);

  // Apply calculated positions to the nodes
  const layoutedNodes = nodes.map(node => {
    const nodeWithPosition = g.node(node.id);
    // Apply Dagre positions to the temporary position
    node.position.x = nodeWithPosition.x + newOriginX;
    node.position.y = nodeWithPosition.y + newOriginY;
    return node;
  });

  return {
    nodes: [...existingNodes, ...layoutedNodes],
    edges: [...existingEdges, ...edges],
  };
};
