import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';

const AddButton = ({ color = 'primary', tooltip = '', onClick }) => {
  

  return (
    <Tooltip title={tooltip}>
      <IconButton aria-label="add" color={color} onClick={onClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AddButton;