import React from 'react';
import {
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material';

export default function CarTarifs({ value, onChange }) {
    return (
        <>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                value={value}
                onChange={onChange}
            >
                <FormControlLabel value="Pariculier" control={<Radio />} label="Pariculier" />
                <FormControlLabel value="Garage" control={<Radio />} label="Garage" />
            </RadioGroup>
        </>
    );
}
