import React from 'react';
import { Stack, Accordion, Typography, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel } from '@mui/material';

export default function PaintType({ value = [], onChange }) {
    const handleColorChange = (type) => {
        const newValue = Array.isArray(value) ? (
            value.includes(type) ? value.filter(v => v !== type) : [...value, type]
        ) : [type];
        onChange({ target: { name: 'painttype', value: newValue } });
    };

    // Ensure value is an array
    const selectedValues = Array.isArray(value) ? value : [];

    return (
        <Accordion>
            <AccordionSummary>
                <Typography>Peinture</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack ml={2} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedValues.includes('metallisee')}
                                onChange={() => handleColorChange('metallisee')}
                            />
                        }
                        label="Métalisée"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedValues.includes('matte')}
                                onChange={() => handleColorChange('matte')}
                            />
                        }
                        label="Matte"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedValues.includes('foncée')}
                                onChange={() => handleColorChange('foncée')}
                            />
                        }
                        label="Foncée"
                    />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
