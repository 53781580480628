// textStructureUtils.js
import { generateJsonStructure } from './jsonStructureUtils'; // Import the JSON structure utility

// Helper function to create the outline text recursively
const createOutlineText = (node, level = 0) => {
    const tabs = '\t'.repeat(level);
    let text = `${tabs}${node.data.title}: ${node.data.brief}\n`;

    node.children.forEach(child => {
        text += createOutlineText(child, level + 1);
    });

    return text;
};

// Main function to generate the outline text from nodes and edges
export const generateOutlineText = (nodes, edges) => {
    const jsonStructure = generateJsonStructure(nodes, edges);

    // Create the outline text starting from the root nodes
    let outlineText = '';
    jsonStructure.forEach(rootNode => {
        outlineText += createOutlineText(rootNode);
    });

    return outlineText;
};


