import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';
import { logos } from '../Belgicomics/BelgicomicsDB';
//TODO have the logos as props so the component can be reusable
const LogosDisplay = () => {
    const { quanyxStorage } = useMlCanvas();

    const handleLogoClick = (link) => {
        if (link) {
            window.open(link, '_blank');
        }
    };

    return (
        <Box sx={{ backgroundColor: '#ffffff', color: '#ffffff', padding: 4 }}>
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    {logos.map((logo, index) => (
                        <Grid item key={index}>
                            <Box
                                component="img"
                                src={quanyxStorage + logo.src}
                                alt={logo.name}
                                onClick={() => handleLogoClick(logo.link)}
                                sx={{ cursor: 'pointer', maxHeight: 100, maxWidth: 200 }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default LogosDisplay;
