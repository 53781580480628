import styled from "styled-components";
import SendIcon from '@mui/icons-material/Send';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useState, useEffect, useRef } from 'react';

import {
  TextField,
  Stack,
  Switch,
  Button,
  Box,
  InputAdornment
} from '@mui/material';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const ContacterLeVendeur = styled.div`
  flex: 1;
  position: relative;
  font-weight: 500;
  font-size: 24px;
`;

const ContacterLeVendeurParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const Label = styled.div`
  align-self: stretch;
  position: relative;
`;

const Message = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

const ContactsellerRoot = styled.div`
  width: 95%;
  max-width: 600px;
  border-radius: 8px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  text-align: left;
  font-size: 24px;
  color: #000;
  font-family: Inter;
`;

const ContactSeller = ({
  sellerMail = 'belcarscrl@skynet.be',
  sellerPhone = '+32 476 52 31 52',
  vehicle = '',
  carUrl = '',
  sellerWhatsap = '+32 476 52 31 52', onClose
}) => {
  const [message, setMessage] = useState('');
  const messageRef = useRef(null);
  useEffect(() => {
    setMessage(`Bonjour,

  Je suis intéressé(e) par votre véhicule:
  ${vehicle}

  Lien vers le véhicule (a coller dans la barre d'adresse du navigateur'):
  ${carUrl}
  
  Est-il toujours disponible ?
  Merci de me contacter.
  
  Je vous en remercie déjà !`);
  }, [carUrl]);  // Dependency array, will re-run the effect when carUrl changes

  const [reprise, setReprise] = useState(`Je suis intéressé par la reprise de mon véhicule`);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleEmailClick = () => {
    let emailBody = `${message}\n\n${reprise}`;
    if (name) emailBody += `\n\nName: ${name}`;
    if (phoneNumber) emailBody += `\nPhone Number: ${phoneNumber}`;
    window.open(`mailto:${sellerMail}?subject=Contact&body=${encodeURIComponent(emailBody)}`, '_blank');
  };

  const handleWhatsAppClick = () => {
    let whatsappMessage = `${message}\n\n${reprise}`;
    if (name) whatsappMessage += `\n\nName: ${name}`;
    if (phoneNumber) whatsappMessage += `\nPhone Number: ${phoneNumber}`;
    window.open(`https://wa.me/${sellerWhatsap.replace(/\D/g, '')}?text=${encodeURIComponent(whatsappMessage)}`, '_blank');
  };

  const handlePhoneClick = () => {
    window.open(`tel:${sellerPhone.replace(/\D/g, '')}`, '_blank');
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ContactsellerRoot>
      <ContacterLeVendeurParent>
        <ContacterLeVendeur>Contacter le vendeur</ContacterLeVendeur>
      </ContacterLeVendeurParent>
      <Message>
        <Label>Votre Message</Label>
        <TextField
          id="message"
          multiline
          style={{ width: '100%' }}
          value={message}  // Bind to the message state
          onChange={(e) => setMessage(e.target.value)}  // Update the message state when the text changes
        />
      </Message>
      <Message>
        <Label>Reprise</Label>
        <Stack
          direction="row"
          style={{ width: '100%' }}
          alignItems="center"
          spacing={1}
        >
          <TextField
            id="reprise"
            multiline
            style={{ width: '100%' }}
            defaultValue={
              `Je suis intéressé par la reprise de mon véricule`
            } />
          <Switch
            id="repriseSwitch"
          />
        </Stack>
      </Message>
      <Message>
        <Label>Votre Nom</Label>
        <TextField
          id="name"
          required
          style={{ width: '100%' }}
          placeholder='p. ex. Jean Dupont'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            ),
          }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Message>
      <Message>
        <Label>Votre Numéreau de téléphone</Label>
        <TextField
          id="phone"
          required
          style={{ width: '100%' }}
          placeholder='p. ex. 04 123 456'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CallOutlinedIcon />
              </InputAdornment>
            ),
          }}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Message>
      <Box style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Stack
          direction="column"
          style={{ width: '100%' }}
          alignItems="center"
          spacing={1}
        >
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleEmailClick}
            style={{ width: '100%' }}
          >
            Email
          </Button>
          <Button
            variant="contained"
            endIcon={<WhatsAppIcon />}
            onClick={handleWhatsAppClick}
            style={{ width: '100%' }}
          >
            Whatsap
          </Button>
          <Button
            variant="contained"
            endIcon={<CallOutlinedIcon />}
            onClick={handlePhoneClick}
            style={{ width: '100%' }}
          >
            Téléphone
          </Button>
        </Stack>
      </Box>
    </ContactsellerRoot>
  );
};

export default ContactSeller;
