import React, { useState, useEffect } from 'react';
import { Stack, Box, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';
import MlAnimationToolBox from './MlAnimationToolBox';

const MlAnimationList = () => {
    const {
        getAnimationTargets,
        goTarget,
        setAnimationTargets
    } = useMlCanvas();

    const [accordionData, setAccordionData] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [draggedItem, setDraggedItem] = useState(null);

    useEffect(() => {
        const targets = getAnimationTargets();
        const formattedTargets = targets.map(target => ({
            id: target.id,
            title: target.name,
            content: `Bounds: ${JSON.stringify(target.bounds)}, Duration: ${target.duration}ms`
        }));
        setAccordionData(formattedTargets);
    }, [getAnimationTargets]);

    const onDragStart = (e, item) => {
        setDraggedItem(item);
    };

    const onDragOver = (e) => {
        e.preventDefault(); // Necessary for allowing to drop
    };

    const onDrop = (e, droppedItem) => {
        e.preventDefault();

        const newAccordionData = accordionData.filter(item => item.id !== draggedItem.id);
        const droppedItemIndex = newAccordionData.findIndex(item => item.id === droppedItem.id);
        newAccordionData.splice(droppedItemIndex, 0, draggedItem);
        setAccordionData(newAccordionData);
        setDraggedItem(null);
        setAnimationTargets(newAccordionData);
    };

    const handleChangeAccordion = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleAccordionClick = (index) => {
        goTarget(index);
    };

    // Prevent accordion toggle when clicking on the summary area (excluding the expand icon)
    const handleSummaryClick = (event) => {
        event.stopPropagation();
    };

    return (
        <Stack direction='column' sx={{ width: '100%', height: '100%' }}>
            <MlAnimationToolBox fill="rgb(80,80,80)" iconSize={12}/>
            <Box flexGrow={1} overflow="auto">
                {accordionData.map((item, index) => (
                    <Accordion
                        key={item.id}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChangeAccordion(`panel${index}`)}
                        draggable
                        onDragStart={(e) => onDragStart(e, item)}
                        onDragOver={onDragOver}
                        onDrop={(e) => onDrop(e, item)}
                    >
                        <AccordionSummary
                            expandIcon={<IconButton onClick={(e) => handleChangeAccordion(`panel${index}`)(e, expanded !== `panel${index}`)}><ExpandMoreIcon /></IconButton>}
                            aria-controls={`panel${index}a-content`}
                            id={`panel${index}a-header`}
                            onClick={handleSummaryClick}
                        >
                            <Typography onClick={() => handleAccordionClick(index)} style={{ flex: 1, cursor: 'pointer' }}>
                                {`${index} - ${item.title}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{item.content}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Stack>
    );
}

export default MlAnimationList;
